<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="py-4 container-fluid" v-else>
    <div class="card shadow-lg mb-4">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-lg bg-gradient-primary">
              <h4 class="text-white">{{ getInitials(user.name) }}</h4>
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ user?.name }}</h5>
              <p class="mb-0 font-weight-bold text-sm">{{ user?.role }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <el-form
          ref="profileFormRef"
          :model="profileForm"
          :rules="rules"
          label-width="auto"
          class="demo-ruleForm"
          size="large"
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0">Edit Profile</p>
                <el-button
                  type="success"
                  size="small"
                  class="px-3 py-3"
                  :loading="isUpdatingProfile"
                  @click="submitForm(profileFormRef, user.id)"
                >
                  Update Profile
                </el-button>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">User Information</p>

              <div class="row">
                <div class="col-md-6">
                  <el-form-item label="First Name" prop="first_name">
                    <el-input v-model="profileForm.first_name" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Last Name" prop="last_name">
                    <el-input v-model="profileForm.last_name" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Role" prop="role">
                    <el-input v-model="profileForm.role" disabled />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Created Date" prop="created_at">
                    <el-input v-model="profileForm.created_at" disabled />
                  </el-form-item>
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Contact Information</p>
              <div class="row">
                <div class="col-md-12">
                  <el-form-item label="Address" prop="address">
                    <el-input v-model="profileForm.address" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Phone" prop="phone">
                    <el-input v-model="profileForm.phone" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="profileForm.email" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="col-md-4">
        <profile-card />
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ProfileCard from "./components/ProfileCard.vue";
import Loader from "../components/Loader.vue";
import { reactive, ref } from "vue";
import { getUserDetails, updateProfile } from "../api/services/users";
import moment from "moment";
import { ElNotification } from "element-plus";
const body = document.getElementsByTagName("body")[0];
const profileFormRef = ref();
const user = ref({});
const isLoading = ref(false);
const isUpdatingProfile = ref(false);
const showMenu = ref(false);

export default {
  name: "profile",
  setup() {
    const profileForm = reactive({
      email: "",
      phone: "",
      address: "",
      first_name: "",
      last_name: "",
      created_at: "",
      role: "",
    });
    const fetchDetails = async () => {
      isLoading.value = true;
      try {
        const response = await getUserDetails();
        let data = response.data;
        console.log("the dtaa", data.email);
        user.value = response.data;
        profileForm.first_name = data.first_name || "Unavailable";
        profileForm.last_name = data.last_name || "Unavailable";
        profileForm.email = data.email || "Unavailable";
        profileForm.phone = data.phone || "Unavailable";
        profileForm.address = data.address || "Unavailable";
        profileForm.role = data.role || "Unavailable";
        profileForm.created_at =
          moment(data.created_at).format("DD/MM/YYYY") || "Unavailable";
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const submitForm = async (formEl, id) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isUpdatingProfile.value = true;

          const formData = new FormData();

          formData.append("first_name", profileForm.first_name);
          formData.append("last_name", profileForm.last_name);
          formData.append("phone", profileForm.phone);
          formData.append("address", profileForm.address);
          formData.append("email", profileForm.email);
          formData.append("role", profileForm.role);
          formData.append("_method", "PUT");

          updateProfile(formData, id)
            .then((data) => {
              if (data.success) {
                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
              } else {
                let error = Object.values(data.errors);

                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isUpdatingProfile.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return {
      showMenu,
      fetchDetails,
      profileForm,
      user,
      getInitials,
      isLoading,
      profileFormRef,
      submitForm,
      resetForm,
      isUpdatingProfile,
    };
  },
  components: { ProfileCard, Loader },

  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
  },
  created() {
    this.fetchDetails();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
