<template>
  <div class="py-1 container-fluid">
    <el-form
      class="el-form-item--label-top"
      ref="invoiceFormRef"
      :model="invoiceForm"
      :rules="rules"
      status-icon
      :scroll-to-error="true"
      label-position="left"
      :hide-required-asterisk="true"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0 text-dark text-bold">
                  {{ invoiceForm?.id != 0 ? "Edit Invoice" : "Add Invoice" }}
                </p>
              </div>
            </div>
            <div class="card-body invoice-body">
              <div class="row">
                <div class="col-md-4 p-3">
                  <el-form-item
                    class="el-form-item-label"
                    label="Appointment Date"
                    prop="appointment_date"
                  >
                    <el-date-picker
                      v-model="appointment_date"
                      type="date"
                      :default-value="appointment_date"
                      label="Pick a date"
                      placement="bottom"
                      @change="loadAppointments"
                      placeholder="Pick a date"
                      style="width: 100%"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-8 p-3">
                  <el-form-item
                    class="el-form-item-label"
                    label="Choose appointment"
                    prop="appointment"
                  >
                    <el-select-v2
                      class="w-100"
                      v-model="invoiceForm.appointment"
                      filterable
                      remote
                      :options="appointments"
                      @change="handleAppointmentSelect"
                      placeholder="Search appointment"
                      placement="top"
                      :remote-method="loadAppointments"
                      :loading="loading"
                    />
                     <!-- :disabled="invoiceForm.id > 0" -->
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card mt-5"
        v-if="invoiceForm.selected_appointment_value != null"
      >
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-md-5">
              <div class="d-flex align-items-center">
                <div
                  class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center"
                >
                  <i class="fa fa-user text-white opacity-10"></i>
                </div>
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">Patient Details</h6>

                  <span class="font-weight-bold"
                    >Name:
                    <span class="text-sm">
                      {{ invoiceForm.patient?.first_name || "" }}
                      {{ invoiceForm.patient?.middle_name || "" }}
                      {{ patient?.last_name || "" }}
                    </span></span
                  >
                  <span class="font-weight-bold"
                    >Patient No.:
                    <span class="text-sm">{{
                      invoiceForm.patient?.patient_number
                    }}</span></span
                  >

                  <div
                    class="d-flex flex-column"
                    v-if="
                      invoiceForm.selected_appointment_value.appointment_type ==
                      'inpatient'
                    "
                  >
                    <span class="font-weight-bold"
                      >Hospital:
                      <span class="text-sm">{{
                        invoiceForm.selected_appointment_value.hospital.name
                      }}</span></span
                    >
                    <span class="font-weight-bold"
                      >Admission No:
                      <span class="text-sm">{{
                        invoiceForm.selected_appointment_value?.admission_number
                      }}</span></span
                    >
                    <span class="font-weight-bold"
                      >Ward:
                      <span class="text-sm">{{
                        invoiceForm.selected_appointment_value?.ward_name
                      }}</span></span
                    >
                  </div>
                  <div>
                    <span class="appointment_type ps-2">{{
                      modifyPatientType(
                        invoiceForm.selected_appointment_value.appointment_type
                      )
                    }}</span>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark m-3" />
              <div class="d-flex align-items-center">
                <div
                  class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center"
                >
                  <i class="fa fa-user text-white opacity-10"></i>
                </div>
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">
                    Medical Cover Details

                    <span
                      class="badge badge-pill badge-success mx-4"
                      @click="
                        showInsurances(
                          invoiceForm.appointment?.patient?.patient_insurances
                        )
                      "
                      >{{
                        invoiceForm.patient_insurance != null ? "Change" : "Add"
                      }}</span
                    >

                    <span
                      v-if="invoiceForm.patient_insurance != null"
                      class="badge badge-pill badge-danger mx-4"
                      @click="removeInsurance()"
                      >Remove</span
                    >
                  </h6>

                  <span
                    v-if="
                      invoiceForm.patient_insurance?.scheme_type == 'insurance'
                    "
                    class="font-weight-bold"
                    >Member Name:
                    <span class="text-sm">{{
                      invoiceForm.patient_insurance?.membership_name
                    }}</span></span
                  >
                  <span
                    v-if="
                      invoiceForm.patient_insurance?.scheme_type == 'insurance'
                    "
                    class="font-weight-bold"
                    >Member No.:
                    <span class="text-sm">{{
                      invoiceForm.patient_insurance?.membership_number
                    }}</span></span
                  >
                  <span
                    v-if="
                      invoiceForm.patient_insurance?.scheme_type == 'insurance'
                    "
                    class="font-weight-bold"
                    >Insurance Name.:
                    <span class="text-sm">{{
                      invoiceForm.patient_insurance?.insurance?.name
                    }}</span></span
                  >
                  <span
                    v-if="invoiceForm.patient_insurance?.scheme_type == 'self'"
                    class="font-weight-bold"
                    >Employee Name:
                    <span class="text-sm">{{
                      invoiceForm.patient_insurance?.employee_name
                    }}</span></span
                  >
                  <span
                    v-if="invoiceForm.patient_insurance?.scheme_type == 'self'"
                    class="font-weight-bold"
                    >Employee No.:
                    <span class="text-sm">{{
                      invoiceForm.patient_insurance?.employee_number
                    }}</span></span
                  >

                  <span class="font-weight-bold"
                    >Scheme/Employer:
                    <span class="text-sm">{{
                      invoiceForm.patient_insurance?.employer?.name
                    }}</span></span
                  >
                  <span class="font-weight-bold"
                    >Expiry Date:
                    <span class="text-sm">{{
                      dateTime(invoiceForm.patient_insurance?.expiry_date)
                    }}</span></span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-7">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="row">
                    <div class="col-md-6">
                      <h6 class="mb-1 text-dark text-sm">Invoice details</h6>

                      <el-form-item
                        label-width="40%"
                        label="Invoice Number"
                        prop="invoice_number"
                      >
                        <!-- :disabled="invoiceForm?.id != 0" -->
                        <el-input
                          placeholder="Invoice Number"
                          v-model="invoiceForm.invoice_number"
                        />
                      </el-form-item>
                      <el-form-item
                        label-width="40%"
                        label="Invoice Date"
                        prop="invoice_date"
                      >
                        <el-date-picker
                          v-model="invoiceForm.invoice_date"
                          type="date"
                          :default-value="invoiceForm.invoice_date"
                          label="Pick a date"
                          placeholder="Pick a date"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <h6 class="mb-1 text-dark text-sm">
                        Dispatch/Acknowledgement/Tracking details
                      </h6>
                      <el-form-item
                        label-width="40%"
                        label="Dispatch Channel"
                        prop="channel"
                      >
                        <el-select
                          placeholder="Dispatch Channel"
                          v-model="invoiceForm.channel"
                        >
                          <el-option label="Slade" value="slade" />
                          <el-option label="LCT" value="lct" />
                          <el-option label="M-tiba" value="mtiba" />
                          <el-option label="Smart" value="smart" />
                          <el-option
                            label="Hospital/Facility"
                            value="facility"
                          />
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label-width="40%"
                        label="Dispatch Date"
                        prop="dispatch_date"
                      >
                        <el-date-picker
                          v-model="invoiceForm.dispatch_date"
                          type="date"
                          label="Pick a date"
                          placeholder="Pick a date"
                          style="width: 100%"
                        />
                      </el-form-item>

                      <el-form-item
                        label-width="40%"
                        label="Dispatch No."
                        prop="dispatch_number"
                      >
                        <el-input
                          placeholder="Dispatch No."
                          v-model="invoiceForm.dispatch_number"
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <hr class="horizontal dark m-3" />
                  <h6 class="mb-1 text-dark text-sm">
                    Pre-Auth/Hospital Invoice No.
                  </h6>
                  <el-form-item
                    label-width="40%"
                    label="Pre-Auth Code."
                    prop="pre_auth_code"
                  >
                    <el-input
                      placeholder="Pre-Auth Code"
                      v-model="invoiceForm.pre_auth_code"
                    />
                  </el-form-item>
                  <el-form-item
                    label-width="40%"
                    label="Hospital/Facility Inv. No"
                    prop="pre_auth_code"
                  >
                    <el-input
                      placeholder="Hospital/Facility Inv. No"
                      v-model="invoiceForm.facility_invoice_number"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row mt-2"
        v-if="
          invoiceForm.selected_appointment_value != null &&
          !organization.auto_invoice_services &&
          !organization.auto_invoice_products
        "
      >
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">Filter Items to be invoice</div>
            <div class="card-body">
              <div class="row">
                <div
                  class="col-md-6"
                  v-if="!organization.auto_invoice_services"
                >
                  <el-select
                    placeholder="Filter by services"
                    v-model="selected_services"
                    searchable
                    filterable
                    style="width: 100%"
                    multiple
                    :reserve-keyword="false"
                    placement="top"
                  >
                    <el-option
                      v-for="service in services"
                      :key="service"
                      :label="service.label"
                      :value="service"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="col-md-6"
                  v-if="!organization.auto_invoice_products"
                >
                  <el-select
                    placeholder="Filter by products"
                    v-model="selected_products"
                    searchable
                    filterable
                    style="width: 100%"
                    multiple
                    :reserve-keyword="false"
                    placement="top"
                  >
                    <el-option
                      v-for="product in products"
                      :key="product"
                      :label="product.label"
                      :value="product"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mt-4"
        v-if="invoiceForm.selected_appointment_value != null"
      >
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive p-0">
                <table class="table align-items-left mb-0">
                  <thead>
                    <tr>
                      <th
                        width="2%"
                        clas="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        #
                      </th>
                      <th
                        width="18%"
                        class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-1"
                      >
                        Item
                      </th>
                      <th
                        width="40%"
                        class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Desc
                      </th>
                      <th
                        width="20%"
                        class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Quantity
                      </th>
                      <th
                        width="10%"
                        class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Price
                      </th>

                      <th
                        width="10%"
                        class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Total(KES)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(service, index) in selected_services"
                      :key="index"
                    >
                      <td class="text-center text-sm">
                        {{ index + 1 }}
                      </td>
                      <td class="text-start text-sm">{{ service.label }}</td>
                      <td class="text-start text-sm ps-4">
                        {{ service.description }}
                      </td>
                      <td class="text-start text-sm ps-4">1</td>
                      <td class="text-end text-sm pe-4">
                        <el-input-number
                          :min="0"
                          v-model="service.price"
                          placeholder="0.00"
                          :controls="false"
                        />
                      </td>
                      <td class="text-end text-sm pe-4">
                        {{ service.price }}
                      </td>
                    </tr>
                    <tr
                      v-for="(product, index) in selected_products"
                      :key="index"
                    >
                      <td class="text-center text-sm">
                        {{ index + selected_products.length + 1 }}
                      </td>
                      <td class="text-start text-sm">{{ product.name }}</td>
                      <td class="text-start text-sm ps-4">
                        {{ product.description }}
                      </td>
                      <td class="text-start text-sm ps-4">
                        <el-input-number
                          :min="0"
                          v-model="product.quantity"
                          placeholder="0.00"
                          :controls="false"
                        />
                      </td>
                      <td class="text-end text-sm pe-4">
                        <el-input-number
                          :min="0"
                          v-model="product.price"
                          placeholder="0.00"
                          :controls="false"
                        />
                      </td>
                      <td class="text-end text-sm pe-4">
                        {{ product.totals }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4"></td>
                      <td class="text-end text-sm font-weight-bolder">
                        Sub Total (KES)
                      </td>
                      <td class="text-end text-sm font-weight-bolder pe-4">
                        {{ invoiceForm.sub_total }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                      <td class="text-end text-sm font-weight-bolder">
                        Discount (KES)
                      </td>
                      <td class="text-end text-sm font-weight-bolder p3-4">
                        <el-input-number
                          :min="0"
                          @input="handleDiscountChange"
                          v-model="invoiceForm.discount"
                          placeholder="0.00"
                          :controls="false"
                        />
                      </td>
                    </tr>

                    <!--  <tr>
          <td colspan="4"></td>
          <td class="text-end text-sm font-weight-bolder">TAX</td>
          <td class="text-end text-sm font-weight-bolder pe-4">
            15.00
          </td>
        </tr> -->
                    <tr>
                      <td colspan="4"></td>
                      <td class="text-end text-sm font-weight-bolder">
                        Total (KES)
                      </td>
                      <td class="text-end text-sm font-weight-bolder pe-4">
                        {{ invoiceForm.total }}
                      </td>
                    </tr>
                    <tr v-if="invoiceForm.invoice_payments != null">
                      <td colspan="4"></td>
                      <td class="text-end text-sm font-weight-bolder">
                        Paid (KES)
                      </td>
                      <td class="text-end text-sm font-weight-bolder pe-4">
                        {{ invoiceForm.invoice_payments_sum_amount }}
                      </td>
                    </tr>
                    <tr v-if="invoiceForm.invoice_payments != null">
                      <td colspan="4"></td>
                      <td class="text-end text-sm font-weight-bolder">
                        Balance (KES)
                      </td>
                      <td class="text-end text-sm font-weight-bolder pe-4">
                        {{
                          invoiceForm.total -
                          invoiceForm.invoice_payments_sum_amount
                        }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <el-form-item
                    label-width="40%"
                    label="Invoice Payment Method"
                    prop="payment_mode_id"
                  >
                    <el-select
                      class="w-100"
                      placeholder="Select Payment Method"
                      v-model="invoiceForm.payment_mode_id"
                    >
                      <el-option
                        v-for="(payment_mode, index) in payment_modes"
                        :key="index"
                        :label="payment_mode.label"
                        size="large"
                        :value="payment_mode.value"
                        border
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label-width="40%"
                    label="Co-Pay Amount"
                    prop="co_pay"
                  >
                    <el-input-number
                      :min="0"
                      :disabled="
                        parseFloat(
                          invoiceForm.patient_insurance?.employer?.co_pay
                        ) > 0.0
                      "
                      v-model="invoiceForm.co_pay"
                      placeholder="0.00"
                      :controls="false"
                    />
                    <div
                      v-if="
                        parseFloat(
                          invoiceForm.patient_insurance?.employer?.co_pay
                        ) > 0.0
                      "
                      class="spinner-grow text-success spinner-grow-sm ms-2"
                      role="status"
                    >
                      <span class="sr-only">has co_pay!</span>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 p-3" v-if="showProgress">
                  <el-progress
                    :stroke-width="10"
                    :percentage="download_perc"
                    status="success"
                  />
                </div>
                <div class="col-md-12 p-3">
                  <el-button
                    v-if="invoiceForm.selected_appointment_value != null"
                    type="success"
                    class="btn mb-0 btn-success btn-xs null"
                    :loading="isLoading"
                    @click="submitForm(invoiceFormRef)"
                  >
                    Save
                  </el-button>

                  <el-button
                    v-if="invoiceForm.selected_appointment_value != null"
                    type="danger"
                    class="btn mb-0 btn-danger btn-xs null"
                    @click="printInvoicePdf"
                  >
                    print
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="invoice.pdf"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <PrintInvoice
          :invoice="invoiceForm"
          :service_price_list="selected_services"
          :product_price_list="selected_products"
        />
      </template>
    </vue3-html2pdf>

    <InsuranceListModal
      v-if="showInsuranceListModal"
      @close-insurance-list-modal="showInsuranceListModal = false"
      @onSelect="onSelectHandler"
      :is_select="true"
      :insurances="invoiceForm.availableInsurances"
    />
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  createInvoice,
  updateInvoice,
  fetchInvoice,
} from "../../api/services/accounts";
import PrintInvoice from "./InvoiceDetailsPrint.vue";
import InsuranceListModal from "./InsuranceList.vue";
import { fetchUnInvoicedAppointments } from "../../api/services/appointments";
import { fetchPaymentModes, fetchServices } from "../../api/services/services";
import { fetchProducts } from "../../api/services/inventory";
import { ElNotification } from "element-plus";
import moment from "moment";
import Vue3Html2pdf from "vue3-html2pdf";
import { useRoute, useRouter } from "vue-router";
const invoiceFormRef = ref();
const isLoading = ref(false);
const appointments = ref([]);
const services = ref([]);
const products = ref([]);
const showInsuranceListModal = ref(false);
const payment_modes = ref([]);
const download_perc = ref(0);
const showProgress = ref(false);
const appointment_date = ref(new Date());
export default {
  name: "invoice-form",

  props: {
    invoice: {
      type: Object,
      required: false,
    },
    image_type: {
      type: String,
      required: false,
      default: "png",
    },
  },
  components: {
    InsuranceListModal,
    Vue3Html2pdf,
    PrintInvoice,
  },
  watch: {
    selected_services: {
      handler: function (newValue) {
        this.invoiceForm.service_sub_total = 0;
        this.invoiceForm.service_sub_total += newValue.reduce(
          (accumulator, item) => {
            return accumulator + item.price;
          },
          0
        );

        this.invoiceForm.sub_total =
          this.invoiceForm.service_sub_total +
          this.invoiceForm.product_sub_total;
        this.invoiceForm.total =
          this.invoiceForm.sub_total - this.invoiceForm.discount;
      },

      deep: true,
    },
    selected_products: {
      handler: function (newValue) {
        newValue.map((product) => {
          product.totals = product.quantity * product.price;
        });
        this.invoiceForm.product_sub_total = 0;
        this.invoiceForm.product_sub_total += newValue.reduce(
          (accumulator, item) => {
            return accumulator + item.totals;
          },
          0
        );
        this.invoiceForm.sub_total =
          this.invoiceForm.service_sub_total +
          this.invoiceForm.product_sub_total;
        this.invoiceForm.total =
          this.invoiceForm.sub_total - this.invoiceForm.discount;
      },
      deep: true,
    },
  },
  setup(props) {
    const selected_services = ref([]);
    const selected_products = ref([]);
    const route = useRoute();
    const router = useRouter();
    const invoice_id = route.params?.id;

    const htmlToPdfOptions = {
      margin: 0.3,

      image: {
        type: props.image_type,
        quality: 2,
      },
      enableLinks: false,
      html2canvas: {
        scale: 5,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };

    const invoiceForm = reactive({
      id: invoice_id || 0,
      invoice_number: "",
      invoice_date: new Date(),
      dispatch_date: new Date(),
      dispatch_number: "",
      pre_auth_code: "",
      co_pay: 0.0,
      discount: 0,
      patient: "",
      patient_insurance: "",
      appointment_services: null,
      availableInsurances: null,
      patient_insurance_id: "",
      total: 0,
      sub_total: 0,
      service_sub_total: 0,
      product_sub_total: 0,
      appointment: null,
      payment_mode_id: "",
      appointment_id: "",
      invoice_items: "",
      selected_appointment_value: null,
      channel: "",
      facility_invoice_number: "",
    });
    const onSelectHandler = (item) => {
      invoiceForm.patient_insurance = item;
      showInsuranceListModal.value = false;
    };
    const removeInsurance = () => {
      invoiceForm.patient_insurance = null;
    };

    const handleAppointmentSelect = (item) => {
      selected_services.value = [];
      selected_products.value = [];
      invoiceForm.selected_appointment_value = item.value;
      invoiceForm.appointment_services = item.value?.record?.services;

      invoiceForm.availableInsurances = item.value?.patient?.patient_insurances;

      item.value?.record?.services.map((service) => {
        selected_services.value.push({
          value: `${service.id}`,
          label: `${service.item}`,
          description: `${service.description}`,
          quantity: 1,
          price: `${service.price}`,
          totals: `${service.price}`,
          item_type: "service",
          item_id: `${service.id}`,
        });
      });

      item.value?.record?.products.map((product) => {
        selected_products.value.push({
          value: `${product.id}`,
          label: `${product.name}`,
          id: product?.id,
          name: product?.name,
          description: product?.description,
          quantity: product?.pivot?.quantity,
          price: product?.activestock?.unit_selling_price,
          totals:
            product?.activestock?.unit_selling_price * product?.pivot?.quantity,
          item_type: "product",
          item_id: product.id,
        });
      });

      invoiceForm.patient = item.value?.patient;
      if (item.value?.patient?.patient_insurances[0] != undefined)
        invoiceForm.patient_insurance =
          item.value?.patient?.patient_insurances[0];
      else invoiceForm.patient_insurance = ref();

      invoiceForm.co_pay = parseFloat(
        invoiceForm.patient_insurance?.employer?.co_pay || 0
      );
    };
    const handleDiscountChange = (val) => {
      invoiceForm.total = invoiceForm.sub_total - val;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const modifyPatientType = (type) => {
      if (type == "in-patient") return "In Patient";
      if (type == "out-patient") return "Out Patient";
      if (type == "day-case") return "Day Case";
    };

    const showInsurances = (insurances) => {
      showInsuranceListModal.value = true;
      invoiceForm.availableInsurances.value = insurances;
    };

    const rules = reactive({
      invoice_date: [
        {
          required: true,
          message: "Please indicate the invoice date",
          trigger: ["blur", "change"],
        },
      ],
      /*  dispatch_date: [
        {
          required: true,
          message: "Please indicate the dispatch date",
          trigger: ["blur", "change"],
        },
      ],
      dispatch_number: [
        {
          required: true,
          message: "Please indicate the dispatch number",
          trigger: ["blur", "change"],
        },
      ],*/
      payment_mode_id: [
        {
          required: false,
          message: "Please indicate the payment method",
          trigger: ["blur"],
        },
      ],
      co_pay: [
        {
          required: false,
          message: "Please indicate the amount to be paid",
          trigger: ["blur", "change"],
        },
      ],
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;

      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("invoice_number", invoiceForm.invoice_number);

          formData.append(
            "invoice_date",
            moment(invoiceForm.invoice_date).format("Do-MM-YYYY")
          );
          invoiceForm.invoice_date = moment(invoiceForm.invoice_date).format(
            "YYYY-MM-DD"
          );
          formData.append(
            "dispatch_date",
            moment(invoiceForm.dispatch_date).format("Do-MM-YYYY")
          );
          invoiceForm.dispatch_date = moment(invoiceForm.dispatch_date).format(
            "YYYY-MM-DD"
          );
          formData.append("dispatch_number", invoiceForm.dispatch_number);
          formData.append("sub_total", invoiceForm.sub_total);
          formData.append("pre_auth_code", invoiceForm.pre_auth_code);
          formData.append("co_pay", invoiceForm.co_pay);
          formData.append("discount", invoiceForm.discount);
          formData.append("tax", "0.00");
          formData.append("total", invoiceForm.total);
          formData.append("payment_mode_id", invoiceForm.payment_mode_id);
          formData.append("channel", invoiceForm.channel);
          formData.append(
            "facility_invoice_number",
            invoiceForm.facility_invoice_number
          );

          formData.append(
            "appointment_id",
            parseInt(invoiceForm.appointment?.value?.id || 0)
          );
          formData.append(
            "patient_id",
            parseInt(invoiceForm.appointment?.value?.patient?.id || 0)
          );
          formData.append("hospital_id", 0);
          formData.append(
            "patient_insurance_id",
            invoiceForm.patient_insurance?.id || 0
          );
          invoiceForm.patient_insurance_id =
            invoiceForm.patient_insurance?.id || 0;
          const _invoice_items = [];

          selected_services.value.map((item) => {
            _invoice_items.push({
              description: item.description,
              quantity: 1,
              price: item.price,
              totals: item.price,
              item_type: "service",
              item_id: item.item_id,
            });
          });
          selected_products.value.map((item) => {
            _invoice_items.push({
              description: item.description,
              quantity: item.quantity,
              price: item.price,
              totals: item.totals,
              item_type: "product",
              item_id: item.item_id,
            });
          });

          invoiceForm.invoice_items = JSON.stringify(_invoice_items);
          formData.append("invoice_items", invoiceForm.invoice_items);

          if (invoiceForm.id > 0) {
            updateInvoice(invoiceForm)
              .then(function (data) {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  router.push("/accounts/invoices");
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createInvoice(formData)
              .then(function (data) {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  router.push("/accounts/invoices");
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        duration: 4500,
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      duration: 4500,
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const fetchInvoiceData = async () => {
      if (invoice_id) {
        isLoading.value = true;
        try {
          const response = await fetchInvoice(invoice_id);
          //add the appointment if not part of loaded appointments
          if (response.data?.appointment_details?.id != null) {
            if (
              !appointments.value.some(
                (appointment) =>
                  appointment.value.value.id ==
                  response.data?.appointment_details?.id
              )
            ) {
              appointments.value.push({
                value: {
                  value: response.data?.appointment_details,
                },
                label: `${
                  moment(
                    response.data?.appointment_details.appointment_date
                  ).format("Do MMM YYYY") +
                  " " +
                  response.data?.appointment_details.appointment_number +
                  " " +
                  response.data?.appointment_details.patient.first_name +
                  " " +
                  response.data?.appointment_details.patient.last_name
                }`,
              });
            }
          }

          appointments.value.filter(function (appointment) {
            if (
              appointment.value.value.id ==
              response.data?.appointment_details?.id
            ) {
              invoiceForm.appointment = appointment;
            }
          });
          invoiceForm.invoice_date = response.data?.invoice_date;
          invoiceForm.dispatch_date = response.data?.invoice_date;
          invoiceForm.invoice_number = response.data?.invoice_number;
          invoiceForm.dispatch_number = response.data?.dispatch_number;
          invoiceForm.pre_auth_code = response.data?.pre_auth_code;
          invoiceForm.co_pay = response.data?.co_pay;
          invoiceForm.discount = response.data?.discount;
          invoiceForm.payment_method = response.data?.discount;
          invoiceForm.appointment_id = response.data?.appointment_details?.id;
          invoiceForm.selected_appointment_value =
            response.data?.appointment_details;
          invoiceForm.invoice_payments_sum_amount =
            response.data.invoice_payments_sum_amount;
          invoiceForm.facility_invoice_number =
            response.data?.facility_invoice_number;
          invoiceForm.channel = response.data?.channel;

          invoiceForm.patient = response.data?.patient;
          invoiceForm.patient_insurance = response.data?.patient_insurance;
          invoiceForm.invoice_payments = response.data?.invoice_payments;

          invoiceForm.payment_mode_id = String(
            response.data?.payment_mode_id || ""
          );

          invoiceForm.appointment_services =
            response.data?.appointment_details?.record?.services;

          selected_services.value = response.data?.appointment_details?.record?.services.map(
            (item) => ({
              value: `${item.id}`,
              label: `${item.item}`,
              id: item.id,
              item: item.item,
              description: item.description,
              quantity: item.quantity,
              price:
                response.data?.service_invoice_items
                  .filter((service) => {
                    return service.item_id == item.id;
                  })
                  .shift()?.price ?? item.price,
              item_type: "service",
              item_id: item.id,
              totals: item.totals,
            })
          );

          selected_products.value = response.data?.appointment_details?.record?.products.map(
            (item) => ({
              value: `${item.id}`,
              label: `${item.name}`,
              id: item.id,
              name: item.name,
              description: item.description,
              quantity:
                response.data?.product_invoice_items
                  .filter((product) => {
                    return product.item_id == item.id;
                  })
                  .shift()?.quantity ?? item.quantity,
              price:
                response.data?.product_invoice_items
                  .filter((product) => {
                    return product.item_id == item.id;
                  })
                  .shift()?.price ?? item.price,
              item_type: "product",
              item_id: item.id,
              totals:
                response.data?.product_invoice_items
                  .filter((product) => {
                    return product.item_id == item.id;
                  })
                  .shift()?.totals ?? item.totals,
            })
          );

          invoiceForm.availableInsurances =
            response.data?.patient?.patient_insurances;
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      }
    };
    const loadAppointments = async (query) => {
      const entity_params = ref();
      entity_params.value = {
        search_query: query,
        pending_invoice: invoiceForm.id > 0 ? 0 : 1,
        date:
          invoiceForm.id > 0
            ? null
            : moment(appointment_date.value).format("YYYY-MM-DD"),
      };
      isLoading.value = true;
      try {
        const response = await fetchUnInvoicedAppointments(entity_params, 1);
        const tempAppointments = response.data.data.map((appnt) => ({
          value: {
            value: appnt,
          },
          label: `${
            moment(appnt.appointment_date).format("Do MMM YYYY") +
            " " +
            appnt?.appointment_number +
            " " +
            appnt?.patient?.first_name +
            " " +
            appnt?.patient?.last_name
          }`,
        }));

        appointments.value = tempAppointments;
        fetchInvoiceData();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchPaymentModesList = async () => {
      try {
        const response = await fetchPaymentModes();

        const tempPaymentModes = response.data.data.map((unit) => ({
          value: `${unit.id}`,
          label: `${unit.name}`,
        }));
        payment_modes.value = tempPaymentModes;
      } catch (error) {
        console.log(error);
      }
    };
    const loadServices = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchServices(entity_params);
        const tempServices = response.data.data.map((service) => ({
          value: `${service.id}`,
          label: `${service.item}`,
          description: `${service.description}`,
          quantity: 1,
          price: `${service.price}`,
          totals: `${service.price}`,
          item_type: "service",
          item_id: `${service.id}`,
        }));
        services.value = tempServices;
      } catch (error) {
        console.log(error);
      }
    };
    const loadProducts = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchProducts(entity_params);

        const tempProducts = response.data.data.map((product) => ({
          value: `${product.id}`,
          label: `${product.name}`,
          id: product?.id,
          name: product?.name,
          description: product?.description,
          quantity: 1,
          price: product?.activestock?.unit_selling_price,
          totals: product?.activestock?.unit_selling_price * 1,
          item_type: "product",
          item_id: product.id,
        }));
        products.value = tempProducts;
      } catch (error) {
        console.log(error);
      }
    };
    const organization = JSON.parse(localStorage.getItem("organization"));
    onMounted(() => {
      loadAppointments("");
      fetchPaymentModesList();
      loadServices("", 1);
      loadProducts("", 1);
    });
    const onProgress = async (percentage) => {
      if (percentage == 100) showProgress.value = false;
      else {
        showProgress.value = true;
      }
      download_perc.value = percentage;
    };

    return {
      removeInsurance,
      appointment_date,
      onProgress,
      showProgress,
      download_perc,
      htmlToPdfOptions,
      modifyPatientType,
      fetchPaymentModes,
      loadAppointments,
      showInsuranceListModal,
      showInsurances,
      invoiceForm,
      submitForm,
      rules,
      organization,
      invoiceFormRef,
      isLoading,
      props,
      appointments,
      onSelectHandler,
      handleAppointmentSelect,
      dateTime,
      handleDiscountChange,
      payment_modes,
      services,
      products,
      selected_services,
      selected_products,
    };
  },
  methods: {
    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
    printInvoicePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
<style scoped>
.appointment_type {
  border-left: solid 2px red;
}

.el-form-item--label-top .el-form-item__label {
  width: 100% !important;
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 10px;
}
.el-form-item--label-top .el-form-item__content {
  margin-left: 0 !important;
}

.el-input-number .el-input__inner {
  text-align: right !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  outline: 0;
  border-radius: 7px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card-body {
  max-height: 80%;
  overflow-x: hidden;
  overflow-y: auto;
}
.invoice-body {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
.el-form-item-label {
  font-weight: bold;
}
</style>
