<template>
  <div class="modal-overlay">
    <div class="card" @click.stop>
      <div class="card-body">
        <vue-pdf-app style="height: 100vh;" :pdf="props.pdfUrl"></vue-pdf-app>

      </div>
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";
export default {
  components: {
    VuePdfApp
  },
  props: {
    pdfUrl: String, // URL of the PDF to display
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  margin-top: 2%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2500;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}
.card {
  outline: 0;
  border-radius: 7px;
  width: 90%;
  height: 100%;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-body {
  max-height: 900px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
  padding: 0 !important
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}

.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}
</style>
