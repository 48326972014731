import { api } from "@/api";

export const fetchDiagnosis = async (filter, page) => {
  var url = "diagnoses?orderBy=name&sortedBy=asc";

  if (filter.value !== undefined && filter.value.search_query)
    url = url + "&searchFields=name:like;&search=" + filter.value.search_query;

  if (page) url = url + "&page=" + page;
  url = url.replace("diagnoses?&", "diagnoses?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};