<template>
  <div class="modal-overlay" @click="$emit('close-insurance-list-modal')">
    <div class="card" @click.stop>
      <div class="card-header">Insurance List</div>
      <hr class="horizontal dark m-0" />

      <div class="modal-body">
        <ul id="notebook_ul">
          <li v-for="(item, index) in insurances" :key="item.id + index">
            <div v-if="item?.scheme_type == 'insurance'">
              Insurance Name:<b>{{ item?.insurance?.name }}</b
              ><br />
            </div>
            Scheme/Employer:<b>{{ item?.employer?.name }}</b
            ><br />
            <div v-if="item.scheme_type == 'insurance'">
              Membership Name.: <b>{{ item?.membership_name }}</b
              ><br />
              Membership No.: <b>{{ item?.membership_number }}</b
              ><br />
            </div>
            <div v-if="item.scheme_type == 'self'">
              Employee Name.: <b>{{ item?.employee_name }}</b
              ><br />
              Employee No.: <b>{{ item?.employee_number }}</b
              ><br />
            </div>
            <div class="right top">
              Expiry date: <br />
              <b>{{ dateTime(item?.expiry_date) }}</b>
            </div>
            <div class="right">
              <button
                v-if="props?.is_select"
                @click="select(item)"
                class="btn btn-success btn-sm p-2 pt-1 pb-1 me-1 rounded-sm"
              >
                Select
              </button>
              <button
                v-if="!props.is_select"
                @click="edit(item)"
                class="btn btn-primary btn-sm p-2 pt-1 pb-1 me-1 rounded-sm"
              >
                Edit</button
              ><button
                v-if="!props.is_select"
                @click="remove(item)"
                class="btn btn-danger btn-sm p-2 pt-1 pb-1"
              >
                Remove
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="close bg-danger" @click="$emit('close-insurance-list-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "add-patient-list-insurance",
  props: {
    insurances: {
      type: Array,
    },
    is_select: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const select = (i) => {
      emit("onSelect", i);
    };

    const edit = (i) => {
      emit("onEdit", i);
    };
    const remove = (i) => {
      emit("onRemove", i);
    };
    const dateTime = (value) => {
      return moment(value).format("MM/DD/YYYY");
    };
    return {
      props,
      select,
      edit,
      remove,
      dateTime,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 600px;
  height: 90%;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

.modal-body {
  padding: 20px;
}
ul {
  margin: 0 auto;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;

  padding: 5px 5px 0 5px;
  border-left: none;
  border-right: none;
}

li {
  list-style: none;
  background-color: rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(
    90deg,
    #ffd32e 10px,
    #eee 10px,
    #eee 11px,
    transparent 11px
  );
  padding: 10px 15px 10px 25px;
  border: 1px solid #ccc;
  box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 3px;
  font-size: 12px;
  margin-bottom: 20px;
  min-height: 130px;
}
.right {
  float: right;
  position: relative;
  top: 6px;
}

.top {
  margin-top: -45px;
}
</style>
