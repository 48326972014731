<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="row" v-else>
    <div class="col-md-12">
      <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Name</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{
                patientDetails.first_name +
                " " +
                patientDetails.middle_name +
                " " +
                patientDetails.last_name
              }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Patient No.:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ capitalized(patientDetails.patient_number) }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">ID Number.:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ capitalized(patientDetails.id_number) }}
            </div>
          </div>
        </div>
        <div class="vl"></div>

        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Mobile Number</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ patientDetails.phone }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Email:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ getAge(patientDetails.email) }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Age:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ getAge(patientDetails.date_of_birth) }}
            </div>
          </div>
        </div>
        <div class="vl"></div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Gender:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ capitalized(patientDetails.gender) }}
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Marital Status:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ capitalized(patientDetails.marital_status) }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Address:</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ patientDetails.address }}
            </div>
          </div>
        </div>
        <div class="vl"></div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">Joined</div>
            <div class="ps-1 text-dark text-bold text-sm">
              {{ dateTime(patientDetails.created_at) }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="p-0 text-sm">balance</div>
            <div class="ps-1 text-dark text-bold text-sm">--</div>
          </div>
        </div>
        <div class="vl"></div>
        <div class="d-flex flex-column">
          <TimelineDrawerVue :patient_id="props.patient_id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { ref, onMounted } from "vue";
import moment from "moment";
import { fetchPatientDetails } from "../../api/services/users";
import TimelineDrawerVue from "../../components/Drawers/TimelineDrawer.vue";
const isLoading = ref(false);
const patientDetails = ref({});
export default {
  components: { Loader, TimelineDrawerVue },
  name: "patient-info",
  props: {
    patient_id: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const fetchPatientData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchPatientDetails(props.patient_id);

        patientDetails.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const capitalized = (name) => {
      try {
        const capitalizedFirst = name[0].toUpperCase();
        const rest = name.slice(1);

        return capitalizedFirst + rest;
      } catch (e) {
        return "--";
      }
    };
    const getAge = (birthDate) => {
      try {
        return Math.floor(
          (new Date() - new Date(birthDate).getTime()) / 3.15576e10
        );
      } catch (e) {
        return "--";
      }
    };
    onMounted(() => {
      if (props.patient_id != undefined) fetchPatientData(props.patient_id);
    });

    return {
      props,
      fetchPatientData,
      isLoading,
      patientDetails,
      capitalized,
      dateTime,
      getAge,
    };
  },
};
</script>
<style scoped>
.side-glance {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
}
.sidebar button {
  margin: 20px auto;
  display: block;
}
.vl {
  margin: 5px 20px;
  border: 1px solid #2ece89;
  height: 50px;
}
</style>
