<template>
  <div class="card">
    <div v-if="isLoading"><Loader /></div>
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-file-o text-primary text-md opacity-10"></i>
        <h6>Invoices</h6>
      </div>
      <div class="cta">
        <el-dropdown trigger="click" v-if="selected.length > 0">
          <span class="el-dropdown-link">
            <el-button class="me-2" size="large" circle>
              <i class="fa fa-ellipsis-v text-primary"></i>
            </el-button>
          </span>
          <template #dropdown>
            <el-dropdown-item
              class="text-danger font-weight-bold text-xs"
              v-if="hasPermission('delete.invoice')"
              @click="ConfirmDeleteInvoices()"
            >
              Delete selected Invoices
            </el-dropdown-item>
            <el-dropdown-item
              class="text-primary font-weight-bold text-xs"
              v-if="hasPermission('update.invoice_status')"
              @click="UpdateStatusSelectedInvoices()"
            >
              Update Selected Invoices Status
            </el-dropdown-item>
          </template>
        </el-dropdown>

        <el-button-group>
          <FilterTables
            :show_search_filter="true"
            :show_date_filter="true"
            :show_insurance_filter="true"
            :show_patient_filter="true"
            :show_payment_status_filter="true"
            :show_employer_filter="true"
            :show_doctor_filter="true"
            :show_appointment_type_filter="true"
            :show_report_format_filter="true"
            :show_hospital_filter="true"
            date_range_label="Invoice Date"
            invoice_status_label="Select invoice status"
            search_label="Search invoice records"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />

          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
          <el-button type="warning" @click="downloadGeneralExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >General Report</el-button
          >

          <el-button
            color="#626aef"
            @click="$router.push(`/accounts/invoice/form`)"
            v-if="hasPermission('create.invoice')"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >New Invoice</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="invoices.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="invoices.data.length > 0">
        <table class="table align-items-left mb-0">
          <thead>
            <tr>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Action
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                <input type="checkbox" v-model="selectAll" />
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Patient details
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Payer
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Inv No
              </th>

              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Amt(KES)
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Paid(KES)
              </th>

              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Inv Date
              </th>

              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Appt No
              </th>

              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Co Pay (KES)
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                Balance
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in invoices.data" :key="item.id + index">
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        class="text-default font-weight-bold text-xs"
                        v-if="hasPermission('view.invoice')"
                        @click="preview(item)"
                      >
                        Preview</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="text-default font-weight-bold text-xs"
                        v-if="
                          hasPermission('update.invoice_status') &&
                          item.invoice_status.status != 'draft'
                        "
                        @click="update_status(item)"
                      >
                        Update Status</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="text-default font-weight-bold text-xs"
                        v-if="
                          hasPermission('update.invoice') &&
                          item.invoice_status.status == 'draft'
                        "
                        @click="
                          $router.push(`/accounts/invoice/form/${item.id}`)
                        "
                      >
                        Edit
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="text-default font-weight-bold text-xs"
                        v-if="hasPermission('update.invoice')"
                        @click="stk_push(item)"
                      >
                        Make Payment</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="text-danger font-weight-bold text-xs"
                        v-if="hasPermission('delete.invoice')"
                        @click="ConfirmDeleteInvoice(item.id)"
                      >
                        Delete Invoice
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
              <td>
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="item.id"
                  number
                />
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{ getInitials(item?.patient?.name) }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item?.patient?.name }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ item?.patient?.phone }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.patient_insurance != undefined">
                  <div
                    class="text-sm"
                    v-if="item.patient_insurance.employer != undefined"
                  >
                    {{ item.patient_insurance.employer.name }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="item.patient_insurance.insurance != undefined"
                  >
                    {{ item.patient_insurance.insurance.name }}
                  </div>
                </div>
                <div class="text-sm" v-if="item.patient_insurance == undefined">
                  {{ item?.payment_mode?.name }}
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">
                  {{ item.invoice_number }}
                </p>
                <p
                  class="text-xs text-secondary mb-0"
                  v-if="item.invoice_status != null"
                >
                  <argon-badge
                    size="sm"
                    v-if="item.invoice_status.status == 'approved'"
                    color="success"
                    >{{ item.invoice_status.status }}</argon-badge
                  >
                  <argon-badge
                    size="sm"
                    v-if="item.invoice_status.status == 'draft'"
                    color="primary"
                    >{{ item.invoice_status.status }}</argon-badge
                  >
                  <argon-badge
                    size="sm"
                    v-if="
                      item.invoice_status.status == 'withdrawn' ||
                      item.invoice_status.status == 'bad_debt'
                    "
                    color="danger"
                    >{{ item.invoice_status.status }}</argon-badge
                  >
                </p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{ item.total }}</p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">
                  {{ item.invoice_payments_sum_amount }}
                </p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">
                  {{ dateTime(item.invoice_date) }}
                </p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0" v-if="item.appointment">
                  {{ item.appointment.appointment_number }}
                </p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">
                  {{ item.co_pay }}
                </p>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">
                  {{ item.total - item.invoice_payments_sum_amount }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>

  <vue3-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1400"
    filename="invoice.pdf"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="organization.inv_paper_size"
    pdf-orientation="organization.inv_print_layout"
    :html-to-pdf-options="htmlToPdfOptions"
    pdf-content-width="100%"
    @progress="onProgress($event)"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <PrintInvoice
        :invoice="invoiceDetails"
        :service_price_list="service_price_list"
        :product_price_list="product_price_list"
      />
    </template>
  </vue3-html2pdf>
  <InvoiceStatusForm
    v-if="showInvoiceStatusModal"
    @close-modal="showInvoiceStatusModal = false"
    :invoice="invoiceDetails"
    :invoices="selected"
    :multiple="isMultiple"
    @refresh-invoices="refresh"
  />
  <StkPushForm
    v-if="showStkPushModal"
    @close-modal="showStkPushModal = false"
    :invoice="invoiceDetails"
    @refresh-invoices="refresh"
  />
</template>
<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import Pagination from "./Pagination.vue";
import FilterTables from "./FilterTables.vue";
import PrintInvoice from "../../components/Modals/InvoiceDetailsPrint.vue";
import StkPushForm from "../../components/Modals/StkPushForm.vue";
import InvoiceStatusForm from "../../components/Modals/InvoiceStatusForm.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import moment from "moment";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  downloadInvoicesExcel,
  downloadGeneralInvoicesExcel,
  downloadInvoicesPdf,
  fetchInvoice,
  deleteInvoice,
  deleteInvoices,
} from "../../api/services/accounts";
import { h, ref, reactive, computed } from "vue";
import EmptyStateTable from "../components/EmptyTableState.vue";
import Loader from "../components/Loader.vue";
import { useRoute } from "vue-router";
const showModal = ref(false);
const showInvoiceStatusModal = ref(false);
const showStkPushModal = ref(false);
const service_price_list = ref({});
const product_price_list = ref({});
const invoiceDetails = ref({});
const filter_params = ref({});
const selected = ref([]);
const isMultiple = ref(false);
const download_perc = ref(0);
const showProgress = ref(false);
const isLoading = ref(false);
const organization = ref({});
const html2Pdf = ref();
export default {
  name: "invoices-table",
  props: {
    invoices: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },

  components: {
    Pagination,
    EmptyStateTable,
    FilterTables,
    PrintInvoice,
    StkPushForm,
    InvoiceStatusForm,
    Vue3Html2pdf,
    Loader,
    ArgonBadge,
  },

  setup(props) {
    organization.value = JSON.parse(localStorage.getItem("organization"));
    const route = useRoute();
    if (route.params?.insurance_id) {
      filter_params.value = {
        insurance_id: route.params?.insurance_id,
      };
    }
    if (route.params?.doctor_id) {
      filter_params.value = {
        doctor_id: route.params?.doctor_id,
      };
    }

    const selectAll = computed({
      get() {
        if (props?.invoices?.data && props?.invoices?.data?.length > 0) {
          // A users array exists with at least one item
          let allChecked = true;

          for (const invoice of props?.invoices?.data) {
            if (!selected.value.includes(invoice.id)) {
              allChecked = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          props?.invoices?.data?.forEach((invoice) => {
            checked.push(invoice.id);
          });
        }

        selected.value = checked;
      },
    });

    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-warning";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const htmlToPdfOptions = {
      margin: 0.3,

      image: {
        type: "jpeg", // 'png', 'jpeg', and 'webp'
        quality: 2,
      },

      enableLinks: false,

      html2canvas: {
        scale: 5,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: organization.value.inv_paper_size, //A5
        orientation: organization.value.inv_print_layout, //portrait
      },
    };

    const total_records = props?.invoices?.total || 0;
    const per_page = props?.invoices?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.invoices?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadInvoicesPdf(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "invoices_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const downloadGeneralExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadGeneralInvoicesExcel(filter_params)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "general_report_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }
    const downloadExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadInvoicesExcel(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "invoice_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const update_status = (invoice) => {
      isMultiple.value = false;
      invoiceDetails.value = invoice;
      showInvoiceStatusModal.value = true;
    };
    const stk_push = (invoice) => {
      invoiceDetails.value = invoice;
      showStkPushModal.value = true;
    };

    const UpdateStatusSelectedInvoices = () => {
      isMultiple.value = true;
      showInvoiceStatusModal.value = true;
    };

    const ConfirmDeleteInvoice = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "You are about to trash an invoice. This action cannot be undone. Continue?"
        ),

        "Trash Invoice",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await deleteInvoice(id);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    const ConfirmDeleteInvoices = async () => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The selected invoices will be trashed. This action cannot be undone. Continue?"
        ),

        "Trash Invoices",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;

            const formData = new FormData();
            formData.append("invoice_ids", JSON.stringify(selected.value));
            const { message, success } = await deleteInvoices(formData);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const preview = async (invoice) => {
      try {
        isLoading.value = true;
        const response = await fetchInvoice(invoice.id);
        isLoading.value = false;

        invoiceDetails.value = response.data;

        service_price_list.value = response.data.service_invoice_items.map(
          (item) => ({
            id: item.service.id,
            item: item.service.item,
            description: item.description,
            quantity: item.quantity,
            price: item.price,
            item_type: item.item_type,
            item_id: item.item_id,
            totals: item.totals,
          })
        );

        product_price_list.value = response.data.product_invoice_items.map(
          (item) => ({
            id: item.product.id,
            name: item.product.name,
            description: item.description,
            quantity: item.quantity,
            price: item.price,
            item_type: item.item_type,
            item_id: item.item_id,
            totals: item.totals,
          })
        );
        html2Pdf.value.generatePdf();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const onProgress = async (percentage) => {
      if (percentage == 100) showProgress.value = false;
      else {
        showProgress.value = true;
      }
      download_perc.value = percentage;
    };

    return {
      html2Pdf,
      preview,
      UpdateStatusSelectedInvoices,
      ConfirmDeleteInvoice,
      ConfirmDeleteInvoices,
      organization,
      isMultiple,
      selectAll,
      selected,
      isLoading,
      onProgress,
      showProgress,
      download_perc,
      htmlToPdfOptions,
      filter_params,
      _filterCallBack,
      onPageChange,
      Pagination,
      update_status,
      stk_push,
      dateTime,
      getStatusColor,
      invoiceDetails,
      service_price_list,
      product_price_list,
      showModal,
      showInvoiceStatusModal,
      showStkPushModal,
      getInitials,
      downloadExcel,
      downloadGeneralExcel,
      downloadPdf,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
.text-sm {
  font-size: 0.6rem !important;
}
</style>
