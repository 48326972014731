<template>
  <div class="modal-overlay" @click="$emit('close-employer-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-address-book-o text-primary me-2" />{{
          props.employer.id != null ? "Edit Employer" : "New Employer"
        }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="employerFormRef"
        :model="employerForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="mb-3">
            <el-form-item label="Insurance" prop="insurance_id">
              <el-select-v2
                v-model="insurance_id"
                filterable
                remote
                clearable
                :options="insurances"
                placeholder="Please select insurance"
                style="width: 100%"
                :remote-method="loadInsurances"
                :loading="isLoading"
              >
              </el-select-v2>
            </el-form-item>
          </div>

          <div class="mb-3">
            <el-form-item label="Employer Name" prop="name">
              <el-input v-model="employerForm.name" />
            </el-form-item>
          </div>
          <div class="mt-4">
            <el-checkbox
              v-model="employerForm.restricted"
              label="Restricted use?"
              border
            />
          </div>
          <div class="mb-3">
            <el-form-item label="Co_Pay Amount" prop="co_pay">
              <el-input v-model="employerForm.co_pay" />
            </el-form-item>
          </div>

          <div class="mb-3">
            <el-form-item label="Phone Number" prop="phone">
              <el-input v-model="employerForm.contact_phone" />
            </el-form-item>
          </div>

          <div class="mb-3">
            <el-form-item label="Email" prop="email">
              <el-input v-model="employerForm.contact_email" />
            </el-form-item>
          </div>
          <div class="mb-3">
            <el-form-item label="Contact Address" prop="address">
              <el-input v-model="employerForm.contact_address" />
            </el-form-item>
          </div>

          <div class="mb-3">
            <el-form-item label="Description" prop="description">
              <el-input v-model="employerForm.description" type="textarea" />
            </el-form-item>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs"
            @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(employerFormRef)"
            >Submit</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {
  createEmployer,
  updateEmployer,
  fetchInsurances,
} from "../../api/services/services";
import { ElNotification } from "element-plus";
const employerFormRef = ref();
const isLoading = ref(false);
const insurances = ref([]);
export default {
  name: "add-employer",
  props: {
    employer: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const employerForm = reactive({
      id: props?.employer?.id || 0,
      name: props?.employer?.name || "",
      contact_phone: props?.employer?.contact_phone || "",
      contact_email: props?.employer?.contact_email || "",
      contact_address: props?.employer?.contact_address || "",
      description: props?.employer?.description || "",
      restricted: props?.employer?.restricted == 1 || false,
      insurance_id:
        props?.employer?.insurance_id != null
          ? String(props?.employer?.insurance_id)
          : "",
      status: props?.employer?.status || 1,
      co_pay: props?.employer?.co_pay || 0,
    });

    const rules = reactive({
      contact_email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      name: [
        {
          required: true,
          message: "Please input inurance name",
          trigger: ["blur", "change"],
        },
      ],
      co_pay: [
        {
          required: true,
          message: "Please input co_pay amount",
          trigger: ["blur", "change"],
        },
      ],
      contact_phone: [
        {
          required: true,
          message: "Please input phone number",
          trigger: ["blur", "change"],
        },
      ],
      contact_address: [
        {
          required: true,
          message: "Please input contact address",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: false,
          message: "Please input a description",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          const formData = new FormData();
          employerForm.restricted = employerForm.restricted ? 1 : 0;

          formData.append("name", employerForm.name);
          formData.append("contact_phone", employerForm.contact_phone);
          formData.append("contact_email", employerForm.contact_email);
          formData.append("contact_address", employerForm.contact_address);
          formData.append("description", employerForm.description);
          formData.append("insurance_id", employerForm.insurance_id);
          formData.append("co_pay", employerForm.co_pay);
          formData.append("status", employerForm.status);
          formData.append("restricted", employerForm.restricted);

          if (employerForm.id > 0) {
            updateEmployer(employerForm)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-employers");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createEmployer(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("employer-created", data.data);
                  emit("refresh-employers");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const loadInsurances = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchInsurances(entity_params);

        const tempInsurances = response.data.data.map((nsurance) => ({
          value: `${nsurance.id}`,
          label: `${nsurance.name}`,
        }));
        insurances.value = tempInsurances;
      } catch (error) {
        console.log(error);
      }
    };
    return {
      loadInsurances,
      submitForm,
      rules,
      employerForm,
      employerFormRef,
      isLoading,
      insurances,
      props,
    };
  },
  created() {
    this.loadInsurances("");
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 450px;
  max-height: 520px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}

.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
