<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <brands-table 
          :brands="brands"
          :refresh="fetchData" 
          :searchQuery="searchTerm"
          v-else />
      </div>
    </div>
  </div>
</template>

<script>
import BrandsTable from "./components/BrandTable.vue";
import Loader from "./components/Loader.vue";
import { fetchBrands } from "../api/services/inventory";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const brands = ref([]);
const searchTerm = ref([]);

export default {
  name: "brands",
  components: {
    BrandsTable,
    Loader,
  },
  setup() {
    const fetchData = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try {
        const response = await fetchBrands(search, page);
        brands.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      brands,
      isLoading,
      searchTerm
    };
  },
  mounted() {
    this.fetchData("", 1);
  },
};
</script>
