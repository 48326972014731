<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2"></div>

      <div class="cta">
        <el-button-group>
          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="row">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row ps-3">
            Name:
            {{
              records.patient.first_name +
              " " +
              records.patient.middle_name +
              " " +
              records.patient.last_name
            }}
          </div>
          <div class="d-flex flex-row ps-3">
            Patient No:{{ records.patient.patient_number }}
          </div>
          <div class="d-flex flex-row ps-3">
            Phone:{{ records.patient.phone }}
          </div>
        </div>
      </div>

      <div v-if="records.transactions === null">
        <empty-state-table />
      </div>
      <div class="table-responsive p-2">
        <table
          class="table align-items-center mb-0"
          v-if="records.transactions != null"
        >
          <thead>
            <tr>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Reference
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Item
              </th>
              <th
                class="p-2 text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount(KES)
              </th>
              <th
                class="p-2 text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Running Balance(KES)
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="transaction in records.transactions">
              <tr
                :class="row.prepayment ? 'prepayment' : ''"
                v-for="(row, index) in transaction"
                :key="index"
              >
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ dateTime(row.transaction_date) }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ row.reference_number }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ row.type }} {{ row.prepayment ? "(Pre-payment)" : "" }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-end text-secondary mb-0">
                    {{
                      row.type == "Receipt"
                        ? "(" + numberFormat(row.amount) + ")"
                        : numberFormat(row.amount)
                    }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-end text-secondary mb-0">
                    {{ numberFormat(calcBalance(row.amount, row.type)) }}
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import {
  fetchPatientStatement,
  downloadPatientStatementPdf,
} from "../api/services/reports";
import EmptyStateTable from "./components/EmptyTableState.vue";
import { ElMessage } from "element-plus";
const patient_id = ref(0);
const records = ref([]);
const isLoading = ref(false);
// const patientId = this.$route.params.id;

export default {
  name: "profile",
  components: {
    EmptyStateTable,
  },
  setup() {
    let balance = 0;
    const calcBalance = (value, type) => {
      if (type == "Receipt") {
        balance = balance - value;
        balance;
      } else {
        balance = balance + value;
      }
      console.log(balance);
      return balance;
    };

    const route = useRoute();

    const fetchData = async () => {
      try {
        const response = await fetchPatientStatement(route.params.id);
        records.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const numberFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    onMounted(() => {
      balance = 0;
      fetchData();
    });
    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadPatientStatementPdf(route.params.id)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "patient_statement_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    return {
      calcBalance,
      numberFormat,
      dateTime,
      fetchData,
      downloadPdf,
      records,
      patient_id,
    };
  },
};
</script>
<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.prepayment {
  background-color: #f2e3e3;
}
</style>
