<template>
  <div class="modal-overlay" @click="$emit('close-admin-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-lock text-primary me-2" />
        Add user to a clinic.
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="addUserToClinicFormRef"
        :model="addUserToClinicForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-form-item label="Clinic" prop="organization_id">
                <el-select-v2
                  v-model="addUserToClinicForm.organization_id"
                  filterable
                  :options="organizations"
                  placeholder="Please select a clinic"
                  @change="onOrganizationChange(val)"
                  style="width: 100%"
                />
              </el-form-item>
            </div>
            <div class="col-md-12 mb-3">
              <el-form-item label="Role" prop="role_id">
                <el-select-v2
                  v-model="addUserToClinicForm.role_id"
                  filterable
                  :options="roles"
                  placeholder="Please select a role"
                  style="width: 100%"
                  :disabled="isRoleDisabled"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs"
            @click="$emit('close-add-user-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(addUserToClinicFormRef)"
            >Submit</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close bg-danger" @click="$emit('close-add-user-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { addUserToClinic, fetchOrganizations } from "../../api/services/users";
import { fetchRoles } from "../../api/services/authentication";
import { ElNotification } from "element-plus";
const addUserToClinicFormRef = ref();
const isLoading = ref(false);
const organizations = ref([]);
const roles = ref([]);
const isRoleDisabled = ref(true);
export default {
  name: "add-user-to-clinic",
  props: {
    user_details: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const addUserToClinicForm = reactive({
      organization_id: "",
      role_id: "",
      user_id: props?.user_details?.id || 0,
    });
    const rules = reactive({
      organization_id: [
        {
          required: true,
          message: "Please select a clinic",
          trigger: ["blur", "change"],
        },
      ],

      role_id: [
        {
          required: true,
          message: "Please select a role",
          trigger: ["blur", "change"],
        },
      ],
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append(
            "organization_id",
            addUserToClinicForm.organization_id
          );
          formData.append("role_id", addUserToClinicForm.role_id);
          formData.append("user_id", addUserToClinicForm.user_id);
          addUserToClinic(formData)
            .then((data) => {
              isLoading.value = false;
              if (data.success) {
                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
                emit("close-add-user-modal");
              } else {
                Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) => {
              isLoading.value = false;
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              });
            })
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const onOrganizationChange = async () => {
      isRoleDisabled.value = false;
      loadRoles(addUserToClinicForm.organization_id);
    };
    const loadOrganizations = async () => {
      try {
        const response = await fetchOrganizations();

        const tempOrganizations = response.data.data.map((organization) => ({
          value: `${organization.id}`,
          label: `${organization.name + " " + organization.email}`,
        }));
        organizations.value = tempOrganizations;
      } catch (error) {
        console.log(error);
      }
    };
    const loadRoles = async (org_id) => {
      try {
        const entity_params = ref();
        entity_params.value = { organization_id: org_id };

        const response = await fetchRoles(entity_params);
        const tempRoles = response.data.data.map((role) => ({
          value: `${role.id}`,
          label: `${role.name}`,
        }));
        roles.value = tempRoles;
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      loadOrganizations();
    });
    return {
      onOrganizationChange,
      loadRoles,
      rules,
      loadOrganizations,
      isRoleDisabled,
      organizations,
      submitForm,
      addUserToClinicForm,
      addUserToClinicFormRef,
      isLoading,
      roles,
      props,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  max-width: 550px;
  max-height: 420px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
