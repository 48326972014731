<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="row">
          <div
            class="top-0 my-auto text-center col-8 d-lg-flex d-none pe-0 justify-content-center flex-column"
            style="height: 100vh"
          >
            <div
              class="position-relative bg-gradient-primary h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/sign-in-side-image.jpg') + ')',
                backgroundSize: 'cover',
              }"
            >
              <span class="mask bg-gradient-secondary opacity-4"></span>
              <h4 class="mt-5 text-white font-weight-bolder position-relative">
                "Here to Simplify Your Hospital’s Management, One Click at a
                Time"
              </h4>
              <p class="text-white position-relative">
                A hospital and clinic management software which enables you to
                run your core financial, clinical, and operational processes
              </p>
            </div>
          </div>
          <div
            class="col-xl-4 col-lg-4 col-md-4 d-flex flex-column mx-lg-0 my-auto align-items-end"
          >
            <div class="card card-plain">
              <div class="pb-0 card-header text-start">
                <h4 class="font-weight-bolder">Forgot Password</h4>
                <p class="mb-1">Enter your email to reset password</p>
              </div>
              <div class="card-body">
                <el-form
                  ref="forgotPassFormRef"
                  :model="resetPassForm"
                  :rules="rules"
                  label-width="auto"
                  class="demo-ruleForm"
                  size="large"
                  status-icon
                  :scroll-to-error="true"
                  label-position="top"
                  :hide-required-asterisk="true"
                >
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="resetPassForm.email" />
                  </el-form-item>

                  <el-button
                    type="success"
                    class="px-5 d-block my-4 mx-auto"
                    :loading="isLoading"
                    @click="submitForm(forgotPassFormRef)"
                  >
                    Send Link
                  </el-button>
                </el-form>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Back to
                    <router-link
                      to="/signin"
                      class="text-success text-gradient font-weight-bold"
                      >Sign In</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { reactive, ref } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { sendLink } from "../api/services/authentication";

const body = document.getElementsByTagName("body")[0];
const forgotPassFormRef = ref();
const isLoading = ref(false);

export default {
  setup() {
    const resetPassForm = reactive({
      email: "",
    });

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("email", resetPassForm.email);
          localStorage.setItem("reset_email", resetPassForm.email);

          sendLink(formData)
            .then((data) => {
              if (data.status === "success") {
                ElMessage({
                  message: data.message,
                  type: "success",
                });
              } else {
                let error = Object.values(data.errors);

                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      isLoading,
      submitForm,
      rules,
      resetPassForm,
      forgotPassFormRef,
    };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
