<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <div class="card">
          <div class="pb-0 card-header text-start">
            <h4 class="font-weight-bolder">Settings</h4>
            <p class="mb-0">Update your business configuration below</p>
          </div>
          <div class="card-body">
            <el-form
              ref="businessFormRef"
              :model="businessForm"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              size="large"
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
            >
              <div class="row">
                <div class="col-md-6">
                  <el-form-item label="Business Name" prop="business_name">
                    <el-input v-model="businessForm.business_name" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Business Email Address"
                    prop="business_email"
                  >
                    <el-input v-model="businessForm.business_email" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Business Phone No" prop="business_phone">
                    <el-input v-model="businessForm.business_phone" />
                  </el-form-item>
                </div>

                <div class="col-md-6 mb-3">
                  <el-form-item label="Clinic Type" prop="clinic_type">
                    <el-select
                      class="w-100"
                      placeholder="Select clinic type"
                      v-model="businessForm.clinic_type"
                    >
                      <el-option label="Optical" value="optical" />
                      <el-option label="Dental" value="dental" />
                      <el-option label="Maternity" value="maternity" />
                      <el-option label="Orthopaedic" value="orthopaedic" />
                    </el-select>
                  </el-form-item>
                </div>

                <div class="col-md-6">
                  <el-form-item
                    label="Business Address"
                    prop="business_address"
                  >
                    <editor
                      output-format="html"
                      initial-value=""
                      :init="tinymceoptions"
                      v-model="businessForm.business_address"
                      api-key="j27p1u6o81b96gghu5jnvc2nyu2jnu6ohoqf62hskyo70z9i"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Invoice Disclaimer"
                    prop="invoice_footer"
                  >
                    <editor
                      output-format="html"
                      initial-value=""
                      :init="tinymceoptions"
                      v-model="businessForm.invoice_footer"
                      api-key="j27p1u6o81b96gghu5jnvc2nyu2jnu6ohoqf62hskyo70z9i"
                    />
                  </el-form-item>
                </div>

                <hr class="my-3" />

                <h5 class="font-weight-bolder mb-23">Mpesa Configurations</h5>

                <div class="col-md-6">
                  <el-form-item label="Consumer Key" prop="consumer_key">
                    <el-input v-model="businessForm.consumer_key" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Consumer Secret" prop="consumer_secret">
                    <el-input v-model="businessForm.consumer_secret" />
                  </el-form-item>
                </div>

                <div class="col-md-6">
                  <el-form-item label="Pass Key" prop="pass_key">
                    <el-input v-model="businessForm.pass_key" />
                  </el-form-item>
                </div>

                <div class="col-md-6">
                  <el-form-item label="Short Code" prop="short_code">
                    <el-input v-model="businessForm.short_code" />
                  </el-form-item>
                </div>

                <div class="col-md-6 mb-3">
                  <el-form-item label="Short Code Type" prop="short_code_type">
                    <el-select
                      class="w-100"
                      placeholder="Select short code type"
                      v-model="businessForm.short_code_type"
                    >
                      <el-option label="Pay Bill" value="Pay Bill" />
                      <el-option label="Till Number" value="Buy Goods" />
                    </el-select>
                  </el-form-item>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <el-button
                      type="info"
                      class="px-5 d-block my-3"
                      :loading="isLoading"
                      @click="registerUrl()"
                    >
                      Register URL
                    </el-button>
                  </div>
                </div>

                <hr class="my-3" />

                <h5 class="font-weight-bolder mb-23">Accounting</h5>

                <div class="col-md-6">
                  <el-form-item
                    label="WithHolding Rate(%)"
                    prop="withholding_rate"
                  >
                    <el-input v-model="businessForm.withholding_rate" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="VAT Rate(%)" prop="vat_rate">
                    <el-input v-model="businessForm.vat_rate" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Invoice Print Layout"
                    prop="inv_print_layout"
                  >
                    <el-select
                      class="w-100"
                      placeholder="Choose Layout"
                      v-model="businessForm.inv_print_layout"
                    >
                      <el-option label="Portrait" value="portrait" />
                      <el-option label="Landscape" value="landscape" />
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Invoice Print Paper Size"
                    prop="inv_paper_size"
                  >
                    <el-select
                      class="w-100"
                      placeholder="Choose paper size"
                      v-model="businessForm.inv_paper_size"
                    >
                      <el-option label="A4" value="A4" />
                      <el-option label="A5" value="A5" />
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-checkbox
                    v-model="businessForm.auto_invoice_products"
                    label="Automatically add appointment services to invoices"
                    size="large"
                  />
                </div>
                <div class="col-md-6">
                  <el-checkbox
                    v-model="businessForm.auto_invoice_services"
                    label="Automatically add appointment services to invoices"
                    size="large"
                  />
                </div>
                <hr class="my-3" />
              </div>
              <div class="col-md-6">
                <el-form-item label="Business Logo" prop="business_logo">
                  <el-upload
                    ref="upload"
                    action="#"
                    :file-list="businessForm.files"
                    list-type="picture-card"
                    :auto-upload="false"
                    :limit="1"
                    :on-change="onChange"
                    :on-exceed="handleExceed"
                  >
                    <el-icon><Plus /></el-icon>

                    <template #file="{ file }">
                      <div>
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <el-icon><zoom-in /></el-icon>
                          </span>
                        </span>
                      </div>
                    </template>
                  </el-upload>
                </el-form-item>

                <div class="col-md-6">
                  <el-form-item label="Top Section Color" prop="navbar_bg">
                    <el-color-picker
                      v-model="businessForm.navbar_bg"
                      size="large"
                    />
                  </el-form-item>
                </div>
              </div>

              <el-button
                type="success"
                class="px-5 d-block my-3 mx-auto"
                :loading="isLoading"
                @click="submitForm(businessFormRef)"
              >
                Update Settings
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" draggable>
      <el-image
        alt="Preview Image"
        style="width: 500px"
        :src="dialogImageUrl"
        fit="fit"
      />
    </el-dialog>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import {
  fetchOrganization,
  updateOrganization,
  register_url,
} from "../api/services/users";
import { genFileId } from "element-plus";
import { Plus, ZoomIn } from "@element-plus/icons-vue";
import Editor from "@tinymce/tinymce-vue";
import { ElNotification } from "element-plus";

const upload = ref();

const businessFormRef = ref();
const isLoading = ref(false);
const phoneBindingProps = {
  mode: "international",
  styleClasses: "has-default",
  defaultCountry: "KE",
  onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
  inputOptions: {
    showDialCode: false,
    placeholder: "Mobile Number",
    name: "phone",
  },
};

const tinymceoptions = {
  height: 300,
  plugins:
    "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
  editimage_cors_hosts: ["picsum.photos"],
  menubar: "file edit view insert format tools table help",
  line_height_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
  forced_root_block: "div",
  elementpath: false,
  toolbar:
    "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify lineheight| outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample| ltr rtl",
  content_css: "default", //or "dark"
  content_style:
    "body {line-height: 1.4; font-family:Helvetica,Arial,sans-serif; font-size:16px }",
};
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
export default {
  components: {
    Plus,
    ZoomIn,
    Editor,
  },
  setup() {
    const org = JSON.parse(localStorage.getItem("organization"));
    const businessForm = reactive({
      business_name: "",
      business_phone: "",
      business_email: "",
      business_address: "",
      withholding_rate: "",
      vat_rate: "",
      inv_paper_size: "",
      inv_print_layout: "",
      navbar_bg: "409EFF",
      invoice_footer: "",
      consumer_secret: "",
      consumer_key: "",
      pass_key: "",
      short_code_type: "",
      short_code: "",
      clinic_type: "",
      auto_invoice_products: "",
      auto_invoice_services: "",
      logo_file: {},
      files: [],
    });
    const checkMobileNumber = (event) => {
      if (event.valid) {
        businessForm.business_phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        businessForm.business_phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };
    const rules = reactive({
      business_name: [
        {
          required: true,
          message: "Please input the business name",
          trigger: ["blur", "change"],
        },
      ],
      business_phone: [
        {
          required: true,
          message: "Please input your business",
          trigger: ["blur", "change"],
        },
      ],
      business_email: [
        {
          required: true,
          message: "Please input your business email",
          trigger: ["blur", "change"],
        },
      ],
      business_address: [
        {
          required: true,
          message: "Please input your business address",
          trigger: ["blur", "change"],
        },
      ],
    });
    const fetchBusiness = async () => {
      isLoading.value = true;
      try {
        const response = await fetchOrganization(org.id);

        //add the appointment if not part of loaded appointments
        if (response.data?.id != null) {
          businessForm.business_name = response.data.name;
          businessForm.business_phone = response.data.phone;
          businessForm.business_email = response.data.email;
          businessForm.withholding_rate = response.data.withholding_rate;
          businessForm.vat_rate = response.data.vat_rate;
          businessForm.inv_paper_size = response.data.inv_paper_size;
          businessForm.inv_print_layout = response.data.inv_print_layout;
          businessForm.business_address = response.data.address;
          businessForm.clinic_type = response.data.clinic_type;
          businessForm.navbar_bg = response.data.navbar_bg;
          businessForm.invoice_footer = response.data.invoice_footer;
          businessForm.short_code_type = response.data.type;
          businessForm.pass_key = response.data.pass_key;
          businessForm.consumer_secret = response.data.consumer_secret;
          businessForm.consumer_key = response.data.consumer_key;
          businessForm.short_code = response.data.shortcode;
          businessForm.auto_invoice_products =
            response.data.auto_invoice_products;
          businessForm.auto_invoice_services =
            response.data.auto_invoice_services;

          businessForm.files.push({
            name: response.data.logo.name,
            url: response.data.logo.path,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const submitForm = async (formEl) => {
      const org = JSON.parse(localStorage.getItem("organization"));
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          const formData = new FormData();

          formData.append("name", businessForm.business_name);
          formData.append("phone", businessForm.business_phone);
          formData.append("email", businessForm.business_email);
          formData.append("withholding_rate", businessForm.withholding_rate);
          formData.append("vat_rate", businessForm.vat_rate);
          formData.append("inv_paper_size", businessForm.inv_paper_size);
          formData.append("inv_print_layout", businessForm.inv_print_layout);
          formData.append("navbar_bg", businessForm.navbar_bg);
          formData.append("address", businessForm.business_address);
          formData.append("clinic_type", businessForm.clinic_type);
          formData.append("invoice_footer", businessForm.invoice_footer);
          formData.append("file", businessForm.logo_file);
          formData.append("type", businessForm.short_code_type);
          formData.append("pass_key", businessForm.pass_key);
          formData.append("consumer_secret", businessForm.consumer_secret);
          formData.append("consumer_key", businessForm.consumer_key);
          formData.append("shortcode", businessForm.short_code);
          formData.append(
            "auto_invoice_services",
            businessForm.auto_invoice_services ? 1 : 0
          );
          formData.append(
            "auto_invoice_products",
            businessForm.auto_invoice_products ? 1 : 0
          );

          updateOrganization(formData, org.id)
            .then((response) => {
              if (response.success) {
                localStorage.setItem(
                  "organization",
                  JSON.stringify(response.data)
                );
                ElNotification({
                  title: "Success",
                  message: response.message,
                  type: "success",
                });
              } else if (response.status == 422) {
                let error = Object.values(response.data.errors);

                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };
    onMounted(() => {
      fetchBusiness();
    });

    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url;
      dialogVisible.value = true;
    };
    const handleExceed = (files) => {
      upload.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      upload.value.handleStart(file);
    };
    const onChange = (file) => {
      businessForm.logo_file = file.raw;
    };
    const registerUrl = () => {
      isLoading.value = true;
      register_url(org.id)
        .then((response) => {
          if (response.success) {
            isLoading.value = false;
            ElNotification({
              title: "Success",
              message: response.message,
              type: "success",
            });
          } else if (response.status == 422) {
            isLoading.value = false;
            let error = Object.values(response.data.errors);

            error.forEach((err) =>
              ElNotification({
                title: "Error",
                message: err[0],
                type: "error",
              })
            );
          } else if (!response.success) {
            isLoading.value = false;
            ElNotification({
              title: "Error",
              message: response.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          isLoading.value = false;
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          });
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    return {
      registerUrl,
      onChange,
      upload,
      handleExceed,
      handlePictureCardPreview,
      dialogImageUrl,
      dialogVisible,
      isLoading,
      resetForm,
      submitForm,
      rules,
      businessForm,
      businessFormRef,
      phoneBindingProps,
      tinymceoptions,
      checkMobileNumber,
    };
  },
};
</script>
<style scoped>
.el-dialog {
  --el-dialog-width: 80% !important;
}
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
