<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fas fa-user-md text-primary text-md opacity-10"></i>
        <h6>Patients</h6>
      </div>

      <div class="cta">
        <el-button-group>
          <FilterTables
            :show_search_filter="true"
            :show_date_filter="true"
            date_range_label="Registration Date"
            search_label="Search patient records"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />

          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
        <button
          type="button"
          class="btn mb-0 btn-primary btn-xs ms-3 p-2"
          @click="newPatient"
          v-if="hasPermission('create.patient')"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
          Patient
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="patients.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="patients.data.length > 0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Patient Name
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Phone Number
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Patient No.
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Insurance
              </th>

              <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in patients.data" :key="item.id + index">
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <router-link
                        :to="{ path: `/patient/${item.id}` }"
                        class="text-secondary"
                        ><el-dropdown-item
                          v-if="hasPermission('view.patient')"
                          class="text-secondary font-weight-bold text-xs"
                        >
                          View</el-dropdown-item
                        ></router-link
                      >
                      <el-dropdown-item
                        v-if="hasPermission('update.patient')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="editPatient(item)"
                      >
                        Edit
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="hasPermission('create.appointment')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="addAppointment(item)"
                      >
                        Add Appointment
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="hasPermission('update.patient')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="showInsuranceDetails(item)"
                      >
                        Add Payer
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="
                          item.status !== 0 && hasPermission('delete.patient')
                        "
                        class="text-secondary font-weight-bold text-xs"
                        @click="disablePatient(item.id)"
                      >
                        Deactivate
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="
                          item.status === 0 && hasPermission('delete.patient')
                        "
                        class="text-secondary font-weight-bold text-xs"
                        @click="activatePatient(item.id)"
                      >
                        Activate
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="hasPermission('delete.patient')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="deletePatient(item.id)"
                      >
                        Delete
                      </el-dropdown-item>
                      <router-link
                        :to="{ path: `/patient/statement/${item.id}` }"
                        class="text-secondary"
                        ><el-dropdown-item
                          v-if="hasPermission('view.patient')"
                          class="text-secondary font-weight-bold text-xs"
                        >
                          View Statement</el-dropdown-item
                        ></router-link
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                          item.first_name + " " + item.last_name
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-center text-uppercase"
                  >
                    <h6 class="mb-0 text-sm">
                      {{
                        item.first_name +
                        " " +
                        item.middle_name +
                        " " +
                        item.last_name
                      }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ item.email }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.phone }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.patient_number }}
                </p>
              </td>
              <td class="text-start text-sm">
                <p class="text-xs font-weight-bold mb-0">
                  <span v-if="item.patient_insurances.length > 0">{{
                    item.patient_insurances[0]?.name
                  }}</span>
                  <span v-else>-</span>
                </p>
                <p
                  class="text-xs font-weight-bold mb-0"
                  v-if="item.patient_insurances.length == 1"
                >
                  <a
                    class="text-primary cursor-pointer"
                    @click="showInsurances(item.patient_insurances)"
                    >view
                  </a>
                </p>

                <p
                  class="text-xs text-primary cursor-pointer"
                  v-if="item.patient_insurances.length > 1"
                >
                  +{{ item.patient_insurances.length - 1 }} more
                  <a @click="showInsurances(item.patient_insurances)"
                    >view all</a
                  >
                </p>
              </td>

              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm"
                  :class="getStatusColor(item.status)"
                  >{{ item.status === 0 ? "Deactivated" : "Active" }}</span
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  dateTime(item.created_at)
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
  <PatientsForm
    v-if="showModal"
    @close-modal="showModal = false"
    :patient="patientDetails"
    @refresh-patients="refresh"
  />
  <PatientInsuranceFormModal
    v-if="showInsuranceModal"
    @close-insurance-modal="showInsuranceModal = false"
    @refresh-patients="refresh"
    :patient="modalData"
    :patient_insurance="patientInsuranceData"
  />

  <AppointmentForm
    v-if="showAppointmentModal"
    @close-appointment-modal="showAppointmentModal = false"
    :patient="patientDetails"
    :appointment="appointmentDetails"
    @refresh-appointments="refresh"
  />
  <InsuranceListModal
    v-if="showInsuranceListModal"
    @close-insurance-list-modal="showInsuranceListModal = false"
    @onSelect="onSelectHandler"
    :is_select="false"
    :insurances="availableInsurances"
    @onEdit="onEditHandler"
    @onRemove="onRemoveHandler"
  />
</template>

<script>
import Pagination from "./Pagination.vue";
import FilterTables from "./FilterTables.vue";
import PatientsForm from "../../components/Modals/PatientForm.vue";
import PatientInsuranceFormModal from "../../components/Modals/PatientInsuranceForm.vue";
import AppointmentForm from "../../components/Modals/AppointmentForm.vue";
import EmptyStateTable from "../components/EmptyTableState.vue";
import InsuranceListModal from "../../components/Modals/InsuranceList.vue";
import moment from "moment";
import { ref, h, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  changePatientStatus,
  downloadPatientsExcel,
  downloadPatientsPdf,
  removePatient,
  removeInsurance,
} from "../../api/services/users";

const showModal = ref(false);
const patientDetails = ref({});
const appointmentDetails = ref({});
const showInsuranceModal = ref(false);
const showAppointmentModal = ref(false);
const showInsuranceListModal = ref(false);
const modalData = ref({});
const patientInsuranceData = ref({});
const filter_params = ref({});
const availableInsurances = ref([]);
const isLoading = ref(false);

export default {
  name: "patients-table",
  props: {
    patients: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  components: {
    PatientsForm,
    PatientInsuranceFormModal,
    InsuranceListModal,
    AppointmentForm,
    Pagination,
    EmptyStateTable,
    FilterTables,
  },

  setup(props) {
    const total_records = props?.patients?.total || 0;
    const per_page = props?.patients?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.patients?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    const onEditHandler = (pi) => {
      showInsuranceListModal.value = false;
      showInsuranceModal.value = true;
      modalData.value = pi.patient;
      patientInsuranceData.value = pi;
    };
    const onRemoveHandler = async (pi) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "You are about to trash an insurance record. This action cannot be undone. Continue?"
        ),

        "Trash Insurance",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            showInsuranceListModal.value = false;
            isLoading.value = true;
            const { message, success } = await removeInsurance(pi.id);

            if (success) {
              props.refresh({}, 0);
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-warning";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const showInsuranceDetails = (user) => {
      showInsuranceModal.value = true;
      modalData.value = user;
    };
    const showInsurances = (insurances) => {
      showInsuranceListModal.value = true;
      availableInsurances.value = insurances;
    };
    const editPatient = (patient) => {
      patientDetails.value = patient;
      showModal.value = true;
    };

    const addAppointment = (patient) => {
      patientDetails.value = patient;
      showAppointmentModal.value = true;
    };

    const newPatient = () => {
      patientDetails.value = {};
      showModal.value = true;
    };

    const disablePatient = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The patient will be deactivated. Continue?"
        ),

        "Deactivate Patient",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await changePatientStatus(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const activatePatient = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The patient will be activated. Continue?"
        ),

        "Activate Patient",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await changePatientStatus(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const deletePatient = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The patient will be deleted. Continue?"
        ),

        "Delete Patient",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removePatient(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadPatientsPdf(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "patients_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const downloadExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadPatientsExcel(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "patients_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      filter_params,
      _filterCallBack,
      onPageChange,
      Pagination,
      dateTime,
      getStatusColor,
      getInitials,
      showModal,
      showInsuranceModal,
      showInsuranceListModal,
      showAppointmentModal,
      availableInsurances,
      modalData,
      patientDetails,
      appointmentDetails,
      showInsuranceDetails,
      patientInsuranceData,
      showInsurances,
      addAppointment,
      editPatient,
      newPatient,
      isLoading,
      activatePatient,
      disablePatient,
      deletePatient,
      onEditHandler,
      onRemoveHandler,
      downloadPdf,
      downloadExcel,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
</style>
