DoctorsActivity.vue<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="ni ni-calendar-grid-58 text-primary text-md opacity-10"></i>
        <h6>Doctor's Report</h6>
      </div>
      <div class="cta">
        <el-button-group>
          <FilterTables
            :show_date_filter="true"
            date_range_label="Select Date Range"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />

          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="tableData.length === 0">
        <empty-state-table />
      </div>

      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder"
              ></th>
              <th
                colspan="5"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Invoice Details
              </th>
              <th
                colspan="3"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Active Appointments
              </th>
              <th
                colspan="3"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Inactive appointments
              </th>
            </tr>
            <tr>
              <th>Action</th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Doctor's Name
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Count
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Total
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Paid
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Balance
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Out Patient
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Day Case
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                In Patient
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Out Patient
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Day Case
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                In Patient
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="item.id + index">
              <td class="ps-4 text-xs text-left">
                <router-link
                  :to="{
                    path: `/accounts/invoices/doctor/${item.id}`,
                  }"
                  class="text-secondary"
                >
                  <el-button type="default">
                    <i class="fas fa-list text-dark me-1" aria-hidden="true"></i
                    >View List
                  </el-button></router-link
                >
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.name }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.invoice_count }}
              </td>

              <td class="ps-4 text-xs text-left">
                {{ item.invoice_total }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.invoice_paid_total }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.balance }}
              </td>

              <td class="ps-4 text-xs text-left">
                {{ item.active?.out_patient ?? 0 }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.active?.day_case ?? 0 }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.active?.in_patient ?? 0 }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.inactive?.out_patient ?? 0 }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.inactive?.day_case ?? 0 }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ item.inactive?.in_patient ?? 0 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3"></div>
  </div>
</template>
<script>
import { ref } from "vue";
import Loader from "./components/Loader.vue";
import { ElMessage } from "element-plus";
import FilterTables from "./components/FilterTables.vue";
import {
  fetchDoctorsReport,
  downloadDoctorReportPdf,
  downloadDoctorReportExcel,
} from "../api/services/users";
const tableData = ref([]);
const isLoading = ref(false);
const filter_params = ref({});
export default {
  name: "doctors",
  components: {
    Loader,
    FilterTables,
  },

  setup() {
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      fetchData(filter_params, 1);
    };
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        tableData.value.splice(0);
        const response = await fetchDoctorsReport(entity_params, page);
        tableData.value = response.data?.map((item) => item);
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const downloadExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadDoctorReportExcel(filter_params, 1)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "doctor_summary_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadDoctorReportPdf(filter_params, 1)
          .then((Response) => {
            console.log(Response);
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "doctor_summary_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    return {
      filter_params,
      _filterCallBack,
      downloadExcel,
      downloadPdf,
      isLoading,
      fetchData,
      tableData,
    };
  },
  mounted() {
    this.fetchData(undefined, 1);
  },
};
</script>
<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

h6 {
  margin: 0;
}

.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
