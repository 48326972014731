<template>
  <div class="card">
    <div v-if="isLoading"><Loader /></div>
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-file-o text-primary text-md opacity-10"></i>
        <h6>payments</h6>
      </div>
      <div class="cta">
        <el-dropdown trigger="click" v-if="selected.length > 1">
          <span class="el-dropdown-link">
            <el-button class="me-2" size="large" circle>
              <i class="fa fa-ellipsis-v text-primary"></i>
            </el-button>
          </span>
          <template #dropdown>
            <el-dropdown-item
              class="text-danger font-weight-bold text-xs"
              v-if="hasPermission('delete.payment')"
              @click="ConfirmDeletePayments()"
            >
              Delete selected Payments
            </el-dropdown-item>
            <el-dropdown-item
              class="text-primary font-weight-bold text-xs"
              v-if="!hasPermission('update.payment_status')"
              @click="UpdateStatusSelectedPayments()"
            >
              Update Selected Payments Status
            </el-dropdown-item>
          </template>
        </el-dropdown>
        <el-button-group>
          <FilterTables
            :show_search_filter="true"
            :show_date_filter="true"
            :show_insurance_filter="true"
            :show_payment_mode_filter="true"
            date_range_label="Payment Date"
            search_label="Search payment records"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />

          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
        <button
          type="button"
          class="btn mb-0 btn-success btn-xs ms-3 p-2"
          @click="$router.push(`/accounts/payment/form`)"
          v-if="hasPermission('create.payment')"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
          Payment
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="payments.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="payments.data.length > 0">
        <table class="table align-items-left mb-0">
          <thead>
            <tr>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Action
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                <input type="checkbox" v-model="selectAll" />
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Reference
              </th>

              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Payment Date
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Payment Method
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Patient Name
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Invoice
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Allocated
              </th>

              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Paid
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Unallocated
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in payments.data">
              <tr
                :key="index"
                style="background-color: #f2e3e3"
                v-if="item.invoices.length == 0"
              >
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-if="hasPermission('view.payment')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="preview(item)"
                        >
                          Preview</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="
                            hasPermission('update.payment') &&
                            item.payment_status?.status == 'draft'
                          "
                        >
                          <router-link
                            :to="{ path: `/accounts/payment/form/${item.id}` }"
                            class="text-secondary font-weight-bold text-xs"
                            >Edit</router-link
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          class="text-default font-weight-bold text-xs"
                          v-if="
                            hasPermission('update.payment_status') &&
                            item.payment_status?.status != 'draft'
                          "
                          @click="update_status(item)"
                        >
                          Update Status</el-dropdown-item
                        >
                        <el-dropdown-item
                          class="text-danger font-weight-bold text-xs"
                          v-if="hasPermission('delete.payment')"
                          @click="ConfirmDeletePayment(item.id)"
                        >
                          Delete Payment
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
                <td>
                  <input
                    type="checkbox"
                    v-model="selected"
                    :value="item.id"
                    number
                  />
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ item.transaction_reference }}
                  </p>
                  <p
                    class="text-xs text-secondary mb-0"
                    v-if="item.payment_status != null"
                  >
                    <argon-badge
                      size="sm"
                      v-if="item.payment_status?.status == 'approved'"
                      color="success"
                      >{{ item.payment_status?.status }}</argon-badge
                    >
                    <argon-badge
                      size="sm"
                      v-if="item.payment_status?.status == 'draft'"
                      color="primary"
                      >{{ item.payment_status?.status }}</argon-badge
                    >
                    <argon-badge
                      size="sm"
                      v-if="item.payment_status?.status == 'cancelled'"
                      color="danger"
                      >{{ item.payment_status?.status }}</argon-badge
                    >
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ dateTime(item.transaction_date) }}
                  </p>
                </td>

                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ item.payment_mode?.name }}
                  </p>
                </td>

                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{
                      item?.patient?.first_name +
                      " " +
                      item?.patient?.middle_name +
                      " " +
                      item?.patient?.last_name
                    }}
                  </p>
                  <p class="text-xs text-secondary mb-0">
                    {{ item?.patient?.patient_number }}
                  </p>
                </td>
                <td>-</td>
                <td>
                  <p class="text-xs text-secondary mb-0">Pre-Payment</p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ numberFormat(item?.paid_amount) }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{
                      numberFormat(
                        item.paid_amount - item.invoice_payments_sum_amount
                      )
                    }}
                  </p>
                </td>
              </tr>

              <tr v-else v-for="(invoice, idx) in item.invoices" :key="idx">
                <td class="align-middle text-center">
                  <el-dropdown trigger="click" v-if="canShowActions(item)">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-if="hasPermission('view.payment')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="preview(item)"
                        >
                          Preview</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="hasPermission('update.payment')"
                        >
                          <router-link
                            :to="{ path: `/accounts/payment/form/${item.id}` }"
                            class="text-secondary font-weight-bold text-xs"
                            >Edit</router-link
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          v-if="!hasPermission('update.payment_status')"
                          @click="update_status(item)"
                        >
                          Update Status</el-dropdown-item
                        >
                        <el-dropdown-item
                          class="text-danger font-weight-bold text-xs"
                          v-if="hasPermission('delete.payment')"
                          @click="ConfirmDeletePayment(item.id)"
                        >
                          Delete Payment
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
                <td>
                  <input
                    v-if="canShowCheckbox(item)"
                    type="checkbox"
                    v-model="selected"
                    :value="item.id"
                    number
                  />
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ item.transaction_reference }}
                  </p>
                  <p
                    class="text-xs text-secondary mb-0"
                    v-if="item.payment_status != null"
                  >
                    <argon-badge
                      size="sm"
                      v-if="item.payment_status?.status == 'approved'"
                      color="success"
                      >{{ item.payment_status?.status }}</argon-badge
                    >
                    <argon-badge
                      size="sm"
                      v-if="item.payment_status?.status == 'draft'"
                      color="primary"
                      >{{ item.payment_status?.status }}</argon-badge
                    >
                    <argon-badge
                      size="sm"
                      v-if="item.payment_status?.status == 'cancelled'"
                      color="danger"
                      >{{ item.payment_status?.status }}</argon-badge
                    >
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ dateTime(item.transaction_date) }}
                  </p>
                </td>

                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ item.payment_mode.name }}
                    <br />
                    {{ invoice?.patient_insurance?.insurance?.name ?? "" }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{
                      invoice.patient?.first_name +
                      " " +
                      invoice.patient?.middle_name +
                      " " +
                      invoice.patient?.last_name
                    }}
                  </p>
                  <p class="text-xs text-secondary mb-0">
                    {{ invoice.patient?.patient_number }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ invoice.invoice_number }}
                  </p>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ numberFormat(invoice.allocated_amount) }}
                  </p>
                </td>
                <td>
                  <p
                    class="text-xs text-secondary mb-0"
                    v-if="idx + 1 == item.invoices.length"
                  >
                    {{ numberFormat(item.paid_amount) }}
                  </p>
                </td>
                <td>
                  <p
                    class="text-xs text-secondary mb-0"
                    v-if="idx + 1 == item.invoices.length"
                  >
                    {{
                      numberFormat(
                        item.paid_amount - item.invoice_payments_sum_amount
                      )
                    }}
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
  <PaymentStatusForm
    v-if="showPaymentStatusModal"
    @close-modal="showPaymentStatusModal = false"
    :payment="paymentDetails"
    :payments="selected"
    :multiple="isMultiple"
    @refresh-payments="refresh"
  />
  <PreviewPayment
    v-if="showModal"
    @close-modal="showModal = false"
    :payment="paymentDetails"
  />
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import moment from "moment";
import Pagination from "./Pagination.vue";
import FilterTables from "./FilterTables.vue";
import { h, ref, reactive, computed } from "vue";
import PreviewPayment from "../../components/Modals/PaymentPreview.vue";
import EmptyStateTable from "../components/EmptyTableState.vue";
import PaymentStatusForm from "../../components/Modals/PaymentStatusForm.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  downloadPaymentsExcel,
  downloadPaymentsPdf,
  deletePayment,
  deletePayments,
} from "../../api/services/accounts";
import Loader from "../components/Loader.vue";
const filter_params = ref({});
const isLoading = ref(false);
const paymentDetails = ref({});
const showPaymentStatusModal = ref(false);
const showModal = ref(false);
const selected = ref([]);
const isMultiple = ref(false);
let item_id = 0;
let checkbox_item_id = 0;
export default {
  name: "payments-table",
  props: {
    payments: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },

  components: {
    EmptyStateTable,
    FilterTables,
    Pagination,
    PaymentStatusForm,
    PreviewPayment,
    Loader,
    ArgonBadge,
  },
  setup(props) {
    const selectAll = computed({
      get() {
        if (props?.payments?.data && props?.payments?.data?.length > 0) {
          // A users array exists with at least one item
          let allChecked = true;

          for (const payment of props?.payments?.data) {
            if (!selected.value.includes(payment.id)) {
              allChecked = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];

        if (value) {
          props?.payments?.data?.forEach((payment) => {
            checked.push(payment.id);
          });
        }

        selected.value = checked;
      },
    });
    const total_records = props?.payments?.total || 0;
    const per_page = props?.payments?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.payments?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-warning";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const preview = (payment) => {
      console.log(payment);
      paymentDetails.value = payment;
      showModal.value = true;
    };

    const numberFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const canShowActions = (item) => {
      let show = false;
      if (item_id != item.id) {
        show = true;
      }
      item_id = item.id;
      return show;
    };
    const canShowCheckbox = (item) => {
      let showCheckbox = false;
      if (checkbox_item_id != item.id) {
        showCheckbox = true;
      }
      checkbox_item_id = item.id;
      return showCheckbox;
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadPaymentsPdf(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "payments_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const downloadExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadPaymentsExcel(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "payments_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const update_status = (invoice) => {
      isMultiple.value = false;
      paymentDetails.value = invoice;
      showPaymentStatusModal.value = true;
    };
    const UpdateStatusSelectedPayments = () => {
      isMultiple.value = true;
      showPaymentStatusModal.value = true;
    };
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const ConfirmDeletePayment = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "You are about to trash a payment. This action cannot be undone. Continue?"
        ),

        "TrashPpayment",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await deletePayment(id);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    const ConfirmDeletePayments = async () => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The selected payments will be trashed. This action cannot be undone. Continue?"
        ),

        "Trash payments",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;

            const formData = new FormData();
            formData.append("payment_ids", JSON.stringify(selected.value));
            const { message, success } = await deletePayments(formData);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    return {
      UpdateStatusSelectedPayments,
      ConfirmDeletePayment,
      ConfirmDeletePayments,
      isMultiple,
      selectAll,
      selected,
      isLoading,
      dateTime,
      getInitials,
      preview,
      numberFormat,
      canShowActions,
      canShowCheckbox,
      update_status,
      showPaymentStatusModal,
      showModal,
      paymentDetails,
      getStatusColor,
      filter_params,
      _filterCallBack,
      onPageChange,
      downloadPdf,
      downloadExcel,
      Pagination,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
</style>
