<template>
  <div class="card">
    <div class="card-header pb-0 ">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-cog text-primary text-md opacity-10"></i>
        <h6>Services</h6>
      </div>
      <div class="cta">
        <el-input
          v-model="Filter.searchquery"
          class="w-50 m-2"
          placeholder="Type something"
          clearable
        >
          <template #suffix>
            <span @click="searchFilter">
              <i
                class="py-2 ps-5 fas fa-search text-black me-1"
                aria-hidden="true"
              ></i>
            </span>
          </template>
        </el-input>
        <button
          class="btn mb-0 btn-success btn-xs ms-auto"
          @click="newService"
          v-if="hasPermission('create.service')"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
          Service
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2" v-if="services.data.length > 0">
      <div v-if="services.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Service Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Price
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Description
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in services.data" :key="item.id + index">
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('update.service')"
                        @click="editService(item)"
                      >
                        Edit
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('update.service')"
                        @click="editService(item)"
                      >
                        <router-link
                          :to="{
                            path: `/settings/services/consumables/${item.id}`,
                          }"
                          class="text-secondary font-weight-bold text-xs"
                          >Manage Consumables</router-link
                        >
                      </el-dropdown-item>

                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('delete.service')"
                        @click="deleteService(item.id)"
                      >
                        Delete
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
              <td>
                <h6 class="mb-0 text-sm text-capitalize ps-3">
                  {{ item.item }}
                </h6>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.price }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.description }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm"
                  :class="getStatusColor(item.status)"
                  >{{ item.status === 0 ? "Deactivated" : "Active" }}</span
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  dateTime(item.created_at)
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
  <ServicesForm
    v-if="showModal"
    @close-modal="showModal = false"
    :service="serviceDetails"
    @refresh-services="refresh"
  />
</template>

<script>
import ServicesForm from "../../components/Modals/ServiceForm.vue";
import moment from "moment";
import { ref, h, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { removeService } from "../../api/services/services";
import Pagination from "./Pagination.vue";
import EmptyStateTable from "../components/EmptyTableState.vue";

const showModal = ref(false);
const serviceDetails = ref({});
const isLoading = ref(false);

export default {
  name: "services-table",
  props: {
    services: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchList: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    ServicesForm,
    Pagination,
    EmptyStateTable,
  },

  setup(props) {
    const total_records = props?.services?.total || 0;
    const per_page = props?.services?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.services?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      props.refresh(Filter.searchquery, page);
    };
    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

    const searchFilter = () => {
      props.refresh(Filter.searchquery, 1);
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-warning";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const editService = (insurance) => {
      serviceDetails.value = insurance;
      showModal.value = true;
    };
    const newService = () => {
      serviceDetails.value = {};
      showModal.value = true;
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const deleteService = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "This service will permanently delete. Continue?"
        ),

        "Delete Service",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeService(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    return {
      onPageChange,
      Pagination,
      searchFilter,
      Filter,
      dateTime,
      getStatusColor,
      getInitials,
      editService,
      newService,
      serviceDetails,
      showModal,
      isLoading,
      deleteService,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

h6 {
  margin: 0;
}

.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
