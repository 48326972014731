import { api } from "../..";

export const getUserDetails = async () => {
  let response = await api
    .get("auth/user")
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchOrganizations = async () => {
  let response = await api
    .get("user/organizations")
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const register_url = async (bid) => {
  let response = await api
    .patch("register-url/" + bid)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const addUserToClinic = async (postData) => {
  let response = await api
    .post(`organization/users/add`, postData)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const changeUserRole = async (postData) => {
  let response = await api
    .post(`organization/users/update/role`, postData)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadPatientsExcel = async () => {
  let response = await api
    .get(`patients/download/excel`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const downloadPatientsPdf = async () => {
  let response = await api
    .get(`patients/download/pdf`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const fetchUnPaginatedPatients = async () => {
  let response = await api
      .get("un-paginated-patients?orderBy=id&sortedBy=desc")
      .then((response) => response.data)
      .catch((e) => e.response.data);
  return response;
};

export const fetchPatients = async (filter, page) => {
  var url = "patients?orderBy=id&sortedBy=desc";
  if (filter.value != undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=first_name:like;last_name:like;middle_name:like;phone:like;email:like;patient_number:like;&search=" +
      filter.value.search_query;
  if (filter.value != undefined && filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;

  if (page) url = url + "&page=" + page;
  url = url.replace("patients?&", "patients?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchCheckIns = async () => {
  let response = await api
    .get("checkins")
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchPatientDetails = async (id) => {
  let response = await api
    .get(`patients/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createPatient = async (postBody) => {
  let response = await api
    .post("patients", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updatePatient = async (body) => {
  let response = await api
    .patch("patients/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const changePatientStatus = async (id) => {
  let response = await api
    .post(`deactivate/${id}/patient`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const removePatient = async (id) => {
  let response = await api
    .delete(`patients/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeInsurance = async (id) => {
  let response = await api
    .delete(`patient_insurances/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchUsers = async (filter, page) => {
  var url = "users?orderBy=id&sortedBy=desc";
  if (filter.value != undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=first_name:like;last_name:like;middle_name:like;phone:like;email:like;&search=" +
      filter.value.search_query;

  if (filter.value != undefined && filter.value.role)
    url = url + "&role=" + filter.value.role;

  if (page) url = url + "&page=" + page;

  url = url.replace("users?&", "users?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const listPractitioners = async () => {
  let response = await api
    .get("list-practitioners?orderBy=id&sortedBy=desc")
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const createUser = async (postBody) => {
  let response = await api
    .post("users", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateUser =  async (body) => {
  let response = await api
    .patch("users/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const removeUser = async (id) => {
  let response = await api
    .delete(`organization/users/remove/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const changeUserStatus = async (id) => {
  let response = await api
    .put(`users/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateProfile = async (postBody, id) => {
  let response = await api
    .post(`update/user/${id}`, postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchOrganization = async (id) => {
  let response = await api
    .get(`organizations/show/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const updateOrganization = async (postBody, id) => {
  const headers = { "Content-Type": "multipart/form-data" };
  let response = await api
    .post(`organizations/update/${id}`, postBody, { headers })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchDoctorsReport = async (filter, page) => {
  var url = "doctors/report?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("report?&", "report?");

  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};
export const downloadDoctorReportPdf = async (filter, page) => {
  var url = "doctors/report/pdf?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("report?&", "report?");
  let response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadDoctorReportExcel = async (filter, page) => {
  var url = "doctors/report/excel?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("report?&", "report?");
  let response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
