<template>
  <div class="modal-overlay">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-invoice-o text-primary me-2" />Initiate Payment
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="stkPushFormRef"
        :model="stkPushForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="col-md-12 mb-3">
            <el-form-item label="Patient Name" prop="patient">
              <el-input v-model="stkPushForm.patient" />
            </el-form-item>
          </div>

          <div class="col-md-12 mb-3">
            <el-form-item label="Invoice Number" prop="invoice_number">
              <el-input v-model="stkPushForm.invoice_number" />
            </el-form-item>
          </div>

          <!--          <div class="col-md-12 mb-3">-->
          <!--            <el-form-item label="Appointment Number" prop="appointment_number">-->
          <!--              <el-input v-model="stkPushForm.appointment_number" />-->
          <!--            </el-form-item>-->
          <!--          </div>-->

          <div class="col-md-12 mb-3">
            <label class="el-form-item__label">Mobile Number</label>
            <vue-tel-input
              v-model="stkPushForm.phone"
              :value="stkPushForm.phone"
              @validate="checkMobileNumber"
              v-bind="phoneBindingProps"
            ></vue-tel-input>
          </div>

          <div class="col-md-12 mb-3">
            <el-form-item label="Amount" prop="amount">
              <el-input v-model="stkPushForm.amount" />
            </el-form-item>
          </div>

          <div class="mb-3">
            <el-form-item label="Comment" prop="comment">
              <el-input v-model="stkPushForm.comment" type="textarea" />
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-5 card--footer">
            <button
              class="btn mb-0 btn-danger btn-xs"
              @click="$emit('close-modal')"
            >
              Cancel
            </button>
            <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(stkPushFormRef)"
              >Submit</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { initiatePayment } from "../../api/services/accounts";
import { ElNotification } from "element-plus";

const stkPushFormRef = ref();
const isLoading = ref(false);
export default {
  name: "update invoice status",
  props: {
    invoice: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const stkPushForm = reactive({
      invoice_id: props?.invoice?.id || 0,
      patient: props?.invoice?.patient.name || "",
      phone: props?.invoice?.patient.phone || "",
      invoice_number: props?.invoice?.invoice_number || "",
      amount: props?.invoice?.invoice_payments_sum_amount || "",
      comment: "",
    });

    const rules = reactive({
      status: [
        {
          required: true,
          message: "Please select status",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        {
          required: true,
          message: "Please input phone number",
          trigger: ["blur", "change"],
        },
      ],
      amount: [
        {
          required: true,
          message: "Please input amount",
          trigger: ["blur", "change"],
        },
      ],
      comment: [
        {
          required: true,
          message: "Please input a comment",
          trigger: ["blur", "change"],
        },
      ],
    });

    const phoneBindingProps = {
      mode: "international",
      styleClasses: "has-default",
      defaultCountry: "KE",
      onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
      inputOptions: {
        showDialCode: false,
        placeholder: "Mobile Number",
        name: "phone",
      },
    };

    const checkMobileNumber = (event) => {
      if (event.valid) {
        stkPushForm.phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        stkPushForm.phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("invoice_id", stkPushForm.invoice_id);
          formData.append("phone", stkPushForm.phone);
          formData.append("invoice_number", stkPushForm.invoice_number);
          formData.append("amount", stkPushForm.amount);
          formData.append("transaction_description", stkPushForm.comment);

          initiatePayment(formData)
            .then((data) => {
              if (data.success) {
                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
                emit("close-modal");
              } else {
                console.log(data);
                ElNotification({
                  title: "Error",
                  message: data.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              isLoading.value = false;
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              });
            })
            .finally(() => {
              isLoading.value = false;
            });
        }
      });
    };
    return {
      submitForm,
      rules,
      stkPushForm,
      checkMobileNumber,
      phoneBindingProps,
      stkPushFormRef,
      isLoading,
      props,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  max-height: 100%;
  overflow-y: auto;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 450px;
  height: 600px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
