<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-menu-item index="1">
        <i class="fa fa-th text-primary text-sm opacity-10 me-2"></i>
        <router-link to="/dashboard" class="text-sm">Dashboard</router-link>
      </el-menu-item>
      <el-sub-menu index="2" v-if="hasPermission('manage.patients')">
        <template
          #title
          v-if="
            hasPermission('manage.patient') ||
            hasPermission('manage.appointment')
          "
        >
          <i class="fa fa-user-md text-primary text-sm opacity-10 me-2"></i>
          <span>Patients</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/patients" class="text-sm"
            ><el-menu-item index="2-1" v-if="hasPermission('manage.patient')">
              Register</el-menu-item
            ></router-link
          >
          <router-link to="/appointments"
            ><el-menu-item
              index="2-2"
              v-if="hasPermission('manage.appointment')"
            >
              Visits</el-menu-item
            ></router-link
          >
        </el-menu-item-group>
      </el-sub-menu>
      <el-sub-menu index="3" v-if="hasPermission('manage.settings')">
        <template
          #title
          v-if="
            hasPermission('manage.service') ||
            hasPermission('manage.hospital') ||
            hasPermission('manage.insurance') ||
            hasPermission('manage.employer') ||
            hasPermission('manage.allergy')
          "
        >
          <i class="fa fa-cog text-sm opacity-10 text-primary me-2"></i>
          <span>Settings</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/settings/services"
            ><el-menu-item index="3-1" v-if="hasPermission('manage.service')">
              Services</el-menu-item
            ></router-link
          >
          <router-link to="/settings/hospitals"
            ><el-menu-item index="3-2" v-if="hasPermission('manage.hospital')">
              Hospitals</el-menu-item
            ></router-link
          >
          <router-link to="/settings/insurances"
            ><el-menu-item index="3-3" v-if="hasPermission('manage.insurance')">
              Insurance</el-menu-item
            ></router-link
          >
          <router-link to="/settings/employers"
            ><el-menu-item index="3-4" v-if="hasPermission('manage.employer')">
              Employers
            </el-menu-item></router-link
          >
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu
        index="4"
        v-if="
          hasPermission('manage.settings') &&
          (hasPermission('manage.category') ||
            hasPermission('manage.brand') ||
            hasPermission('manage.product') ||
            hasPermission('manage.unit'))
        "
      >
        <template #title>
          <i class="fa fa-cubes text-sm opacity-10 text-primary me-2"></i>
          <span>Inventory</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/inventory/categories"
            ><el-menu-item index="4-1" v-if="hasPermission('manage.category')">
              Categories
            </el-menu-item></router-link
          >
          <router-link to="/inventory/brands"
            ><el-menu-item index="4-2" v-if="hasPermission('manage.brand')">
              Brands
            </el-menu-item></router-link
          >
          <router-link to="/inventory/units"
            ><el-menu-item index="4-3" v-if="hasPermission('manage.unit')">
              Unit of Measure
            </el-menu-item></router-link
          >
          <router-link to="/inventory/products"
            ><el-menu-item index="4-4" v-if="hasPermission('manage.product')">
              Products
            </el-menu-item></router-link
          >
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu index="5" v-if="hasPermission('manage.accounts')">
        <template
          #title
          v-if="
            hasPermission('manage.invoice') ||
            hasPermission('add.invoices') ||
            hasPermission('manage.payment') ||
            hasPermission('add.payments')
          "
        >
          <i class="fa fa-copy text-sm opacity-10 text-primary me-2"></i>
          <span>Accounts</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/accounts/invoices"
            ><el-menu-item index="5-1" v-if="hasPermission('manage.invoice')">
              Invoices</el-menu-item
            ></router-link
          >
          <router-link to="/accounts/invoice/form"
            ><el-menu-item index="5-2" v-if="hasPermission('create.invoice')"
              >Add Invoice</el-menu-item
            ></router-link
          >
          <router-link to="/accounts/payments"
            ><el-menu-item index="5-3" v-if="hasPermission('manage.payment')">
              Payments</el-menu-item
            ></router-link
          >
          <router-link to="/accounts/payment/form"
            ><el-menu-item index="5-4" v-if="hasPermission('create.payment')"
              >Receive Payment</el-menu-item
            ></router-link
          >
        </el-menu-item-group>
      </el-sub-menu>
      <el-sub-menu index="6" v-if="hasPermission('manage.reports')">
        <template #title>
          <i class="fa fa-users text-sm opacity-10 text-primary me-2"></i>
          <span>Reports</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/reports/consumables"
            ><el-menu-item index="6-1" v-if="!hasPermission('view.report')"
              >Consumables Report</el-menu-item
            ></router-link
          >
          <router-link to="/reports/doctors/activity"
            ><el-menu-item index="6-2" v-if="!hasPermission('view.report')">
              Doctor's Report
            </el-menu-item></router-link
          >
          <router-link to="/reports/services/summary"
          ><el-menu-item index="6-2" v-if="!hasPermission('view.report')">
            Services Report
          </el-menu-item></router-link
          >
          <router-link to="/reports/insurances/summary/"
            ><el-menu-item index="6-3" v-if="!hasPermission('view.report')">
              Insurance Statement
            </el-menu-item></router-link
          >
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu index="7" v-if="hasPermission('manage.users')">
        <template
          #title
          v-if="hasPermission('manage.roles') || hasPermission('manage.users')"
        >
          <i class="fa fa-users text-sm opacity-10 text-primary me-2"></i>
          <span>Users</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/roles"
            ><el-menu-item index="7-1" v-if="hasPermission('manage.roles')"
              >Roles
            </el-menu-item></router-link
          >
          <router-link to="/users"
            ><el-menu-item index="7-2" v-if="hasPermission('manage.users')">
              Users
            </el-menu-item></router-link
          >
        </el-menu-item-group>
      </el-sub-menu>

      <el-sub-menu index="8">
        <template #title>
          <i
            class="fa fa-address-book text-sm opacity-10 text-primary me-2"
          ></i>
          <span>Human Resource</span>
        </template>

        <router-link to="/hr/referrals"
          ><el-menu-item index="8-1" v-if="hasPermission('manage.employer')">
            Referrals
          </el-menu-item></router-link
        >

        <router-link to="/hr/leaves"
          ><el-menu-item index="8-2" v-if="hasPermission('manage.employer')">
            Leaves
          </el-menu-item></router-link
        >

        <router-link to="/hr/templates">
          <el-menu-item index="8-3" v-if="hasPermission('manage.employer')">
            Templates
          </el-menu-item></router-link
        >
      </el-sub-menu>

      <hr class="my-3" />
      <el-menu-item index="2" v-if="hasPermission('manage.business_setting')">
        <i class="fa fa-cog text-primary text-sm opacity-10 me-2"></i>
        <router-link to="/settings" class="text-sm"
          >Business Settings</router-link
        >
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));

    return {
      title: "Clinical",
      controls: "dashboardsMain",
      isActive: "active",
      userPermissions: storedPermissions || [],
    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>
<style lang="scss" scoped>
$color-emperor: #4f4f4f;
$color-mine-shaft: #333333;
$color-bondi-blue: #344767;
$left-spacing: 20px;
.collapsible-menu {
  font-size: 14px;
  padding: 0;
  margin: 0;
  overflow: hidden;

  .check {
    position: absolute;
    user-select: none;
    pointer-events: none;
    opacity: 0;

    &:checked {
      ~ ul {
        opacity: 1;
        max-height: 9999em;
        pointer-events: all;
        transition: opacity 0.15s ease-in-out;

        > li {
          margin: 12px 0;
        }
      }

      + label {
        > .icon-wrapper {
          > .icon-angle {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  ul,
  li {
    list-style: none;
  }

  ul {
    opacity: 0;
    max-height: 0;
    pointer-events: none;
    padding: 0;
    overflow: hidden;

    li {
      margin: 0;
      transition: 0.15s ease-out;
      padding-left: $left-spacing;
    }
  }

  // collapsible menu
  label {
    font-weight: 600;
    color: #344767;
    user-select: none;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    margin-bottom: 0;
    width: 100%;
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1rem;

      .icon-angle {
        transform-origin: center;
        transition: 0.15s ease-in-out;
      }
    }
  }

  > li {
    &.active {
      overflow: hidden;
      box-shadow: none;
      color: #344767;

      > label {
        background-color: #f6f9fc;
        border-radius: 0.5rem;
      }
    }

    &:not(.active) {
      .check {
        &:checked {
          ~ ul {
            margin-top: -8px;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

a {
  padding-left: 0 !important;
}
</style>
