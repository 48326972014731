<template>
  <div class="py-1 container-fluid">
    <el-form
      class="el-form-item--label-top"
      ref="templateFormRef"
      :model="templateForm"
      :rules="rules"
      status-icon
      :scroll-to-error="true"
      label-position="left"
      :hide-required-asterisk="true"
    >
      <div class="card mt-5">
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">
                    {{
                      templateForm?.id != 0
                        ? "Update Template"
                        : "Create Template"
                    }}
                  </h6>
                  <div class="row">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <label prop="type" class="el-form-item__label"
                            >Type</label
                          >
                          <el-form-item>
                            <el-select
                              class="w-100"
                              placeholder="Select template type"
                              v-model="templateForm.type"
                            >
                              <el-option label="Referral" value="referral" />
                              <el-option label="Leave" value="leave" />
                            </el-select>
                          </el-form-item>
                        </div>

                        <div class="col-md-12 mb-3">
                          <label prop="template" class="el-form-item__label"
                            >Template</label
                          >
                          <el-form-item>
                            <editor
                              output-format="html"
                              initial-value=""
                              :init="tinymceoptions"
                              v-model="templateForm.template"
                              api-key="j27p1u6o81b96gghu5jnvc2nyu2jnu6ohoqf62hskyo70z9i"
                            />
                          </el-form-item>
                        </div>

                        <div class="col-md-12 p-3 text-center">
                          <el-button
                            type="success"
                            class="btn mb-0 btn-success btn-xs null"
                            :loading="isLoading"
                            @click="submitForm(templateFormRef)"
                          >
                            Save
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">Available Tags</h6>
                  <div class="row">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <el-descriptions
                            title="Referral Tags"
                            :column="3"
                            border
                          >
                            <el-descriptions-item
                              label="Tags"
                              label-align="right"
                              align="center"
                            >
                              <div class="d-flex gap-1">
                                <el-tag size="small">{ patient_name }</el-tag>
                                <el-tag size="small">{ referred_by }</el-tag>
                                <el-tag size="small">{ hospital_name }</el-tag>
                                <el-tag size="small">{ reason }</el-tag>
                                <el-tag size="small">{ date }</el-tag>
                              </div>
                            </el-descriptions-item>
                          </el-descriptions>
                          <el-descriptions
                            class="mt-5"
                            title="Leave Tags"
                            :column="3"
                            border
                          >
                            <el-descriptions-item
                              label="Tags"
                              label-align="right"
                              align="center"
                            >
                              <div class="d-flex gap-2">
                                <el-tag size="small">{ patient_name }</el-tag>
                                <el-tag size="small">{ issued_by }</el-tag>
                                <el-tag size="small">{ start_date }</el-tag>
                                <el-tag size="small">{ end_date }</el-tag>
                                <el-tag size="small">{ reason }</el-tag>
                              </div>
                            </el-descriptions-item>
                          </el-descriptions>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { ElNotification } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import {
  createTemplate,
  fetchTemplate,
  updateTemplate,
} from "../../api/services/templates";
import Editor from "@tinymce/tinymce-vue";

const templateFormRef = ref();
const isLoading = ref(false);
export default {
  name: "template-form",
  components: { Editor },

  props: {
    template: {
      type: Object,
      required: false,
    },
    image_type: {
      type: String,
      required: false,
      default: "png",
    },
  },

  setup(props) {
    const tinymceoptions = {
      height: 300,
      plugins:
        "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
      editimage_cors_hosts: ["picsum.photos"],
      menubar: "file edit view insert format tools table help",
      line_height_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
      forced_root_block: "div",
      elementpath: false,
      toolbar:
        "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify lineheight| outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample| ltr rtl",
      content_css: "default", //or "dark"
      content_style:
        "body {line-height: 1.4; font-family:Helvetica,Arial,sans-serif; font-size:16px }",
    };

    const route = useRoute();
    const router = useRouter();
    const template_id = route.params?.id;

    const templateForm = reactive({
      id: template_id || 0,
      type: "",
      template: "",
    });

    const fetchTemplateData = async () => {
      if (template_id) {
        try {
          const response = await fetchTemplate(template_id);
          templateForm.type = response.data.type;
          templateForm.template = response.data.template;
        } catch (error) {
          console.log(error);
        }
      }
    };

    const rules = reactive({
      template: [
        {
          required: true,
          message: "Please type your template",
          trigger: ["blur", "change"],
        },
      ],
      type: [
        {
          required: true,
          message: "Please select template type",
          trigger: ["blur", "change"],
        },
      ],
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;

      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("type", templateForm.type);
          formData.append("template", templateForm.template);

          if (templateForm.id > 0) {
            formData.append("_METHOD", 'PUT');
            updateTemplate(formData, templateForm?.id)
              .then(function (data) {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  router.push("/hr/templates");
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createTemplate(formData)
              .then(function (data) {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  router.push("/hr/templates");
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        duration: 4500,
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      duration: 4500,
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    onMounted(() => {
      fetchTemplateData();
    });

    return {
      templateForm,
      submitForm,
      rules,
      templateFormRef,
      isLoading,
      props,
      tinymceoptions,
    };
  },
};
</script>
<style scoped>
.el-form-item--label-top .el-form-item__label {
  width: 100% !important;
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 10px;
}
.el-form-item--label-top .el-form-item__content {
  margin-left: 0 !important;
}

.el-input-number .el-input__inner {
  text-align: right !important;
}

.card {
  outline: 0;
  border-radius: 7px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card-body {
  max-height: 80%;
  overflow-x: hidden;
  overflow-y: auto;
}

input {
  border-radius: 6px;
}

:deep(.my-label) {
  background: var(--el-color-success-light-9) !important;
}
:deep(.my-content) {
  background: var(--el-color-danger-light-9);
}
</style>
