<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <referral-table
            :referrals="referrals"
            :filter="filter"
            :refresh="fetchData"
            v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReferralTable from "./components/ReferralTable.vue";
import Loader from "./components/Loader.vue";
import { fetchReferrals } from "../api/services/referral";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const isLoading = ref(false);
const referrals = ref([]);
export default {
  name: "referrals",
  components: {
    ReferralTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      //console.log(filter);
      try {
        const response = await fetchReferrals(filter, page);
        referrals.value = response.data;
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      const route = useRoute();
      const entity_params = ref();
      entity_params.value = {
        insurance_id: route.params?.insurance_id,
      };
      fetchData(entity_params, 1);
    });
    return {
      fetchData,
      referrals,
      isLoading,
    };
  },
};
</script>
