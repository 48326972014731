<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center">
        <p class="mb-0">Change Password</p>
      </div>
    </div>
    <div class="card-body">
      <el-form
        ref="changePasswordFormRef"
        :model="changePasswordForm"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <el-form-item label="Current Password" prop="current_password">
          <el-input
            v-model="changePasswordForm.current_password"
            type="password"
            :show-password="true"
          />
        </el-form-item>
        <el-form-item label="New Password" prop="new_password">
          <el-input
            v-model="changePasswordForm.new_password"
            type="password"
            :show-password="true"
          />
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirm_password">
          <el-input
            v-model="changePasswordForm.confirm_password"
            type="password"
            :show-password="true"
          />
        </el-form-item>

        <el-button
          type="success"
          class="px-5 d-block mt-4 mx-auto"
          :loading="isLoading"
          @click="submitForm(changePasswordFormRef)"
        >
          Change Password
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { ElNotification } from "element-plus";
import { changePassword } from "../../api/services/authentication";
import { useRouter } from "vue-router";

const changePasswordFormRef = ref();
const isLoading = ref(false);
export default {
  name: "profile-card",

  setup() {
    const router = useRouter();
    const changePasswordForm = reactive({
      current_password: "",
      new_password: "",
      confirm_password: "",
    });

    const rules = reactive({
      current_password: [
        {
          required: true,
          message: "Please input your current password",
          trigger: ["blur", "change"],
        },
      ],
      new_password: [
        {
          required: true,
          message: "Please input your new password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
      confirm_password: [
        {
          required: true,
          message: "Please confirm your password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append(
            "current_password",
            changePasswordForm.current_password
          );
          formData.append("password", changePasswordForm.new_password);
          formData.append(
            "password_confirmation",
            changePasswordForm.confirm_password
          );

          changePassword(formData)
            .then((data) => {
              if (data.success) {
                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
                localStorage.clear();
                router.replace({ path: "/signin" });
              } else {
                if (data.errors) {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                } else {
                  ElNotification({
                    title: "Error",
                    message: data.message,
                    type: "error",
                  });
                }
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return {
      isLoading,
      resetForm,
      submitForm,
      rules,
      changePasswordForm,
      changePasswordFormRef,
    };
  },
};
</script>
