<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header pb-0 mb-5">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-shield text-primary text-md opacity-10"></i>
          <h6>Roles</h6>
        </div>
        <div class="cta"></div>
      </div>

      <div class="card-body pb-2">
        <el-form
          ref="RolePermissionFormRef"
          :model="roleForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="mb-3">
              <el-form-item label="Name" prop="name">
                <el-input v-model="roleForm.name" />
              </el-form-item>
            </div>

            <div class="mb-3">
              <el-form-item label="Description" prop="description">
                <el-input v-model="roleForm.description" type="textarea" />
              </el-form-item>
            </div>
          </div>

          <div class="table-responsive p-0">
            <h6>Permissions</h6>
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Module
                  </th>
                  <th
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    v-for="action in actions"
                    :key="action"
                  >
                    {{ capitalizedText(action) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(permission, _module) in permissions" :key="_module">
                  <td>
                    {{ capitalizedText(_module) }}
                  </td>
                  <td v-for="action in actions" :key="action">
                    <el-checkbox
                      v-if="permission[action]"
                      v-model="selected_permissions[permission[action]]"
                      :checked="
                        current_permissions.includes(permission[action])
                      "
                      size="large"
                    />
                    <i v-else class="fa fa-ban"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-form>
      </div>
      <div class="card-footer p-5">
        <div class="d-flex flex-row-reverse">
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(RolePermissionFormRef)"
            >Save</el-button
          >
          <el-button type="danger" class="me-2"> Cancel </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import {
  fetchPermissions,
  updateRole,
  createRole,
  fetchRole,
} from "../api/services/authentication";
import { ElNotification } from "element-plus";
import { useRoute, useRouter } from "vue-router";
const RolePermissionFormRef = ref();
const isLoading = ref(false);
const permissions = ref([]);
const selected_permissions = ref([]);
const current_permissions = ref([]);

const actions = ["manage", "view", "create", "update", "delete"];
export default {
  name: "Roles Form",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const role_id = route.params?.id;
    const roleForm = reactive({
      id: role_id || 0,
      name: "",
      description: "",
      permissions: [],
    });

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Please input the role name",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please input a description of what this role is about",
          trigger: ["blur", "change"],
        },
      ],
    });
    const getRole = async () => {
      if (roleForm.id > 0) {
        selected_permissions.value.splice(0);
        try {
          const response = await fetchRole(roleForm.id);
          roleForm.name = response.data.name;
          roleForm.description = response.data.description;
          response.data.permissions.map((permission) => {
            selected_permissions.value[permission.id] = true;
          });
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      }
    };
    const getPermissions = async () => {
      isLoading.value = true;
      try {
        const response = await fetchPermissions();
        permissions.value = response.data;
        getRole();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const capitalizedText = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };
    onMounted(() => {
      getPermissions();
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const formData = new FormData();
          let selected = [];
          selected_permissions.value.map((isSelected, value) => {
            if (isSelected) selected.push(value);
          });
          roleForm.permissions = JSON.stringify(selected);

          formData.append("name", roleForm.name);
          formData.append("description", roleForm.description);
          formData.append("permissions", roleForm.permissions);

          if (roleForm.id > 0) {
            updateRole(roleForm, roleForm.id)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  router.push("/roles");
                } else {
                  let error = Object.values(data.errors);

                  error.forEach((err) =>
                    ElNotification({
                      title: "Error",
                      message: err[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {});
          } else {
            createRole(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  router.push("/roles");
                } else {
                  let error = Object.values(data.errors);

                  error.forEach((err) =>
                    ElNotification({
                      title: "Error",
                      message: err[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {});
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    return {
      role_id,
      submitForm,
      current_permissions,
      selected_permissions,
      capitalizedText,
      actions,
      permissions,
      roleForm,
      RolePermissionFormRef,
      rules,
      isLoading,
      getRole,
    };
  },
};
</script>
<style scoped></style>
