<template>
  <div class="modal-overlay" @click="$emit('close-appointment-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="ni ni-calendar-grid-58 text-primary me-2" />

        {{
          props.appointment?.id != null ? "Edit Appointment" : "New Appointment"
        }}
      </div>
      <hr class="horizontal dark m-0" />

      <div class="card-body">
        <el-form
          ref="appointmentFormRef"
          :model="appointmentForm"
          label-width="auto"
          label-position="top"
          :rules="rules"
          size=""
          status-icon
          :scroll-to-error="true"
          :hide-required-asterisk="true"
        >
          <el-form-item
            v-if="props?.patient?.id == null && props?.appointment?.id == null"
            label="Patient Name"
            prop="patient_id"
          >
            <el-select-v2
              v-model="appointmentForm.patient_id"
              filterable
              remote
              :options="patients"
              placeholder="Please select a patient"
              style="width: 100%"
              :remote-method="loadPatients"
              :loading="loading"
            >
            </el-select-v2>
          </el-form-item>
          <el-form-item>
            <el-input
              v-if="
                props?.patient?.id != null || props?.appointment?.id != null
              "
              v-model="appointmentForm.patient_name"
              disabled
            />
          </el-form-item>
          <el-form-item
            label-width="40%"
            label="Appoitment Type"
            prop="appointment_type"
          >
            <el-radio-group v-model="appointmentForm.appointment_type">
              <el-radio
                v-for="(appointment_type, index) in [
                  { value: 'out-patient', label: 'Out Patient' },
                  { value: 'day-case', label: 'Day Case' },
                  { value: 'in-patient', label: 'In Patient' },
                ]"
                :key="index"
                :label="appointment_type.value"
                size="large"
                border
                >{{ appointment_type.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item
            v-if="
              appointmentForm.appointment_type == 'in-patient' ||
              appointmentForm.appointment_type == 'day-case'
            "
            label="Hospital"
            prop="hospital"
          >
            <el-select-v2
              v-model="appointmentForm.hospital_id"
              filterable
              :options="hospitals"
              placeholder="Please select a hospital"
              style="width: 100%"
            />
          </el-form-item>

          <el-form-item
            v-if="appointmentForm.appointment_type == 'in-patient'"
            label="Admission Number"
            prop="admission_number"
          >
            <el-input v-model="appointmentForm.admission_number" />
          </el-form-item>
          <el-form-item
            v-if="appointmentForm.appointment_type == 'in-patient'"
            label="Ward"
            prop="ward"
          >
            <el-select-v2
              v-model="appointmentForm.ward"
              filterable
              :options="wards"
              placeholder="Please select a ward"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            v-if="appointmentForm.ward == 'Others'"
            label="Ward Name"
            prop="ward_name"
          >
            <el-input v-model="appointmentForm.ward_name" />
          </el-form-item>
          <el-form-item>
            <el-col :span="11">
              <el-form-item label="Appointment Date" prop="date">
                <el-date-picker
                  type="date"
                  placeholder="Pick a date"
                  style="width: 100%"
                  v-model="appointmentForm.appointment_date"
                />
              </el-form-item>
            </el-col>
            <el-col class="text-center" :span="1" style="margin: 0 0.5rem"
              >-</el-col
            >

            <el-col :span="11">
              <el-form-item label="Appointment Time" prop="time">
                <el-time-picker
                  placeholder="Pick time"
                  style="width: 100%"
                  v-model="appointmentForm.appointment_time"
                />
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="Doctor" prop="doctor_id">
            <el-select-v2
              v-model="appointmentForm.doctor_id"
              filterable
              :options="doctors"
              placeholder="Please select a doctor"
              style="width: 100%"
            />
          </el-form-item>

          <el-form-item label="Service" prop="selected_services">
            <el-select
              placeholder="Please choose Services Offered"
              v-model="selected_services"
              searchable
              filterable
              style="width: 100%"
              multiple
              :reserve-keyword="false"
              placement="top"
            >
              <el-option
                v-for="service in services"
                :key="service"
                :label="service.label"
                :value="service.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="row">
        <div class="col-md-12 mb-5 card--footer">
          <el-button
            type="danger"
            class="btn mb-0 btn-danger btn-xs"
            @click="$emit('close-appointment-modal')"
          >
            Cancel
          </el-button>
          <el-button
            type="success"
            class="btn mb-0 btn-success btn-xs"
            :loading="isLoading"
            @click="submitForm(appointmentFormRef)"
          >
            Submit
          </el-button>
        </div>
      </div>
    </div>
    <div class="close bg-danger" @click="$emit('close-appointment-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  createAppointment,
  updateAppointment,
} from "../../api/services/appointments";
import { fetchUsers, fetchPatients } from "../../api/services/users";
import { fetchServices, fetchHospitals } from "../../api/services/services";
import moment from "moment";

const appointmentFormRef = ref();
const doctors = ref([]);
const services = ref([]);
const patients = ref([]);
const hospitals = ref([]);
const isLoading = ref(false);
const selected_services = ref([]);
const wards = [
  { value: "Maternity", label: "Maternity" },
  { value: "Labour", label: "Labour" },
  { value: "Surgical", label: "Surgical" },
  { value: "Others", label: "Others" },
];
export default {
  props: {
    appointment: {
      type: Object,
      required: false,
      default: null,
    },
    patient: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props, { emit }) {
    selected_services.value = ref([]);
    var pid = "";
    var p_name = "";
    if (props.patient?.id != null) {
      pid = String(props.patient.id);
      p_name = props?.patient?.first_name + " " + props?.patient?.last_name;
    }

    if (props.appointment.id != null) {
      pid = String(props?.appointment.patient?.id);
      p_name =
        props?.appointment.patient?.first_name +
        " " +
        props?.appointment.patient?.last_name;
    }

    let _time = "";
    if (props.appointment.appointment_time != null) {
      _time = moment(
        moment(props.appointment.appointment_date).format("YYYY-MM-DD") +
          " " +
          props.appointment.appointment_time
      ).format("YYYY-MM-DD HH:mm:ss");
    } else {
      _time = new Date();
    }

    const appointmentForm = reactive({
      id: props.appointment != null ? String(props.appointment.id) : "",
      patient_id: pid,
      doctor_id:
        props.appointment?.doctor_id != null
          ? String(props.appointment.doctor_id)
          : null,
      appointment_date:
        props.appointment != null
          ? moment(props.appointment.appointment_date).format("YYYY-MM-DD")
          : new Date(),
      appointment_time: _time,
      appointment_type:
        props.appointment != null ? props.appointment.appointment_type : "",
      ward: props.appointment != null ? props.appointment.ward : "",
      admission_number:
        props.appointment != null ? props.appointment.admission_number : "",
      ward_name: props.appointment != null ? props.appointment.ward_name : "",
      hospital_id:
        props?.appointment?.hospital_id != null
          ? String(props.appointment.hospital_id)
          : "",
      patient_name: p_name,
    });

    const rules = reactive({
      patient_id: [
        {
          required: true,
          message: "Please select a patient",
          trigger: ["blur", "change"],
        },
      ],

      appointment_type: [
        {
          required: true,
          message: "Please indicate the patient type",
          trigger: ["blur", "change"],
        },
      ],
      doctor_id: [
        {
          required: true,
          message: "Please select a doctor",
          trigger: ["blur", "change"],
        },
      ],
      appointment_date: [
        {
          required: true,
          message: "Please select appointment date",
          trigger: ["blur", "change"],
        },
      ],

      appointment_time: [
        {
          required: true,
          message: "Please select appointment time",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          if (appointmentForm.id > 0) {
            appointmentForm.service_ids = JSON.stringify(
              selected_services.value
            );
          } else {
            appointmentForm.service_ids = JSON.stringify(
              selected_services.value
            );
          }

          formData.append(
            "appointment_date",
            moment(appointmentForm.appointment_date).format("YYYY-MM-DD")
          );
          appointmentForm.appointment_date = moment(
            appointmentForm.appointment_date
          ).format("YYYY-MM-DD");

          formData.append(
            "appointment_time",
            moment(appointmentForm.appointment_time).format("HH:mm:ss")
          );
          appointmentForm.appointment_time = moment(
            appointmentForm.appointment_time
          ).format("HH:mm:ss");

          formData.append("service_ids", appointmentForm.service_ids);
          formData.append("doctor_id", appointmentForm.doctor_id);
          formData.append("patient_id", appointmentForm.patient_id);
          formData.append("appointment_type", appointmentForm.appointment_type);
          formData.append("hospital_id", appointmentForm.hospital_id);
          formData.append("admission_number", appointmentForm.admission_number);
          formData.append("ward", appointmentForm.ward);
          formData.append("ward_name", appointmentForm.ward_name);
          if (appointmentForm.id > 0) {
            updateAppointment(appointmentForm)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  emit("refresh-appointments");
                  emit("close-appointment-modal");
                  appointmentForm.value = reactive({});
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createAppointment(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  emit("refresh-appointments");
                  emit("close-appointment-modal");
                  appointmentForm.value = reactive({});
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const loadPatients = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchPatients(entity_params);

        const tempPatients = response.data.data.map((patient) => ({
          value: `${patient.id}`,
          label: `${
            patient.first_name +
            " " +
            patient.middle_name +
            " " +
            patient.last_name
          }`,
        }));
        patients.value = tempPatients;
      } catch (error) {
        console.log(error);
      }
    };
    const loadDoctors = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query, role: "doctor" };
      try {
        const response = await fetchUsers(entity_params);

        const tempDoctors = response.data.data.map((doctor) => ({
          value: `${doctor.id}`,
          label: `${doctor.first_name + " " + doctor.last_name}`,
        }));
        doctors.value = tempDoctors;
      } catch (error) {
        console.log(error);
      }
    };
    const loadServices = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchServices(entity_params);
        const tempServices = response.data.data.map((service) => ({
          value: `${service.id}`,
          label: `${service.item}`,
        }));
        services.value = tempServices;

        const tempServicesIds = [];
        if (props?.appointment?.id != null) {
          tempServicesIds.value = props?.appointment?.service.map((service) => {
            tempServicesIds.push(String(service.id));
          });
          selected_services.value = tempServicesIds;
        }
      } catch (error) {
        console.log(error);
      }
    };
    const loadHospitals = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchHospitals(entity_params);

        const tempHospitals = response.data.data.map((hospital) => ({
          value: `${hospital.id}`,
          label: `${hospital.name}`,
        }));
        hospitals.value = tempHospitals;
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      loadServices("", 1);
      loadDoctors("", 1);
      loadPatients("", 1);
      loadHospitals("", 1);
    });
    return {
      loadDoctors,
      loadPatients,
      submitForm,
      rules,
      doctors,
      patients,
      services,
      hospitals,
      appointmentForm,
      appointmentFormRef,
      isLoading,
      props,
      wards,
      selected_services,
    };
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  max-height: 100%;
  overflow-y: auto;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 600px;
  height: 600px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
.el-radio-group {
  margin-right: 12px;
}
</style>
