<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="ni ni-calendar-grid-58 text-primary text-md opacity-10"></i>
        <h6>Insurance Summary</h6>
      </div>
      <div class="cta">
        <el-button-group>
          <FilterTables
            :show_date_filter="true"
            date_range_label="Select Date Range"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />
          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="tableData.length === 0">
        <empty-state-table />
      </div>

      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Name
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Invoice Count
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Invoice Total(Ksh.)
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Paid Total(Ksh.)
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Balance(Ksh.)
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="item.id + index">
              <td class="ps-4 text-xs text-left">
                {{ item.name }}
                <p>
                  Contact:<small> {{ item.contact_phone }}</small>
                </p>
              </td>

              <td class="ps-4 text-xs text-left">
                {{ item.invoice_count }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ numberFormat(item.invoice_total) }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ numberFormat(item.payment_total) }}
              </td>
              <td class="ps-4 text-xs text-left">
                {{ numberFormat(item.balance) }}
              </td>
              <td class="ps-4 text-xs text-left">
                <router-link
                  :to="{
                    path: `/accounts/invoices/insurance/${item.id}`,
                  }"
                  class="text-secondary"
                >
                  <el-button type="default">
                    <i class="fas fa-list text-dark me-1" aria-hidden="true"></i
                    >View List
                  </el-button></router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3"></div>
  </div>
</template>
<script>
import { ref } from "vue";
import Loader from "./components/Loader.vue";
import { ElMessage } from "element-plus";
import FilterTables from "./components/FilterTables.vue";
import {
  fetchInsuranceSummary,
  downloadInsuranceSummaryPdf,
  downloadInsuranceSummaryExcel,
} from "../api/services/services";
const tableData = ref([]);
const isLoading = ref(false);
const filter_params = ref({});
export default {
  name: "doctors",
  components: {
    Loader,
    FilterTables,
  },
  setup() {
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      fetchData(filter_params, 1);
    };
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        tableData.value.splice(0);
        const response = await fetchInsuranceSummary(entity_params, page);
        tableData.value = response.data?.map((item) => item);
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const numberFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const downloadExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadInsuranceSummaryExcel(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "insurance_summary_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadInsuranceSummaryPdf(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "insurance_summary_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    return {
      filter_params,
      _filterCallBack,
      downloadExcel,
      downloadPdf,
      isLoading,
      fetchData,
      tableData,
      numberFormat,
    };
  },
  mounted() {
    this.fetchData(undefined, 1);
  },
};
</script>
<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

h6 {
  margin: 0;
}

.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
