<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-user-md text-primary me-2" />
      Manage Consumables
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Products</div>
            <div class="card-body">
              <el-table
                :data="filterTableProducts"
                height="500"
                style="width: 100%"
              >
                <el-table-column label="Name" prop="name" />
                <el-table-column label="Description" prop="description" />
                <el-table-column align="right">
                  <template #header>
                    <el-input
                      v-model="ProdSearch"
                      size="small"
                      placeholder="Type to search"
                    />
                  </template>
                  <template #default="scope">
                    <el-switch
                      v-model="selected_product[scope.row.id]"
                      :loading="isProdUpdating[scope.row.id]"
                      class="ml-2"
                      @change="handleSwitch($event, scope.row.id, 'product')"
                      style="
                        --el-switch-on-color: #13ce66;
                        --el-switch-off-color: #ff4949;
                      "
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Services</div>
            <div class="card-body">
              <el-table
                :data="filterTableServices"
                height="500"
                style="width: 100%"
              >
                <el-table-column label="Name" prop="item" />
                <el-table-column label="Description" prop="description" />
                <el-table-column align="right">
                  <template #header>
                    <el-input
                      v-model="ServSearch"
                      size="small"
                      placeholder="Type to search"
                    />
                  </template>
                  <template #default="scope">
                    <el-switch
                      v-model="selected_services[scope.row.id]"
                      :loading="isServUpdating[scope.row.id]"
                      class="ml-2"
                      @change="handleSwitch($event, scope.row.id, 'service')"
                      style="
                        --el-switch-on-color: #13ce66;
                        --el-switch-off-color: #ff4949;
                      "
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="horizontal dark m-0" />
  </div>
</template>
<script>
import { onMounted, ref, reactive, computed } from "vue";
import { fetchProducts } from "../../api/services/inventory";
import { fetchServices, updateServiceItem } from "../../api/services/services";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
const ProdSearch = ref("");
const ServSearch = ref("");
const isLoading = ref(false);
const products = ref([]);
const services = ref([]);
export default {
  name: "service-consumables",
  props: {},
  setup() {
    const route = useRoute();
    const sid = route.params?.id;
    const filterTableProducts = computed(() =>
      products.value.data.filter(
        (data) =>
          !ProdSearch.value ||
          data.name.toLowerCase().includes(ProdSearch.value.toLowerCase()) ||
          data.description
            .toLowerCase()
            .includes(ProdSearch.value.toLowerCase())
      )
    );
    const filterTableServices = computed(() =>
      services.value.data.filter(
        (data) =>
          !ServSearch.value ||
          data.item.toLowerCase().includes(ServSearch.value.toLowerCase()) ||
          data.description
            .toLowerCase()
            .includes(ServSearch.value.toLowerCase())
      )
    );

      const selected_product = {};
    const selected_services = {};

    const isProdUpdating = reactive({});
    const isServUpdating = reactive({});

    const fetchData = async () => {
      isLoading.value = true;
      try {
        const filter = ref();
        filter.value = { service_id: sid };

        const prod_response = await fetchProducts(filter);

        prod_response.data?.data?.map((prod) => {
          selected_product[prod.id] = prod.service_item.length ? true : false;
          isProdUpdating[prod.id] = false;
        });
        products.value = prod_response.data;

        const serv_response = await fetchServices(filter);
        serv_response.data?.data?.map((serv) => {
          selected_services[serv.id] = serv.service_item.length ? true : false;
          isServUpdating[serv.id] = false;
        });
        services.value = serv_response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const handleSwitch = ($state, $idx, $type) => {
      const postBody = {
        service_id: sid,
        state: $state,
        entity: $idx,
        entity_type: $type,
      };
      try {
        if ($type == "product") isProdUpdating[$idx] = true;
        else isServUpdating[$idx] = true;

        updateServiceItem(postBody).then((response) => {
          if ($type == "product") isProdUpdating[$idx] = false;
          else isServUpdating[$idx] = false;
          if (response.success)
            ElNotification({
              title: "Success",
              message: response.message,
              type: "success",
            });
          else
            ElNotification({
              title: "Error",
              message: "Error updating service Item",
              type: "error",
            });
        });
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });
    return {
      ProdSearch,
      ServSearch,
      products,
      selected_product,
      services,
      selected_services,
      isProdUpdating,
      isServUpdating,
      filterTableProducts,
      filterTableServices,
      handleSwitch,
      fetchData,
    };
  },
};
</script>
