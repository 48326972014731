<template>
  <div class="py-4 container-fluid">
    <div class="row" v-if="role == 'ADMIN'">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.income.title"
              :value="stats.income.value"
              :percentage="stats.income.percentage"
              :iconClass="stats.income.iconClass"
              :iconBackground="stats.income.iconBackground"
              :detail="stats.income.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.patients.title"
              :value="stats.patients.value"
              :percentage="stats.patients.percentage"
              :iconClass="stats.patients.iconClass"
              :iconBackground="stats.patients.iconBackground"
              :detail="stats.patients.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.appointments.title"
              :value="stats.appointments.value"
              :percentage="stats.appointments.percentage"
              :iconClass="stats.appointments.iconClass"
              :iconBackground="stats.appointments.iconBackground"
              :percentageColor="stats.appointments.percentageColor"
              :detail="stats.appointments.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.sales.title"
              :value="stats.sales.value"
              :percentage="stats.sales.percentage"
              :iconClass="stats.sales.iconClass"
              :iconBackground="stats.sales.iconBackground"
              :detail="stats.sales.detail"
              directionReverse
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="col-m-12 mb-2">
              <appointments-by-month />
            </div>

            <div class="col-m-4 mt-2">
              <payment-summary />
            </div>
          </div>
          <div class="col-lg-5">
            <debtors-card :debtors="top_debtors" />
          </div>
        </div>
        <div class="row">
          <div class="col-m-12 mt-2">
            <appointments-by-doctors />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/Main/Cards/Card.vue";
import DebtorsCard from "./components/DebtorsCard.vue";
import AppointmentsByMonth from "@/Main/Charts/AppointmentsByMonth.vue";
import AppointmentsByDoctors from "@/Main/Charts/AppointmentsByDoctors.vue";
import PaymentSummary from "@/Main/Charts/PaymentSummary.vue";
import { fetchStatistics, fetchDebtors } from "../api/services/reports";
import { ref, reactive, onMounted } from "vue";
const isLoading = ref(false);
const top_debtors = ref({});

export default {
  name: "dashboard",
  components: {
    Card,
    DebtorsCard,
    AppointmentsByMonth,
    AppointmentsByDoctors,
    PaymentSummary,
  },
  setup() {
    const monthly_appointments = reactive({
      labels: [],
      data: [],
    });
    const stats = reactive({
      income: {
        title: "Monthly Income",
        value: "",
        percentage: "",
        iconClass: "ni ni-money-coins",
        detail: "",
        iconBackground: "bg-gradient-primary",
      },
      patients: {
        title: "Total Patients",
        value: "",
        percentage: "",
        iconClass: "fa fa-users",
        iconBackground: "bg-gradient-danger",
        detail: "",
      },
      appointments: {
        title: "Total Appointments",
        value: "",
        percentage: "",
        iconClass: "ni ni-calendar-grid-58",
        percentageColor: "text-danger",
        iconBackground: "bg-gradient-success",
        detail: "",
      },
      sales: {
        title: "Accounts receivable",
        value: "",
        percentage: "",
        iconClass: "ni ni-cart",
        iconBackground: "bg-gradient-warning",
        detail: "",
      },
    });

    const fetchStatisticsData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchStatistics();
        stats.income.value = response.monthly_income;
        stats.patients.value = response.patient_count;
        stats.appointments.value = response.appointment_count;
        stats.sales.value = response.outstanding_balance;
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchDebtorsData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchDebtors();
        top_debtors.value = response.top_debtors;
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const role = localStorage.getItem("role");

    onMounted(() => {
      fetchStatisticsData();
      fetchDebtorsData();
    });
    return {
      stats,
      top_debtors,
      monthly_appointments,
      isLoading,
      role,
    };
  },
};
</script>
