import { api } from "../../index";

const template_url = (root, filter, page) => {
  var url = root;

  if (filter.value !== undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=template:like;type:like;&search=" +
      filter.value.search_query;

  if (page) url = url + "&page=" + page;

  url = url.replace("templates?&", "templates?");

  return url;
};

export const fetchTemplates = async (filter, page) => {
  return await api
    .get(template_url("templates?orderBy=id&sortedBy=desc", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const fetchTemplate = async (id) => {
  return await api
    .get(`templates/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const createTemplate = async (postBody) => {
  return await api
    .post("templates", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const updateTemplate = async (body, id) => {
  return await api
    .put("templates/" + id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const deleteTemplate = async (id) => {
  return await api
    .delete("templates/" + id)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
