<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-product-o text-primary text-md opacity-10"></i>
        <h6>Products</h6>
      </div>
      <div class="cta">
        <el-dropdown trigger="click" v-if="selected.length > 0">
          <span class="el-dropdown-link">
            <el-button class="me-2" size="large" circle>
              <i class="fa fa-ellipsis-v text-primary"></i>
            </el-button>
          </span>
          <template #dropdown>
            <el-dropdown-item
              class="text-primary font-weight-bold text-xs"
              v-if="hasPermission('delete.stock')"
              @click="ConfirmResetStock()"
            >
              Reset Stock
            </el-dropdown-item>
          </template>
        </el-dropdown>
        <el-button-group>
          <FilterTables
            :show_search_filter="true"
            search_label="Search products"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />

          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
          <el-button
            color="#626aef"
            @click="$router.push('/inventory/product/form')"
            v-if="hasPermission('create.product')"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >New Product</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="products.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="products.data.length > 0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                <input type="checkbox" v-model="selectAll" />
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name & Code
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Reorder Level
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Current Stock
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Available Stock
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Dispensed Stock
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Cost(Ksh)
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Price(Ksh)
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Brand
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Category
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Unit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in products.data" :key="item.id + index">
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('update.product')"
                        @click="
                          $router.push(`/inventory/product/form/${item.id}`)
                        "
                      >
                        Edit
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('create.stock')"
                        @click="reStock(item)"
                      >
                        Re-stock
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('manage.stock')"
                        @click="viewStockHistory(item)"
                      >
                        Stock History
                      </el-dropdown-item>

                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('delete.product')"
                        @click="deleteProduct(item.id)"
                      >
                        Delete
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
              <td>
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="item.id"
                  number
                />
              </td>
              <td>
                <h6 class="mb-0 text-sm text-capitalize ps-3">
                  {{ item.name }}
                </h6>
                <span class="mb-0 text-sm ps-3">{{ item.code }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item.reorder_level
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item.totalstock_sum_current_stock
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item.totalstock_sum_current_stock - item.reorder_level
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item.totalstock_sum_purchase_stock -
                  item.totalstock_sum_current_stock
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item.activestock?.unit_buying_price
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item.activestock?.unit_selling_price
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item?.brand?.name || ""
                }}</span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">
                  {{ item?.category?.name || "" }}
                </span>
              </td>
              <td>
                <span class="mb-0 text-sm text-capitalize ps-3">{{
                  item?.unit?.name
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
  <StockForm
    v-if="showStockModal"
    @close-modal="showStockModal = false"
    :product="product"
    @refresh-units="refresh"
  />
  <StockList
    v-if="showStockListModal"
    @close-modal="showStockListModal = false"
    :product="product"
    @refresh-stock="refresh"
  />
</template>

<script>
import StockForm from "../../components/Modals/StockForm.vue";
import StockList from "./StockTable.vue";
import moment from "moment";
import FilterTables from "./FilterTables.vue";
import { ref, h, reactive, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { removeProduct, resetProductStock } from "../../api/services/inventory";
import Pagination from "./Pagination.vue";
import EmptyStateTable from "./EmptyTableState.vue";

const showStockModal = ref(false);
const showStockListModal = ref(false);
const selected = ref([]);
const product = ref();
const isLoading = ref(false);
const filter_params = ref({});
export default {
  name: "products-table",
  props: {
    products: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchList: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    Pagination,
    EmptyStateTable,
    StockForm,
    StockList,
    FilterTables,
  },

  setup(props) {
    const total_records = props?.products?.total || 0;
    const per_page = props?.products?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }
    const Pagination = reactive({
      current_page: props?.products?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });
    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

    const searchFilter = () => {
      props.refresh(Filter.searchquery, 1);
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const selectAll = computed({
      get() {
        if (props?.products?.data && props?.products?.data?.length > 0) {
          // A users array exists with at least one item
          let allChecked = true;

          for (const invoice of props?.products?.data) {
            if (!selected.value.includes(invoice.id)) {
              allChecked = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          props?.products?.data?.forEach((invoice) => {
            checked.push(invoice.id);
          });
        }

        selected.value = checked;
      },
    });
    const deleteProduct = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "This product will permanently delete. Continue?"
        ),

        "Delete Product",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeProduct(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };
    const reStock = (prod) => {
      product.value = prod;
      showStockModal.value = true;
    };
    const viewStockHistory = (prod) => {
      product.value = prod;
      showStockListModal.value = true;
    };
    const ConfirmResetStock = () => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "Stock history for the selected products will be trashed. This action cannot be undone. Continue?"
        ),

        "Reset Stock",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;

            const formData = new FormData();
            formData.append("product_ids", JSON.stringify(selected.value));
            const { message, success } = await resetProductStock(formData);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Reset canceled",
          });
        });
    };
    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
    return {
      _filterCallBack,
      filter_params,
      selectAll,
      selected,
      ConfirmResetStock,
      onPageChange,
      Pagination,
      searchFilter,
      reStock,
      Filter,
      dateTime,
      product,
      showStockModal,
      viewStockHistory,
      showStockListModal,
      isLoading,
      deleteProduct,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

h6 {
  margin: 0;
}

.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
