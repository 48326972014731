<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="row">
          <div
            class="top-0 my-auto text-center col-8 d-lg-flex d-none pe-0 justify-content-center flex-column"
            style="height: 100vh"
          >
            <div
              class="position-relative bg-gradient-primary h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/sign-in-side-image.jpg') + ')',
                backgroundSize: 'cover',
              }"
            >
              <span class="mask bg-gradient-secondary opacity-4"></span>
              <h4 class="mt-5 text-white font-weight-bolder position-relative">
                "Here to Simplify Your Hospital’s Management, One Click at a
                Time"
              </h4>
              <p class="text-white position-relative">
                A hospital and clinic management software which enables you to
                run your core financial, clinical, and operational processes
              </p>
            </div>
          </div>
          <div
            class="col-xl-4 col-lg-4 col-md-4 d-flex flex-column mx-lg-0 my-auto align-items-end"
          >
            <div class="card card-plain">
              <div class="pb-0 card-header text-start">
                <h4 class="font-weight-bolder">Success</h4>
              </div>
              <div class="card-body">
                <p class="mb-1">
                  Thank you {{ route.query.name }} for signing up on Mclinic. A
                  verification link has been sent to:<b>{{
                    route.query.email
                  }}</b>
                  . Click the link to verify and sign in to your account to
                  start managing your clinic.
                </p>
              </div>
              <div class="px-1 pt-0 text-center card-footer px-lg-2">
                <p class="mx-auto mb-4 text-sm">
                  <router-link
                    to="/signin/"
                    class="text-success text-gradient font-weight-bold"
                    >Sign In</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { useRoute } from "vue-router";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "welcome",

  setup() {
    const route = useRoute();

    return {
      route,
    };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
