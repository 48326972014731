<template>
  <div class="modal-overlay">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-user text-primary me-2" />{{
          props.patient.id != null ? "Edit Patient" : "New Patient"
        }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="patientFormRef"
        :model="patientForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <el-form-item
                label-width="40%"
                label="Patient Type"
                prop="patient_type"
              >
                <el-radio-group v-model="patientForm.patient_type">
                  <el-radio
                    v-for="(patient_type, index) in [
                      { value: 'old', label: 'Old Patient' },
                      { value: 'new', label: 'New Patient' },
                    ]"
                    :key="index"
                    :label="patient_type.value"
                    size="large"
                    border
                    >{{ patient_type.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Patient Number" prop="patient_number">
                <el-input
                  :disabled="
                    patientForm.patient_type == 'new' &&
                    props.patient.id == null
                  "
                  v-model="patientForm.patient_number"
                />
              </el-form-item>
            </div>

            <div class="col-md-4 mb-3">
              <el-form-item label="First Name" prop="first_name">
                <el-input v-model="patientForm.first_name" />
              </el-form-item>
            </div>
            <div class="col-md-4 mb-3">
              <el-form-item label="Middle Name" prop="middle_name">
                <el-input v-model="patientForm.middle_name" />
              </el-form-item>
            </div>
            <div class="col-md-4 mb-3">
              <el-form-item label="Last Name" prop="last_name">
                <el-input v-model="patientForm.last_name" />
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <el-form-item label="Date of birth" prop="date_of_birth">
                    <VueDatePicker
                      v-model="patientForm.date_of_birth"
                      :text-input-options="dateTextInputOptions"
                      position="left"
                      :flow="flow"
                      text-input
                      :format="dateformat"
                    ></VueDatePicker>
                  </el-form-item>
                </div>
                <div class="col-md-6 mb-3">
                  <el-form-item label=" Age" prop="Age">
                    <el-input type="number" v-model="patientForm.age" />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="ID Number" prop="id_number">
                <el-input v-model="patientForm.id_number" />
              </el-form-item>
            </div>

            <div class="col-md-6 mb-3">
              <label class="el-form-item__label">Phone Number</label>
              <vue-tel-input
                v-model="patientForm.phone"
                :value="patientForm.phone"
                @validate="checkMobileNumber"
                v-bind="phoneBindingProps"
              ></vue-tel-input>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Email" prop="email">
                <el-input v-model="patientForm.email" />
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Gender" prop="gender">
                <el-select
                  class="w-100"
                  placeholder="Select"
                  v-model="patientForm.gender"
                >
                  <el-option label="Male" value="male" />
                  <el-option label="Female" value="female" />
                  <el-option label="Other" value="other" />
                </el-select>
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Marital Status" prop="marital_status">
                <el-select
                  class="w-100"
                  placeholder="Select"
                  v-model="patientForm.marital_status"
                >
                  <el-option label="Single" value="single" />
                  <el-option label="Married" value="married" />
                  <el-option label="Divorced" value="divorced" />
                  <el-option label="Separated" value="separated" />
                  <el-option label="Other" value="other" />
                </el-select>
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Address" prop="address">
                <el-input v-model="patientForm.address" />
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Blood Group" prop="blood_group">
                <el-select
                  class="w-100"
                  placeholder="Select blood group"
                  v-model="patientForm.blood_group"
                >
                  <el-option label="-" value="-" />
                  <el-option label="A" value="A" />
                  <el-option label="B+" value="B+" />
                  <el-option label="0" value="0" />
                  <el-option label="AB" value="AB" />
                </el-select>
              </el-form-item>
            </div>
            <div class="row">
              <div class="col-md-12 mb-5 card--footer">
                <button
                  class="btn mb-0 btn-danger btn-xs"
                  @click="$emit('close-modal')"
                >
                  Cancel
                </button>

                <el-button
                  type="success"
                  :loading="isLoading"
                  @click="submitForm(patientFormRef)"
                >
                  {{
                    props.patient.id != null
                      ? "Update Patient Record"
                      : "Create Patient"
                  }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { createPatient, updatePatient } from "../../api/services/users";
import VueDatePicker from "@vuepic/vue-datepicker";
import { ElNotification } from "element-plus";

const patientFormRef = ref();
const isLoading = ref(false);

const phoneBindingProps = {
  mode: "international",
  styleClasses: "has-default",
  defaultCountry: "KE",
  onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
  inputOptions: {
    showDialCode: false,
    placeholder: "Mobile Number",
    name: "phone",
  },
};
export default {
  name: "patient-form",
  components: { VueDatePicker },
  props: {
    patient: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const flow = ref(["year", "month", "calendar"]);
    const dateTextInputOptions = ref({
      enterSubmit: true,
    });
    const dateformat = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };
    const yearDiff = (birthday) => {
      var ageDifMs = Date.now() - new Date(birthday);
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const patientForm = reactive({
      id: props?.patient?.id || 0,
      email: props?.patient?.email || "",
      first_name: props?.patient?.first_name || "",
      marital_status: props?.patient.marital_status || "",
      last_name: props?.patient?.last_name || "",
      middle_name: props?.patient?.middle_name || "",
      address: props?.patient?.address || "",
      id_number: props?.patient?.id_number || "",
      phone: props?.patient?.phone || "",
      gender: props?.patient?.gender || "",
      date_of_birth: new Date(props?.patient?.date_of_birth) || new Date(),
      blood_group: props?.patient?.blood_group || "",
      patient_type: props?.patient?.patient_type || "old",
      patient_number: props?.patient?.patient_number || "",
      age: yearDiff(new Date(props?.patient?.date_of_birth) || new Date()),
    });

    watch(
      () => patientForm.age,
      (age) => {
        console.log(`count is: ${age}`);
        let dt = new Date();
        dt.setFullYear(dt.getFullYear() - age);
        patientForm.date_of_birth = dt;
      }
    );
    const rules = reactive({
      email: [
        {
          required: false,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        {
          required: true,
          message: "Please input your phone number",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
      first_name: [
        {
          required: true,
          message: "Please input first name",
          trigger: ["blur", "change"],
        },
      ],
      last_name: [
        {
          required: true,
          message: "Please input last name",
          trigger: ["blur", "change"],
        },
      ],
      marital_status: [
        {
          required: false,
          message: "Please select an option",
          trigger: ["blur", "change"],
        },
      ],

      address: [
        {
          required: false,
          message: "Please input address",
          trigger: ["blur", "change"],
        },
      ],

      id_number: [
        {
          required: true,
          message: "Please input id number",
          trigger: ["blur", "change"],
        },
      ],
      gender: [
        {
          required: true,
          message: "Please select your gender",
          trigger: ["blur", "change"],
        },
      ],
      blood_group: [
        {
          required: false,
          message: "Please select patient's blood group",
          trigger: ["blur", "change"],
        },
      ],
      date_of_birth: [
        {
          required: false,
          message: "Please select patient's date of birth",
          trigger: ["blur", "change"],
        },
      ],
    });

    const checkMobileNumber = (event) => {
      if (event.valid) {
        patientForm.phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        patientForm.phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("email", patientForm.email);
          formData.append("first_name", patientForm.first_name);
          formData.append("middle_name", patientForm.middle_name);
          formData.append("last_name", patientForm.last_name);
          formData.append("address", patientForm.address);
          formData.append("id_number", patientForm.id_number);
          formData.append("phone", patientForm.phone);
          formData.append("gender", patientForm.gender);
          formData.append("date_of_birth", patientForm.date_of_birth);
          formData.append("blood_group", patientForm.blood_group);
          formData.append("patient_type", patientForm.patient_type);
          formData.append("patient_number", patientForm.patient_number);

          if (patientForm.id > 0) {
            updatePatient(patientForm)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-patients");
                  emit("close-modal");
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createPatient(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-patients");
                  emit("close-modal");
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) => console.log(err))
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      flow,
      dateformat,
      dateTextInputOptions,
      patientForm,
      submitForm,
      rules,
      patientFormRef,
      isLoading,
      props,
      phoneBindingProps,
      yearDiff,
      checkMobileNumber,
    };
  },
};
</script>
<style scoped>
.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  max-height: 100%;
  overflow-y: auto;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 800px;
  height: 800px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}

input {
  border-radius: 6px;
}
</style>
