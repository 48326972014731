<template>
  <div class="modal-overlay" @click="$emit('close-insurance-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-user text-primary me-2" />Add Insurance
      </div>
      <hr class="horizontal dark m-0" />

      <div class="card-body">
        <el-form
          ref="patientInsuranceFormRef"
          :model="patientInsuranceForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <el-form-item
            label-width="40%"
            label="Scheme Type"
            prop="scheme_type"
          >
            <el-radio-group v-model="patientInsuranceForm.scheme_type">
              <el-radio
                v-for="(scheme_type, index) in [
                  { value: 'insurance', label: 'Insurance' },
                  { value: 'self', label: 'Self Funded' },
                ]"
                :key="index"
                :label="scheme_type.value"
                size="large"
                border
                >{{ scheme_type.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <div
            class="input-container"
            v-if="patientInsuranceForm.scheme_type == 'insurance'"
          >
            <div class="input-field">
              <el-form-item label="Insurance" prop="insurance_id">
                <el-select-v2
                  v-model="patientInsuranceForm.insurance_id"
                  filterable
                  remote
                  :options="insurances"
                  placeholder="Please select an insurance provider"
                  style="width: 100%"
                  :remote-method="loadInsurances"
                  :loading="loading"
                />
              </el-form-item>
            </div>
            <button
              type="button"
              class="inline-button"
              v-if="hasPermission('create.insurance')"
              @click="newInsurance"
            >
              + New Insurance
            </button>
          </div>

          <div class="input-container">
            <div class="input-field">
              <el-form-item label="Employer/Scheme" prop="employer_id">
                <el-select-v2
                  v-model="patientInsuranceForm.employer_id"
                  filterable
                  remote
                  :options="employers"
                  placeholder="Please select the employer or scheme"
                  style="width: 100%"
                  :remote-method="loadEmployers"
                  :loading="loading"
                />
              </el-form-item>
            </div>
            <button
              type="button"
              class="inline-button"
              v-if="hasPermission('create.employer')"
              @click="newEmployer"
            >
              + New Employer
            </button>
          </div>

          <el-form-item label="Patient Name" prop="patient">
            <el-input v-model="patientInsuranceForm.patient" disabled />
          </el-form-item>

          <el-form-item
            v-if="patientInsuranceForm.scheme_type == 'insurance'"
            label="Membership Name"
            prop="membership_name"
          >
            <el-input v-model="patientInsuranceForm.membership_name" />
          </el-form-item>
          <el-form-item
            v-if="patientInsuranceForm.scheme_type == 'insurance'"
            label="Membership Number"
            prop="membership_number"
          >
            <el-input v-model="patientInsuranceForm.membership_number" />
          </el-form-item>
          <el-form-item
            v-if="patientInsuranceForm.scheme_type == 'self'"
            label="Employee/Staff Name"
            prop="employee_name"
          >
            <el-input v-model="patientInsuranceForm.employee_name" />
          </el-form-item>
          <el-form-item
            v-if="patientInsuranceForm.scheme_type == 'self'"
            label="Employee/Staff Number"
            prop="employee_number"
          >
            <el-input v-model="patientInsuranceForm.employee_number" />
          </el-form-item>
          <el-form-item label="Expiry Date" prop="expiry">
            <el-date-picker
              v-model="patientInsuranceForm.expiry"
              type="date"
              label="Pick a date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-form>
      </div>

      <div class="card--footer">
        <button
          class="btn mb-0 btn-danger btn-xs"
          @click="$emit('close-insurance-modal')"
        >
          Cancel
        </button>
        <el-button
          type="success"
          :loading="isLoading"
          @click="submitForm(patientInsuranceFormRef)"
          >Submit</el-button
        >
      </div>
    </div>

    <div class="close bg-danger" @click="$emit('close-insurance-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
  <InsuranceForm
    v-if="showInsuranceModal"
    @close-modal="showInsuranceModal = false"
    :insurance="insuranceDetails"
    @refresh-insurances="refresh"
    @insurance-created="insuranceCreated"
  />

  <EmployerForm
    v-if="showEmployerModal"
    @close-modal="showEmployerModal = false"
    :employer="employerDetails"
    @refresh-employers="refresh"
    @employer-created="employerCreated"
  />
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  createPatientInsurance,
  updatePatientInsurance,
} from "../../api/services/services";
import InsuranceForm from "../../components/Modals/InsuranceForm.vue";
import EmployerForm from "../../components/Modals/EmployerForm.vue";
import { ElNotification } from "element-plus";
import { fetchInsurances, fetchEmployers } from "../../api/services/services";
import moment from "moment";
const patientInsuranceFormRef = ref();
const isLoading = ref(false);

const insurances = ref([]);
const showInsuranceModal = ref(false);
const insuranceDetails = ref({});

const employers = ref([]);
const showEmployerModal = ref(false);
const employerDetails = ref({});

export default {
  name: "add-patient-insurance",
  components: { InsuranceForm, EmployerForm },
  props: {
    patient_insurance: {
      type: Object,
    },
    patient: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const patient_name = `${
      props?.patient?.first_name + " " + props?.patient?.last_name
    }`;
    const newInsurance = () => {
      showInsuranceModal.value = true;
    };
    const newEmployer = () => {
      showEmployerModal.value = true;
    };

    const employerCreated = (employer) => {
      employers.value.push({
        value: employer.id,
        label: employer.name,
      });
      patientInsuranceForm.employer_id = employer.id;
    };

    const insuranceCreated = (insurance) => {
      insurances.value.push({
        value: insurance.id,
        label: insurance.name,
      });
      patientInsuranceForm.insurance_id = insurance.id;
    };

    const patientInsuranceForm = reactive({
      id: props?.patient_insurance != null ? props?.patient_insurance.id : 0,

      expiry:
        props?.patient_insurance != null
          ? moment(props?.patient_insurance?.expiry_date).format("MM/DD/YYYY")
          : new Date(),
      patient: patient_name || "",
      insurance_id:
        props?.patient_insurance?.insurance_id != null
          ? String(props?.patient_insurance?.insurance_id)
          : "",
      employer_id:
        props?.patient_insurance?.employer_id != null
          ? String(props?.patient_insurance?.employer_id)
          : "",
      membership_number:
        props?.patient_insurance != null
          ? props?.patient_insurance.membership_number
          : "",
      membership_name:
        props?.patient_insurance != null
          ? props?.patient_insurance.membership_name
          : "",
      employee_number:
        props?.patient_insurance != null
          ? props?.patient_insurance.employee_number
          : "",
      employee_name:
        props?.patient_insurance != null
          ? props?.patient_insurance.employee_name
          : "",
      scheme_type:
        props?.patient_insurance != null
          ? props?.patient_insurance.scheme_type
          : "insurance",
    });

    const rules = reactive({
      patient: [
        {
          required: true,
          message: "Please input patient name",
          trigger: ["blur", "change"],
        },
      ],
      expiry: [
        {
          required: true,
          message: "Please input insurance expiry date",
          trigger: ["blur", "change"],
        },
      ],
      insurance_id: [
        {
          required: true,
          message: "Please select an insurance provider",
          trigger: ["blur", "change"],
        },
      ],
      employer_id: [
        {
          required: true,
          message: "Please select an insurance provider",
          trigger: ["blur", "change"],
        },
      ],
      membership_number: [
        {
          required: true,
          message: "Please input member  number",
          trigger: ["blur", "change"],
        },
      ],
      membership_name: [
        {
          required: true,
          message: "Please input member name",
          trigger: ["blur", "change"],
        },
      ],
      employee_number: [
        {
          required: true,
          message: "Please input employee number",
          trigger: ["blur", "change"],
        },
      ],
      employee_name: [
        {
          required: true,
          message: "Please input employee name",
          trigger: ["blur", "change"],
        },
      ],
    });

    const dateTime = (value) => {
      return moment(value).format("MM/DD/YYYY");
    };

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          let date = dateTime(patientInsuranceForm.expiry);
          patientInsuranceForm.expiry_date = date;
          patientInsuranceForm.patient_id = props.patient.id;
          formData.append("patient_id", props.patient.id);
          formData.append("insurance_id", patientInsuranceForm.insurance_id);
          formData.append("employer_id", patientInsuranceForm.employer_id);
          formData.append("expiry_date", date);
          formData.append("scheme_type", patientInsuranceForm.scheme_type);
          formData.append(
            "membership_name",
            patientInsuranceForm?.membership_name || ""
          );
          formData.append(
            "membership_number",
            patientInsuranceForm?.membership_number || ""
          );
          formData.append(
            "employee_name",
            patientInsuranceForm?.employee_name || ""
          );
          formData.append(
            "employee_number",
            patientInsuranceForm?.employee_number || ""
          );

          if (patientInsuranceForm.id > 0) {
            updatePatientInsurance(patientInsuranceForm)
              .then((data) => {
                if (data.success) {
                  console.log("data", data);

                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-patients");
                  emit("close-insurance-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createPatientInsurance(formData)
              .then((data) => {
                if (data.success) {
                  console.log("data", data);

                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-patients");
                  emit("close-insurance-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const loadInsurances = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchInsurances(entity_params);

        const tempInsurances = response.data.data.map((nsurance) => ({
          value: `${nsurance.id}`,
          label: `${nsurance.name}`,
        }));
        insurances.value = tempInsurances;
      } catch (error) {
        console.log(error);
      }
    };
    const loadEmployers = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchEmployers(entity_params);

        const tempEmployer = response.data.data.map((employer) => ({
          value: `${employer.id}`,
          label: `${employer.name}`,
        }));
        employers.value = tempEmployer;

        //this code is to virtually add the employer if not in first page
        if (props?.patient_insurance?.employer != null) {
          if (
            !employers.value.some(
              (employer) => employer.id == props?.patient_insurance?.employer.id
            )
          ) {
            employers.value.push({
              value: props?.patient_insurance?.employer.id,
              label: props?.patient_insurance?.employer.name,
            });
            patientInsuranceForm.employer_id =
              props?.patient_insurance?.employer.id;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      loadInsurances("");
      loadEmployers("");
    });
    return {
      submitForm,

      newEmployer,
      employerCreated,
      employerDetails,
      showEmployerModal,

      newInsurance,
      insuranceCreated,
      insuranceDetails,
      showInsuranceModal,

      rules,
      patientInsuranceForm,
      patientInsuranceFormRef,
      isLoading,
      props,

      loadInsurances,
      insurances,
      loadEmployers,
      employers,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  max-height: 100%;
  overflow-y: auto;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 600px;
  overflow-y: auto;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}

.inline-button {
  margin-left: 10px;
  margin-top: 10px;
  background-color: #2dce89;
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in;
}
</style>
