<template>
  <div class="card card-nav-tabs">
    <div class="card-header card-header-warning">
      Doctors Monthly Appointments
    </div>
    <div class="card-body">
      <Bar
        id="appointment-doctors-chart-id"
        :options="chartOptions"
        :data="chartData"
        v-if="!isLoading"
      />
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import { onMounted, ref, reactive } from "vue";
import { fetchDoctorsAppointments } from "../../api/services/reports";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
const isLoading = ref(false);

const chartOptions = {
  responsive: true,
};
export default {
  name: "AppointmentsByMonth",
  components: { Bar },

  setup() {
    const chartData = reactive({
      labels: [],
      datasets: [],
    });

    const fetchDoctorsAppointmentsData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchDoctorsAppointments();
        chartData.labels = Object.values(response.doctors);
        chartData.datasets = [
          {
            label: "In Patient",
            backgroundColor: "#127a4c",
            data: Object.values(response.doctor_appointments.in_patient),
          },
          {
            label: "Out Patient",
            backgroundColor: "#6c8212",
            data: Object.values(response.doctor_appointments.out_patient),
          },
          {
            label: "Day Case",
            backgroundColor: "#6e0a55",
            data: Object.values(response.doctor_appointments.day_case),
          },
        ];
        console.log(chartData);
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      fetchDoctorsAppointmentsData();
    });
    return {
      isLoading,
      chartData,
      chartOptions,
    };
  },
};
</script>
