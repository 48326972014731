<template>
  <div class="py-4 container-fluid">
    <el-form
      class="el-form-item--label-top"
      ref="paymentFormRef"
      :model="paymentForm"
      :rules="rules"
      status-icon
      :scroll-to-error="true"
      label-position="top"
      :hide-required-asterisk="true"
    >
      <div class="row">
        <div v-if="isLoading"><Loader /></div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header pb-0 mb-5">
              <div class="d-flex align-items-center gap-2">
                <i class="fa fa-receipt text-primary text-md opacity-10"></i>
                <h6>Payment Details</h6>
              </div>
            </div>
            <div class="card-body px-2 pt-0 pb-2">
              <div class="row">
                <el-form-item label="Payment Date" prop="transaction_date">
                  <el-date-picker
                    v-model="paymentForm.transaction_date"
                    type="date"
                    label="Pick a date"
                    placeholder="Pick a date"
                    style="width: 100%"
                  />
                </el-form-item>
                <div class="col-md-6">
                  <el-form-item label="Amount Received." prop="paid_amount">
                    <el-input
                      placeholder="0.00"
                      type="number"
                      v-model="paymentForm.paid_amount"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Allocated amount."
                    prop="allocated_amount"
                  >
                    <el-input
                      placeholder="0.00"
                      type="number"
                      v-model="allocated_amount"
                      disabled
                    />
                  </el-form-item>
                </div>
                <el-form-item
                  label-position="left"
                  label="Record this transaction as pre-payment"
                  prop="prepayment"
                >
                  <el-radio-group v-model="paymentForm.prepayment">
                    <el-radio border label="1">Yes</el-radio>
                    <el-radio border label="0"
                      >No, Allocate to invoices</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
                <div
                  class="ms-4"
                  v-if="paymentForm.prepayment == 1"
                  style="width: 90%; background-color: #f0c9c9"
                >
                  <el-form-item
                    v-if="paymentForm.prepayment == 1"
                    label="Select the patient account the payment belongs to."
                    prop="patient_id"
                  >
                    <el-select-v2
                      v-model="paymentForm.patient_id"
                      filterable
                      remote
                      :options="patients"
                      placeholder="Select the patient account the payment belongs to."
                      style="width: 100%"
                      :loading="isLoading"
                      :remote-method="loadPatients"
                    >
                    </el-select-v2>
                  </el-form-item>
                </div>
                <div
                  class="ms-4"
                  v-if="paymentForm.prepayment == 0"
                  style="width: 90%; background-color: #f0c9c9"
                >
                  <div class="col-md-12">
                    <el-form-item
                      label="Filter Invoices By Date Range "
                      prop="search_date"
                    >
                      <el-date-picker
                        v-model="date_range"
                        type="daterange"
                        @change="loadInvoices"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        :shortcuts="shortcuts"
                        :size="size"
                        format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD"
                      />
                    </el-form-item>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Filter Invoices By Insurance "
                        prop="insurance_id"
                      >
                        <el-select-v2
                          v-model="insurance_id"
                          filterable
                          remote
                          clearable
                          :options="insurances"
                          placeholder="Please select insurance"
                          style="width: 100%"
                          :remote-method="loadInsurances"
                          :loading="isLoading"
                          @change="loadInvoices"
                        >
                        </el-select-v2>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Search invoice details"
                        prop="insurance_id"
                      >
                        <el-input
                          v-model="search_invoice"
                          placeholder="Enter search text"
                          class="input-with-select"
                        >
                          <template #append>
                            <el-button @click="loadInvoices" :icon="Search" />
                          </template>
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-12 table_filter">
                    <table class="inv_filter_table">
                      <thead>
                        <th class="">
                          <input type="checkbox" v-model="selectAll" />
                        </th>
                        <th>Invoice No.</th>
                        <th>Patient Name</th>
                        <th>Amount</th>
                        <th>Paid</th>
                        <th>Balance</th>
                      </thead>
                      <tbody>
                        <tr
                          :class="
                            selected_invoices.filter(function (inv) {
                              if (inv.id == item.id) {
                                return inv; // returns length = 1 (object exists in array)
                              }
                            }).length > 0
                              ? 'highlightedRow'
                              : ''
                          "
                          v-for="(item, index) in invoices"
                          :key="item.id + index"
                        >
                          <td>
                            <input
                              type="checkbox"
                              v-model="selected_invoices"
                              :value="item"
                              number
                            />
                          </td>
                          <td>{{ item.invoice_number }}</td>
                          <td>
                            {{
                              item?.patient?.first_name +
                              " " +
                              item?.patient?.last_name
                            }}
                          </td>
                          <td>{{ numberFormat(item.total) }}</td>
                          <td>
                            {{ numberFormat(item.invoice_payments_sum_amount) }}
                          </td>
                          <td>
                            {{
                              numberFormat(
                                item.total - item.invoice_payments_sum_amount
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <el-form-item
                  label="Transaction Reference."
                  prop="transaction_reference"
                >
                  <el-input
                    placeholder="Ref."
                    v-model="paymentForm.transaction_reference"
                  />
                </el-form-item>

                <el-form-item label="Payment Method" prop="payment_mode_id">
                  <el-select
                    v-model="paymentForm.payment_mode_id"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(payment_mode, index) in payment_modes"
                      :key="index"
                      :label="payment_mode.label"
                      size="large"
                      :value="payment_mode.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="Memo/Note." prop="note">
                  <el-input
                    :rows="2"
                    type="textarea"
                    placeholder="Transaction details."
                    v-model="paymentForm.note"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="card--footer">
              <router-link
                :to="{ path: `/accounts/payments` }"
                class="text-secondary"
              >
                <el-button type="default">
                  <i
                    class="fas fa-delete text-white me-1"
                    aria-hidden="true"
                  ></i
                  >cancel
                </el-button></router-link
              >
              <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(paymentFormRef)"
                >Submit</el-button
              >
              <el-button
                v-if="paymentForm.id != null"
                type="danger"
                class="btn mb-0 btn-danger btn-xs null"
                @click="printPaymentPdf"
              >
                print
              </el-button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header pb-0 mb-5">
              <div class="d-flex align-items-center gap-2">
                <i class="fa fa-file-o text-primary text-md opacity-10"></i>
                <h6>Invoice allocation</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 p-3">
                <div
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  v-for="invoice in selected_invoices"
                  :key="invoice"
                >
                  <div class="d-flex align-items-center" style="width: 70%">
                    <div
                      class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center"
                    >
                      <i class="fa fa-file-o text-white opacity-10"></i>
                    </div>

                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">
                        Date:{{ dateTime(invoice.invoice_date) }}<br />
                        Invoice No.{{ invoice.invoice_number }}<br />
                        Balance: KES{{
                          invoice.total - invoice.invoice_payments_sum_amount
                        }}
                      </h6>
                      <span class="text-xs"
                        >{{ invoice.patient?.first_name }}
                        {{ invoice.patient?.last_name }}
                        {{ invoice.patient?.phone }}
                      </span>
                      <span class="text-xs">
                        <el-row class="mt-2">
                          <el-button
                            size="small"
                            type="danger"
                            :icon="Delete"
                            circle
                            @click="removeInvoice(invoice)"
                          />
                        </el-row>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-column ms-3">
                    <div class="mb-1 text-dark text-sm">
                      Distribute payment
                      <el-input
                        style="width: 100%"
                        placeholder="0.00"
                        @keyup="onAllocationAmountChange"
                        v-model="invoice.allocated_amount"
                      />
                    </div>
                    <div class="text-dark text-sm">
                      <el-checkbox
                        class="m-0"
                        style="width: 100%"
                        border
                        :checked="invoice.withholding > 0"
                        v-model="invoice.withholding"
                        label="Deduct withholding tax?"
                        size="large"
                      />
                    </div>
                    <div class="mb-1 text-dark text-sm">
                      Other Deduction
                      <el-input
                        style="width: 100%"
                        placeholder="0.00"
                        v-model="invoice.deduction_value"
                      >
                        <template #append>
                          <el-select
                            v-model="invoice.deduction_type"
                            placeholder="Type"
                            style="width: 115px"
                          >
                            <el-option label="%" value="1" />
                            <el-option label="Fixed" value="2" />
                          </el-select>
                        </template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="payment"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <PrintPayment :payment="paymentForm" />
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import { Search, Delete } from "@element-plus/icons-vue";
import Vue3Html2pdf from "vue3-html2pdf";
import PrintPayment from "./PaymentDetails.vue";
import { reactive, ref, onMounted, computed, watch } from "vue";
import Loader from "../../views/components/Loader.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { fetchPaymentModes } from "../../api/services/services";
import { fetchPatients } from "../../api/services/users";
import {
  fetchPayment,
  createPayment,
  updatePayment,
  fetchInvoices,
} from "../../api/services/accounts";
import { fetchInsurances } from "../../api/services/services";
const paymentFormRef = ref();
const isLoading = ref(false);
const payment_modes = ref([]);
const patients = ref([]);
const invoices = ref([]);
const date_range = ref([]);
const insurance_id = ref("");
const insurances = ref([]);
const allocated_amount = ref(0);
const search_invoice = ref("");
const selected_invoices = ref([]);

import { ElNotification } from "element-plus";
export default {
  components: {
    Loader,
    PrintPayment,
    Vue3Html2pdf,
  },

  setup() {
    allocated_amount.value = ref(0);
    selected_invoices.value.splice(0);
    const onAllocationAmountChange = () => {
      allocated_amount.value = 0;
      selected_invoices.value.map((invoice) => {
        if (invoice.allocated_amount != undefined) {
          allocated_amount.value += parseFloat(invoice.allocated_amount);
        }
      });
    };
    watch(selected_invoices, async (newSelectedInvoices) => {
      allocated_amount.value = 0;
      newSelectedInvoices.map((invoice) => {
        if (invoice.allocated_amount != undefined) {
          allocated_amount.value += parseFloat(invoice.allocated_amount);
        }
      });
    });
    const selectAll = computed({
      get() {
        if (invoices.value != null && invoices.value.length > 0) {
          let allChecked = true;

          for (const invoice of invoices.value) {
            if (!selected_invoices.value.includes(invoice)) {
              allChecked = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }
          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          invoices.value.forEach((invoice) => {
            checked.push(invoice);
          });
        }

        selected_invoices.value = checked;
      },
    });

    const htmlToPdfOptions = {
      margin: 0,
      filename: "payment.pdf",
      image: {
        type: "png",
        quality: 2,
      },
      enableLinks: false,
      html2canvas: {
        scale: 5,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };
    const route = useRoute();
    const router = useRouter();
    const payment_id = route.params?.id;
    const paymentForm = reactive({
      id: payment_id || 0,
      paid_amount: "",
      transaction_reference: "",
      transaction_date: new Date(),
      payment_mode_id: "",
      note: "",
      invoices: null,
      payment_mode: null,
      prepayment: null,
      patient_id: null,
    });

    const rules = reactive({
      paid_amount: [
        {
          required: true,
          message: "Please add the amount paid",
          trigger: ["blur", "change"],
        },
      ],
      prepayment: [
        {
          required: true,
          message: "Please indicate if this is pre-payment transaction",
          trigger: ["blur", "change"],
        },
      ],
      transaction_reference: [
        {
          required: true,
          message: "Please add the transaction reference",
          trigger: ["blur", "change"],
        },
      ],
      transaction_date: [
        {
          required: true,
          message: "Please add the transaction date ",
          trigger: ["blur", "change"],
        },
      ],
      payment_mode_id: [
        {
          required: true,
          message: "Please choose mode of payment",
          trigger: ["blur", "change"],
        },
      ],
    });
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          //isLoading.value = true;
          const formData = new FormData();
          paymentForm.transaction_date = moment(
            paymentForm.transaction_date
          ).format("YYYY-MM-D");
          formData.append("paid_amount", paymentForm.paid_amount);
          formData.append(
            "transaction_reference",
            paymentForm.transaction_reference
          );
          formData.append("transaction_date", paymentForm.transaction_date);
          formData.append("payment_mode_id", paymentForm.payment_mode_id);
          formData.append("note", paymentForm.note);
          formData.append("prepayment", paymentForm.prepayment);
          formData.append("patient_id", paymentForm.patient_id ?? 0);

          const invoice_items = [];

          if (selected_invoices.value != null)
            selected_invoices.value.map((invoice) => {
              invoice_items.push({
                id: invoice.id,
                allocated_amount: invoice.allocated_amount,
                withholding: invoice.withholding,
                deduction_type: invoice.deduction_type,
                deduction_value: invoice.deduction_value,
                patient_id: invoice.patient_id,
              });
            });

          paymentForm.invoices = JSON.stringify(invoice_items);

          formData.append("invoices", paymentForm.invoices);

          if (paymentForm.id > 0) {
            updatePayment(paymentForm)
              .then((data) => {
                isLoading.value = false;

                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  router.push({ path: "/accounts/payments" });
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) => {
                isLoading.value = false;
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                });
              })
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createPayment(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });

                  router.push({ path: "/accounts/payments" });
                } else {
                  if (data.errors) {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                  if (data.message) {
                    ElNotification({
                      title: "Error",
                      message: data.message,
                      type: "error",
                    });
                  }
                }
              })
              .catch((err) => {
                isLoading.value = false;
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                });
              })
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const fetchPaymentData = async () => {
      if (paymentForm.id > 0) {
        isLoading.value = true;
        try {
          const response = await fetchPayment(paymentForm.id);

          paymentForm.paid_amount = response.data.paid_amount;
          paymentForm.transaction_reference =
            response.data.transaction_reference;
          paymentForm.transaction_date = response.data.transaction_date;
          paymentForm.payment_mode_id = String(response.data.payment_mode_id);
          paymentForm.payment_mode = response.data.payment_mode;
          paymentForm.prepayment = String(response.data.prepayment);
          paymentForm.patient_id = String(response.data.patient_id);
          paymentForm.note = response.data.note;
          paymentForm.invoices = response.data?.invoices;

          const selected_invoice_list = [];

          response.data.invoices.map((invoice) => {
            selected_invoice_list.push(invoice);
          });

          const checked = [];
          response.data.invoices.forEach((invoice) => {
            checked.push(invoice);
          });
          selected_invoices.value = checked;
          loadPatients("", 1);
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      }
    };
    const loadInvoices = async () => {
      const entity_params = ref();
      entity_params.value = {
        search_query: search_invoice,
        date_range: date_range,
        insurance_id: insurance_id,
        has_balance: 1,
      };

      isLoading.value = true;
      try {
        const response = await fetchInvoices(entity_params);

        const tempInvoices = [];
        response.data.data.map((invoice) => {
          invoice.deduction_type = "1";
          invoice.deduction_value = "";
          tempInvoices.push(invoice);
        });
        invoices.value = tempInvoices;

        fetchPaymentData();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const loadPatients = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchPatients(entity_params);

        const tempPatients = response.data.data.map((patient) => ({
          value: `${patient.id}`,
          label: `${
            patient.first_name +
            " " +
            patient.middle_name +
            " " +
            patient.last_name
          }`,
        }));
        patients.value = tempPatients;
      } catch (error) {
        console.log(error);
      }
    };
    const loadInsurances = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchInsurances(entity_params);

        const tempInsurances = response.data.data.map((nsurance) => ({
          value: `${nsurance.id}`,
          label: `${nsurance.name}`,
        }));
        insurances.value = tempInsurances;
      } catch (error) {
        console.log(error);
      }
    };
    const loadPaymentModes = async () => {
      try {
        const response = await fetchPaymentModes();
        let tempPaymentModes = [];

        let userPermissions = JSON.parse(localStorage.getItem("permissions"));
        response.data.data.map((unit) => {
          if (unit.admin_only == 1) {
            if (userPermissions.includes("insurance.allocate")) {
              tempPaymentModes.push({
                value: `${unit.id}`,
                label: `${unit.name}`,
              });
            }
          } else {
            tempPaymentModes.push({
              value: `${unit.id}`,
              label: `${unit.name}`,
            });
          }
        });
        payment_modes.value = tempPaymentModes;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
    
      loadPaymentModes();
      loadInvoices("");
      loadInsurances("");
      if (paymentForm.id == 0) {
        loadPatients("", 1);
      }
    });
    const numberFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const removeInvoice = (invoice) => {
      selected_invoices.value = selected_invoices.value.filter(
        (item) => item !== invoice
      );
    };
    return {
      removeInvoice,
      onAllocationAmountChange,
      selected_invoices,
      selectAll,
      search_invoice,
      numberFormat,
      Search,
      Delete,
      allocated_amount,
      loadInsurances,
      date_range,
      insurance_id,
      insurances,
      htmlToPdfOptions,
      fullScreenLoader,
      loadPatients,
      isLoading,
      dateTime,
      payment_modes,
      paymentForm,
      paymentFormRef,
      patients,
      invoices,
      rules,
      submitForm,
      fetchPaymentData,
      loadInvoices,
    };
  },

  methods: {
    printPaymentPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
<style scoped>
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.inv_filter_table {
  font-size: 0.75rem;
  background-color: #eef5f3;
  width: 100%;
}
.table_filter {
  display: inline-block;
  overflow-y: scroll;
  max-height: 200px;
}

.inv_filter_table thead th {
  padding: 5px;
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
}
th {
  background: #eee;
}
.inv_filter_table td {
  padding: 5px;
}
.highlightedRow {
  color: brown;
}
</style>
