<template>
  <div class="modal-overlay">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-medkit text-primary me-2" />Preview
      </div>
      <hr class="horizontal dark m-0" />

      <PreviewPayment :payment="props.payment" />
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>
<script>
import PreviewPayment from "./PaymentDetails.vue";
export default {
  name: "payment details",
  components: {
    PreviewPayment,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
    
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}
.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  max-width: 50%;
  max-height: 70%;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
