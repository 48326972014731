<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <hospitals-table 
          :hospitals="hospitals"
          :refresh="fetchData" 
          :searchQuery="searchTerm"
          v-else />
      </div>
    </div>
  </div>
</template>

<script>
import HospitalsTable from "./components/HospitalTable.vue";
import Loader from "./components/Loader.vue";
import { fetchHospitals } from "../api/services/services";
import { ref  } from "vue";

const isLoading = ref(false);
const hospitals = ref([]);

export default {
  name: "hospitals",
  components: {
    HospitalsTable,
    Loader,
  },
  setup() {
    const fetchData = async (search, page) => {
      
      const entity_params = ref();
      entity_params.value = { search_query: search };

      isLoading.value = true;
      try {
        const response = await fetchHospitals(entity_params, page);
        hospitals.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      fetchData,
      hospitals,
      isLoading,
    };
  },
  mounted() {
    this.fetchData("", 1);
  },
};
</script>
