import {api} from "../../index";

const referral_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=patient.first_name:like;patient.last_name:like;patient.middle_name:like;patient.patient_number:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.patient_id)
        url = url + "&patient_id=" + filter.value.patient_id;

    if (filter.value != undefined && filter.value.doctor_id)
        url = url + "&referred_by=" + filter.value.doctor_id;

    if (filter.value != undefined && filter.value.hospital_id)
        url = url + "&hospital_id=" + filter.value.hospital_id;

    url = url.replace("referrals?&", "referrals?");

    return url;
};

export const fetchReferrals = async (filter, page) => {
    let response = await api
        .get(referral_url("referrals?orderBy=id&sortedBy=desc", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchReferral = async (id) => {
    let response = await api
        .get(`referrals/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);

    return response;
};

export const createReferral = async (postBody) => {
    let response = await api
        .post("referrals", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateReferral = async (body) => {
    let response = await api
        .patch("referrals/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const deleteReferral = async (id) => {
    let response = await api
        .delete("referrals/" + id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const downloadReferralPdf = async (id) => {
    let response = await api
        .get(`download/referral/sheet/${id}`,
            {
                responseType: "blob",
            }
        )
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};