<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header pb-0 mb-5">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-users text-primary text-md opacity-10"></i>
          <h6>Available Roles</h6>
        </div>
        <div class="cta">
          <button
            @click="$router.push(`/roles/form`)"
            v-if="hasPermission('create.role')"
            class="btn mb-0 btn-success btn-xs ms-auto"
          >
            <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
            Role
          </button>
        </div>
      </div>

      <div class="card-body pb-2">
        <section class="card-area">
          <div class="row mb-4" v-for="role in roles" :key="role.id">
            <div class="col-md-2">
              <section class="card-section mt-md-4">
                <div class="role-card">
                  <div class="flip-card">
                    <div class="flip-card__container">
                      <div class="card-front">
                        <div
                          class="card-front__tp"
                          :class="`card-front__tp--${role.name}`"
                        >
                          <i class="fa fa-lock fa-2x" />

                          <h2 class="card-front__heading">
                            {{ ucfirst(role.name) }}
                          </h2>
                        </div>

                        <div class="card-front__bt" v-if="role.locked == 0">
                          <el-row>
                            <el-button
                              class="me-2"
                              @click="$router.push(`/roles/form/${role.id}`)"
                              size="large"
                              circle
                            >
                              <i class="fa fa-edit text-primary"></i>
                            </el-button>
                            <el-button
                              class="me-2"
                              @click="deleteRole(role.id)"
                              size="large"
                              circle
                            >
                              <i class="fa fa-trash text-danger"></i>
                            </el-button>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-md-10">
              <div class="card">
                <div class="card-body">
                  <h6>{{ ucfirst(role.name) }} Permissions</h6>
                  <div class="row">
                    <div
                      class="col-md-3"
                      v-for="permission in dividedRows(role.permissions)"
                      :key="permission.id"
                    >
                      <ol>
                        <li v-for="perm in permission" :key="perm.id">
                          {{ perm.name }}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { h, ref } from "vue";
import { fetchRoles, removeRole } from "../api/services/authentication";
import { ElMessage, ElMessageBox } from "element-plus";
const roles = ref([]);
const isLoading = ref(false);

export default {
  name: "roles",
  setup() {
    const fetchData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchRoles({});
        roles.value = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const ucfirst = (value) => {
      if (!value) return "";
      return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const dividedRows = (permissions) => {
      const columnsPerRow = 10;
      const rows = [];
      for (let i = 0; i < permissions.length; i += columnsPerRow) {
        const row = permissions.slice(i, i + columnsPerRow);
        rows.push(row);
      }

      return rows;
    };
    const deleteRole = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "This role will be permanently deleted. This action cannot be undone Continue?"
        ),

        "Delete Role",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await removeRole(id);

            if (success) {
              ElMessage({
                type: "success",
                message: message,
              });
              fetchData();
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    return {
      deleteRole,
      dividedRows,
      ucfirst,
      roles,
      isLoading,
      fetchData,
    };
  },
  mounted() {
    this.fetchData();
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>
<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
</style>
<style scoped>
.card-front__heading {
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 600;
}

.card-front__text-view {
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.2rem;
}

.card-front__text-view--admin {
  color: #ff62b2;
}

.card-front__text-view--doctor {
  color: #2aaac1;
}

.card-front__text-view--nurse {
  color: #8fb129;
}
.card-front__text-view--receptionist {
  color: #fa7f67;
}

/* Front cover */

.card-front__tp {
  color: #fafbfa;
}

.card-front__icon {
  fill: #fafbfa;
  font-size: 3vw;
  height: 3.25rem;
  margin-top: -0.5rem;
  width: 3.25rem;
}

.main {
  background: linear-gradient(to bottom right, #eee8dd, #e3d9c6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.card-section {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.role-card {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: -0.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.5);
  width: 7em;
}

.card-front {
  background-color: #fafbfa;
}

.card-front__tp {
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem;
  border-radius: 4px;
}

.card-front__tp--admin {
  background: linear-gradient(to bottom, #ff73b9, #ff40a1);
}

.card-front__tp--nurse {
  background: linear-gradient(to bottom, #bacd82, #8fb129);
}

.card-front__tp--doctor {
  background: linear-gradient(to bottom, #47c2d7, #279eb2);
}

.card-front__tp--receptionist {
  background: linear-gradient(to bottom, #fb9b88, #f86647);
}

/* Front card's bottom section */
.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
}
ol {
  list-style: none;
  padding: 1rem 0;
}

ol li:before {
  content: "✓";
  color: green;
}
</style>
