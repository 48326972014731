<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-file-o text-primary text-md opacity-10"></i>
        <h6>Consumables</h6>
      </div>
      <div class="cta">
        <el-button-group>
          <FilterTables
            :show_search_filter="true"
            :show_date_filter="true"
            :show_insurance_filter="true"
            :show_patient_filter="true"
            :show_employer_filter="true"
            date_range_label="Consumable Date"
            search_label="Search invoice records"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />

          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <div class="row">
        <div class="col-md-7 pt-3 list-table">
          <div v-if="consumables_list.length === 0">
            <empty-state-table />
          </div>

          <div class="table-responsive p-0" v-if="consumables_list.length > 0">
            <table class="inv-table table align-items-left mb-0">
              <thead>
                <tr>
                  <th
                    width="5%"
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                  >
                    <input type="checkbox" v-model="selectAll" />
                  </th>
                  <th
                    width="25%"
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                  >
                    Patient details
                  </th>
                  <th
                    width="45%"
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                  >
                    Invoice Details
                  </th>

                  <th
                    width="25%"
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-center"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in consumables_list" :key="index">
                  <td width="5%">
                    <input
                      type="checkbox"
                      v-model="selected"
                      :value="item.id"
                      number
                    />
                  </td>
                  <td width="30%">
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row">
                        <span class="font-weight-bold"
                          >{{ item.patient.name }}
                        </span>
                        <small class="ps-1"></small>
                      </div>
                      <div class="d-flex flex-column">
                        #{{ item.patient.patient_number }}
                      </div>
                      <div class="d-flex flex-column">
                        {{ item.patient.phone }}
                      </div>
                    </div>
                  </td>
                  <td width="45%">
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row">
                        <span class="font-weight-bold">
                          {{ dateTime(item.invoice_date) }}
                        </span>
                        <small class="ps-1">(#{{ item.invoice_number }})</small>
                      </div>
                      <div class="d-flex flex-row flex-space-between">
                        <div class="d-flex flex-column">Amount:</div>
                        <div class="d-flex flex-column">
                          KES {{ item.total }}
                        </div>
                      </div>
                      <div class="d-flex flex-row flex-space-between">
                        <div class="d-flex flex-column">Paid:</div>
                        <div class="d-flex flex-column">
                          KES {{ item.invoice_payments_sum_amount }}
                        </div>
                      </div>
                      <div class="d-flex flex-row flex-space-between">
                        <div class="d-flex flex-column">Balance:</div>
                        <div class="d-flex flex-column">
                          KES
                          {{ item.total - item.invoice_payments_sum_amount }}
                        </div>
                      </div>
                      <div class="d-flex flex-row flex-space-between">
                        <div class="d-flex flex-column">Consumables Total:</div>
                        <div class="d-flex flex-column">
                          KES {{ numberFormat(item.consumableTotal)}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td width="20%" valign="middle" align="center">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      @click="showConsumables(item)"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer d-flex justify-content-center mb-3">
            <pagination
              :totalPages="Pagination.total_pages"
              :perPage="Pagination.per_page"
              :currentPage="Pagination.current_page"
              @pagechanged="onPageChange"
            />
          </div>
        </div>

        <div class="col-md-5 pt-3" v-if="show_consumables">
          <div
            class="table-responsive p-0"
            v-if="invoice?.consumable_products?.length > 0"
          >
            <table class="inv-table table align-items-left mb-0">
              <thead>
                <tr>
                  <th
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                  >
                    Date
                  </th>

                  <th
                    class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                  >
                    Consumable Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in invoice?.consumable_products"
                  :key="index"
                >
                  <td width="20%">
                    {{ dateTime(row.pivot.created_at) }}<br />
                    {{ dateYear(row.pivot.created_at) }}
                  </td>
                  <td width="80%">
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold"> {{ row.name }} </span>
                          <small class="ps-1">Product </small>
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          Qnty: {{ numberFormat(row.pivot.quantity) }}
                        </div>
                        <div class="d-flex flex-column ps-3">
                          @cost:{{ numberFormat(row.pivot.cost) }}
                        </div>
                        <div class="d-flex flex-column ps-3">
                          Total:{{
                            numberFormat(row.pivot.quantity * row.pivot.cost)
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr
                  v-for="(srvrow, index) in invoice?.consumable_services"
                  :key="index"
                >
                  <td width="20%">
                    {{ dateTime(srvrow.pivot.created_at) }}<br />
                    {{ dateYear(srvrow.pivot.created_at) }}
                  </td>
                  <td width="80%">
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">
                            {{ srvrow.item }}
                          </span>
                          <small class="ps-1">Service </small>
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          Qnty: {{ numberFormat(srvrow.pivot.quantity) }}
                        </div>
                        <div class="d-flex flex-column ps-3">
                          @cost:{{ numberFormat(srvrow.pivot.cost) }}
                        </div>
                        <div class="d-flex flex-column ps-3">
                          Total:{{
                            numberFormat(
                              srvrow.pivot.quantity * srvrow.pivot.cost
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "./Pagination.vue";
import FilterTables from "./FilterTables.vue";
import moment from "moment";
import { ElMessage } from "element-plus";
import {
  downloadConsumablesExcel,
  downloadConsumablesPdf,
} from "../../api/services/accounts";
import { ref, reactive, computed } from "vue";
import EmptyStateTable from "./EmptyTableState.vue";
const filter_params = ref({});
const selected = ref([]);
const isLoading = ref(false);
const show_consumables = ref(false);
const consumables_list = [];
export default {
  name: "consumables-table",
  props: {
    consumables: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },

  components: {
    Pagination,
    EmptyStateTable,
    FilterTables,
  },

  setup(props) {
    consumables_list.splice(0);
    if (props?.consumables?.data && props?.consumables?.data?.length > 0) {
    
      props?.consumables?.data?.forEach((item) => {
        const summation = ref();
        summation.value = item.consumable_products.reduce(
          (total, consumable) =>
            total + consumable.pivot.cost * consumable.pivot.quantity,
          0
        );
        summation.value += item.consumable_services.reduce(
          (total, consumable) =>
            total + consumable.pivot.cost * consumable.pivot.quantity,
          0
        );

        item.consumableTotal = summation.value;
        consumables_list.push(item);
      });
    }

    const selectAll = computed({
      get() {
        if (consumables_list && consumables_list?.length > 0) {
          // A users array exists with at least one item
          let allChecked = true;

          for (const invoice of consumables_list) {
            if (!selected.value.includes(invoice.id)) {
              allChecked = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];

        if (value) {
          consumables_list?.forEach((consumable) => {
            checked.push(consumable.id);
          });
        }

        selected.value = checked;
      },
    });

    const total_records = props?.consumables?.total || 0;
    const per_page = props?.consumables?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.consumables?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };

    const invoice = ref();
    const showConsumables = (item) => {
      show_consumables.value = true;
      invoice.value = item;
    };

    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM,");
    };
    const dateYear = (value) => {
      return moment(value).format("YYYY");
    };
    const numberFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const downloadPdf = async () => {
      filter_params.value.has_consumables = true;
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadConsumablesPdf(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "consumables_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const downloadExcel = async () => {
      filter_params.value.has_consumables = true;
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadConsumablesExcel(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "invoice_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      consumables_list,
      show_consumables,
      invoice,
      selectAll,
      selected,
      showConsumables,
      filter_params,
      _filterCallBack,
      onPageChange,
      Pagination,
      dateTime,
      dateYear,
      numberFormat,
      downloadExcel,
      downloadPdf,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
    printConsumablePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.flex-space-between {
  justify-content: space-between;
}

.inv-table {
  white-space: normal;
}
tbody {
  display: block;
  height: 70vh;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.card-body {
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
</style>
