<template>
  <div
    v-show="this.$store.state.layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="
        this.$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'
      "
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
    <configurator
      :toggle="toggleConfigurator"
      :class="[
        this.$store.state.showConfig ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
  </main>
</template>
<script>
import Sidenav from "./Main/Sidenav";
import Configurator from "@/Main/Configurator.vue";
import Navbar from "@/Main/Navbars/Navbar.vue";
import AppFooter from "@/Main/Footer.vue";
import { mapMutations } from "vuex";
import { ElNotification } from "element-plus";
export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  sockets: {
    connect: function () {
      console.log("socket connected");
    },
    "mclinic-database-new-mpesa-payment": function (data) {
      //show notification only if payment belongs to the currently viewed organization
      const current_organization = JSON.parse(
        localStorage.getItem("organization")
      );
      if (data.payload.organization_id == current_organization.id) {
        ElNotification({
          title: "New Mpesa Payment",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" +
            data.payload.transaction_reference +
            "</strong> Confirmed. Ksh." +
            data.payload.paid_amount +
            " receieved.<br>" +
            data.payload.note,
          type: "success",
        });
      }
    },
  },
  methods: {

    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && !this.$store.state.darkMode,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && this.$store.state.darkMode,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {

    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
