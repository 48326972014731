import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Patients from "../views/Patients.vue";
import Invoices from "../views/Invoices.vue";
import InvoiceForm from "../components/Modals/InvoiceForm.vue";
import Payments from "../views/Payments.vue";
import PaymentForm from "../components/Modals/PaymentForm.vue";
import Appointments from "../views/Appointments.vue";
import PatientDetails from "../views/PatientDetails.vue";
import PatientStatement from "../views/PatientStatement.vue";
import Roles from "../views/Roles.vue";
import RolesForm from "../views/RolesForm.vue";
import Users from "../views/Users.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/SignUp.vue";
import Settings from "../views/Settings.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Signin from "../views/SignIn.vue";
import Welcome from "../views/Welcome.vue";
import Landing from "../views/Landing.vue";
import AppointmentSummary from "../views/AppointmentSummary.vue";
import Hospitals from "../views/Hospitals.vue";
import Services from "../views/Services.vue";
import Employers from "../views/Employers.vue";
import Allergies from "../views/Allergies.vue";
import Insurances from "../views/Insurances.vue";
import Categories from "../views/Categories.vue";
import Brands from "../views/Brands.vue";
import Units from "../views/Units.vue";
import ConsumablesReport from "../views/Consumables.vue";
import DoctorsActivity from "../views/DoctorsActivity.vue";
import InsuranceSummary from "../views/InsuranceSummary.vue";
import Products from "../views/Products.vue";
import ProductForm from "../components/Modals/ProductForm.vue";
import ConsumablesForm from "../components/Modals/ConsumablesForm.vue";
import AppointmentConsumablesForm from "../components/Modals/AppointmentConsumablesForm.vue";
import ResetPassword from "../views/ResetPassword.vue";
import EmailVerification from "../views/EmailVerification.vue";
import Referrals from "../views/Referrals.vue";
import Leaves from "../views/Leaves.vue";
import ReferralForm from "../components/Modals/ReferralForm.vue";
import LeaveForm from "../components/Modals/LeaveForm.vue";
import Templates from "../views/Templates.vue";
import TemplateForm from "../components/Modals/TemplateForm.vue";
import ServicesActivity from "@/views/ServicesActivity.vue";
const routes = [{
        path: "/:pathMatch(.*)*",
        name: "Landing",
        component: Landing,
    },

    {
        path: "/",
        name: "Signin",
        component: Signin,
        meta: { guest: true },
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
        meta: { guest: true },
    },
    {
        path: "/auth/reset-password/:id",
        name: "Reset Password",
        component: ResetPassword,
        meta: { guest: true },
    },
    {
        path: "/auth/email-verificication/:id",
        name: "Email Verification",
        component: EmailVerification,
        meta: { guest: true },
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
        meta: { guest: true },
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
        meta: { guest: true },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: { guest: true },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
    },

    {
        path: "/patients",
        name: "Patients",
        component: Patients,
        meta: { requiresAuth: true },
    },
    {
        path: "/appointment/:id/summary",
        name: "Appointment Summary",
        component: AppointmentSummary,
        meta: { requiresAuth: true },
    },
    {
        path: "/patient/:id",
        name: "Patient Details",
        component: PatientDetails,
        meta: { requiresAuth: true },
    },
    {
        path: "/patient/statement/:id",
        name: "Patient Statement",
        component: PatientStatement,
        meta: { requiresAuth: true },
    },
    {
        path: "/appointments",
        name: "Appointments",
        component: Appointments,
        meta: { requiresAuth: true },
    },
    {
        path: "/roles",
        name: "Roles",
        component: Roles,
        meta: { requiresAuth: true },
    },
    {
        path: "/roles/form/:id",
        name: "Edit Role",
        component: RolesForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/roles/form",
        name: "Add Role",
        component: RolesForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings/hospitals",
        name: "Hospitals",
        component: Hospitals,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings/services",
        name: "Services",
        component: Services,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings/allergies",
        name: "Allergies",
        component: Allergies,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings/insurances",
        name: "Insurances",
        component: Insurances,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings/employers",
        name: "Employers",
        component: Employers,
        meta: { requiresAuth: true },
    },

    {
        path: "/inventory/categories",
        name: "Categories",
        component: Categories,
        meta: { requiresAuth: true },
    },
    {
        path: "/inventory/brands",
        name: "Brands",
        component: Brands,
        meta: { requiresAuth: true },
    },
    {
        path: "/inventory/units",
        name: "units",
        component: Units,
        meta: { requiresAuth: true },
    },
    {
        path: "/inventory/products",
        name: "products",
        component: Products,
        meta: { requiresAuth: true },
    },
    {
        path: "/inventory/product/form",
        name: "Product Form",
        component: ProductForm,
        meta: { requiresAuth: true },
    },

    {
        path: "/inventory/product/form/:id",
        name: "Product Edit Form",
        component: ProductForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings/services/consumables/:id",
        name: "Manage Service Consumables",
        component: ConsumablesForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/templates",
        name: "Templates",
        component: Templates,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/new/template",
        name: "Template Form",
        component: TemplateForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/edit/template/:id",
        name: "Edit Template",
        component: TemplateForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/referrals",
        name: "Referrals",
        component: Referrals,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/new/referral",
        name: "Referral Form",
        component: ReferralForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/edit/referral/:id",
        name: "Edit Referral",
        component: ReferralForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/leaves",
        name: "Leaves",
        component: Leaves,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/new/leave",
        name: "Leaves Form",
        component: LeaveForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/hr/edit/leave/:id",
        name: "Edit Leave",
        component: LeaveForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/reports/consumables",
        name: "Consumables",
        component: ConsumablesReport,
        meta: { requiresAuth: true },
    },
    {
        path: "/reports/doctors/activity",
        name: "Doctor's Activity",
        component: DoctorsActivity,
        meta: { requiresAuth: true },
    },
    {
        path: "/reports/services/summary",
        name: "Services Summary",
        component: ServicesActivity,
        meta: { requiresAuth: true },
    },
    {
        path: "/reports/insurances/summary/",
        name: "Insurance Summary",
        component: InsuranceSummary,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/invoices",
        name: "Invoices",
        component: Invoices,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/invoices/insurance/:insurance_id",
        name: "Insurance Invoices",
        component: Invoices,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/invoices/doctor/:doctor_id",
        name: "Doctor's Invoices",
        component: Invoices,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/invoice/form/:id",
        name: "Invoice Edit Form",
        component: InvoiceForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/invoice/form",
        name: "Invoice Form",
        component: InvoiceForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/appointment/consumables/:id",
        name: "Manage Appointment Consumables",
        component: AppointmentConsumablesForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/payments",
        name: "Payments",
        component: Payments,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/payment/form",
        name: "Payment Form",
        component: PaymentForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/accounts/payment/form/:id",
        name: "Payment Edit Form",
        component: PaymentForm,
        meta: { requiresAuth: true },
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings",
        name: "Business Settings",
        component: Settings,
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (
            localStorage.getItem("isAuthenticated") &&
            localStorage.getItem("token")
        ) {
            next();
        } else {
            next("/");
        }
    } else {
        next();
    }
});
export default router;