<template>
  <div class="card" @click.stop>
    <div class="card-header">
      <i class="fa fa-product-o text-primary me-2" />New Product
    </div>
    <hr class="horizontal dark m-0" />

    <el-form
      ref="productFormRef"
      :model="productForm"
      :rules="rules"
      label-width="120px"
      size=""
      status-icon
      :scroll-to-error="true"
      label-position="top"
      :hide-required-asterisk="true"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <el-form-item label="Product Name" prop="name">
                <el-input v-model="productForm.name" />
              </el-form-item>
            </div>

            <div class="mb-3">
              <el-form-item label="Description" prop="description">
                <el-input v-model="productForm.description" type="textarea" />
              </el-form-item>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <el-form-item label="Category" prop="category_id">
                <el-tree-select
                  v-model="productForm.category_id"
                  :render-after-expand="false"
                  filterable
                  check-strictly
                  :data="categories"
                  placeholder="Please select a category"
                  style="width: 100%"
                />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-form-item label="Brand" prop="brand_id">
                <el-select-v2
                  v-model="productForm.brand_id"
                  filterable
                  :options="brands"
                  placeholder="Please select a brand"
                  style="width: 100%"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <h5>Stock details</h5>
          <hr />
          <div class="col-md-6">
            <div class="mb-3">
              <el-form-item label="Quantity" prop="purchase_stock">
                <el-input v-model="productForm.purchase_stock" type="number" />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-form-item label="Buying Price" prop="buying_price">
                <el-input v-model="productForm.buying_price" type="number" />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-form-item label="Discount Type" prop="discount_type">
                <el-switch
                  v-model="productForm.discount_type"
                  class="ml-2"
                  width="200"
                  inline-prompt
                  active-text="percentage"
                  inactive-text="Fixed"
                />
              </el-form-item>
            </div>
            <div class="mb-3" v-if="productForm.discount_type == true">
              <el-form-item
                label="Buying Price Discount (%)"
                prop="purchase_discount_percentage"
              >
                <el-input
                  v-model="productForm.purchase_discount_percentage"
                  type="number"
                />
              </el-form-item>
            </div>

            <div class="mb-3" v-if="productForm.discount_type == false">
              <el-form-item
                label="Buying Price Discount"
                prop="purchase_discount"
              >
                <el-input
                  v-model="productForm.purchase_discount"
                  type="number"
                />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-form-item label="Unit Buying Price" prop="unit_buying_price">
                <el-input
                  v-model="productForm.unit_buying_price"
                  type="number"
                />
              </el-form-item>
            </div>

            <div class="mb-3">
              <el-form-item label="Re-order level" prop="reorder_level">
                <el-input v-model="productForm.reorder_level" type="number" />
              </el-form-item>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <el-form-item label="Unit of Measure" prop="unit_id">
                <el-select-v2
                  v-model="productForm.unit_id"
                  filterable
                  :options="units"
                  placeholder="Please select a unit of measure"
                  style="width: 100%"
                />
              </el-form-item>
            </div>

            <div class="mb-3">
              <el-form-item label="Selling Price" prop="buying_price">
                <el-input v-model="productForm.selling_price" type="number" />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-form-item label="Markup Type" prop="markup_type">
                <el-switch
                  v-model="productForm.markup_type"
                  class="ml-2"
                  width="200"
                  inline-prompt
                  active-text="percentage"
                  inactive-text="Fixed"
                />
              </el-form-item>
            </div>

            <div class="mb-3" v-if="productForm.markup_type == true">
              <el-form-item
                label="Selling Price Markup (%)"
                prop="sale_markup_percentage"
              >
                <el-input
                  v-model="productForm.sale_markup_percentage"
                  type="number"
                />
              </el-form-item>
            </div>

            <div class="mb-3" v-if="productForm.markup_type == false">
              <el-form-item label="Selling Price Markup" prop="sale_markup">
                <el-input v-model="productForm.sale_markup" type="number" />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-form-item
                label="Unit Selling Price"
                prop="unit_selling_price"
              >
                <el-input
                  v-model="productForm.unit_selling_price"
                  type="number"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
      <div class="card--footer">
        <button
          @click="$router.push('/inventory/products')"
          class="btn mb-0 btn-danger btn-xs"
        >
          Cancel
        </button>
        <el-button
          type="success"
          :loading="isLoading"
          @click="submitForm(productFormRef)"
          >Submit</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {
  createProduct,
  updateProduct,
  fetchCategoriesTree,
  fetchBrands,
  fetchUnits,
  fetchProduct,
} from "../../api/services/inventory";
import { ElNotification } from "element-plus";
import { useRoute, useRouter } from "vue-router";
const productFormRef = ref();
const isLoading = ref(false);
const categories = ref([]);
const brands = ref([]);
const units = ref([]);
export default {
  name: "add-product",

  watch: {
    productForm: {
      handler: function (newproductForm) {
        if (
          newproductForm.discount_type &&
          newproductForm.purchase_discount_percentage > 0
        ) {
          //if percentage
          newproductForm.purchase_discount =
            newproductForm.buying_price *
            (newproductForm.purchase_discount_percentage / 100);
          newproductForm.unit_buying_price =
            newproductForm.buying_price - newproductForm.purchase_discount;
        } else {
          newproductForm.purchase_discount_percentage = "";
          newproductForm.unit_buying_price =
            newproductForm.buying_price - newproductForm.purchase_discount;
        }

        if (
          newproductForm.markup_type &&
          newproductForm.sale_markup_percentage > 0
        ) {
          //if percentage
          newproductForm.sale_markup =
            newproductForm.selling_price *
            (newproductForm.sale_markup_percentage / 100);
          newproductForm.unit_selling_price =
            parseFloat(newproductForm.selling_price) +
            parseFloat(newproductForm.sale_markup);
        } else {
          newproductForm.sale_markup_percentage = "";
          newproductForm.unit_selling_price =
            parseFloat(newproductForm.selling_price) +
            parseFloat(newproductForm.sale_markup);
        }
      },
      deep: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const product_id = route.params?.id;

    const productForm = reactive({
      id: 0,
      name: "",
      description: "",
      category_id: null,
      brand_id: null,
      unit_id: null,
      discount_type: false,
      purchase_discount_percentage: 0,
      markup_type: false,
      sale_markup_percentage: 0,
      purchase_stock: 0,
      purchase_discount: 0,
      reorder_level: 0,
      sale_markup: 0,
      buying_price: 0,
      selling_price: 0,
      unit_buying_price: 0,
      unit_selling_price: 0,
    });

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Please input product name",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please input a description",
          trigger: ["blur", "change"],
        },
      ],
      unit_id: [
        {
          required: true,
          message: "Please select a unit of measure",
          trigger: ["blur", "change"],
        },
      ],
      purchase_stock: [
        {
          required: true,
          message: "Please add the quantity purchased",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          productForm.purchase_discount_percentage =
            productForm.purchase_discount_percentage || 0;
          productForm.sale_markup_percentage =
            productForm.sale_markup_percentage || 0;
          productForm.category_id = productForm.category_id || 0;

          productForm.discount_type = productForm.discount_type
            ? "fixed"
            : "percent";
          productForm.markup_type = productForm.markup_type
            ? "fixed"
            : "percent";

          formData.append("name", productForm.name);
          formData.append("description", productForm.description);
          formData.append("category_id", productForm.category_id || 0);

          productForm.brand_id = Number(productForm.brand_id);
          formData.append("brand_id", productForm.brand_id);
          formData.append(
            "unit_id",
            productForm.unit_id != null ? productForm.unit_id : 0
          );

          formData.append("purchase_stock", productForm.purchase_stock);

          formData.append("buying_price", productForm.buying_price);
          formData.append("discount_type", productForm.discount_type);
          formData.append("reorder_level", productForm.reorder_level);
          formData.append(
            "purchase_discount",
            productForm.purchase_discount ?? 0
          );
          formData.append(
            "purchase_discount_percentage",
            productForm.purchase_discount_percentage
          );
          formData.append("unit_buying_price", productForm.unit_buying_price);

          formData.append("selling_price", productForm.selling_price);
          formData.append("markup_type", productForm.markup_type);
          formData.append("sale_markup", productForm.sale_markup || 0);
          formData.append(
            "sale_markup_percentage",
            productForm.sale_markup_percentage
          );
          formData.append("unit_selling_price", productForm.unit_selling_price);

          if (productForm.id > 0) {
            updateProduct(productForm)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
                router.push("/inventory/products");
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createProduct(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
                router.push("/inventory/products");
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const fetchAttributes = async () => {
      isLoading.value = true;
      try {
        const cats_response = await fetchCategoriesTree();

        categories.value = cats_response.data;

        const brands_response = await fetchBrands();
        const tempBrands = brands_response.data.data.map((brand) => ({
          value: `${brand.id}`,
          label: `${brand.name}`,
        }));
        brands.value = tempBrands;

        const units_response = await fetchUnits();
        const tempUnits = units_response.data.data.map((unit) => ({
          value: `${unit.id}`,
          label: `${unit.name}`,
        }));
        units.value = tempUnits;

        fetchProductData();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchProductData = async () => {
      if (product_id > 0) {
        isLoading.value = true;
        try {
          const response = await fetchProduct(product_id);

          productForm.id = response.data?.id;
          productForm.name = response.data?.name;
          productForm.description = response.data?.description;

          productForm.category_id = response.data?.category?.id;
          productForm.unit_id = String(response.data.unit?.id || null);

          productForm.brand_id = response.data.brand
            ? String(response.data.brand?.id)
            : null;

          productForm.discount_type =
            response.data?.activestock?.discount_type == "fixed";
          productForm.purchase_discount_percentage =
            response.data?.activestock?.purchase_discount_percentage || 0;
          productForm.markup_type =
            response.data?.activestock?.markup_type == "fixed";
          productForm.sale_markup_percentage =
            response.data?.activestock?.sale_markup_percentage;
          productForm.purchase_stock =
            response.data?.activestock?.purchase_stock;
          productForm.purchase_discount =
            response.data?.activestock?.purchase_discount || 0;
          productForm.sale_markup = response.data?.activestock?.sale_markup;
          productForm.buying_price = response.data?.activestock?.buying_price;
          productForm.selling_price = response.data?.activestock?.selling_price;
          productForm.unit_buying_price =
            response.data?.activestock?.unit_buying_price;
          productForm.unit_selling_price =
            response.data?.activestock?.unit_selling_price;
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      }
    };
    return {
      submitForm,
      rules,
      productFormRef,
      fetchAttributes,
      productForm,
      isLoading,
      categories,
      brands,
      units,
      props,
      fetchProductData,
    };
  },
  mounted() {
    this.fetchAttributes();
  },
};
</script>
<style scoped>
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 10rem 1.2rem 1.2rem !important;
}

input {
  border-radius: 6px;
}
</style>
