import { api } from "../..";
export const fetchStatistics = async () => {
  let response = await api
    .get(`summary/statistics`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchMonthlyAppointments = async () => {
  let response = await api
    .get(`summary/monthly/appointments`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchDoctorsAppointments = async () => {
  let response = await api
    .get(`summary/doctors/appointments`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchPaymentSummary = async () => {
  let response = await api
    .get(`summary/payments`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchDebtors = async () => {
  let response = await api
    .get(`summary/debtors`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};




export const fetchPatientStatement = async (id) => {
  let response = await api
    .get(`patient/statement/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const downloadPatientStatementPdf = async (id) => {
  let response = await api
    .get(`patient/statement/pdf/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
