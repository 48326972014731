<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <products-table 
          :products="products"
          :refresh="fetchData" 
          :searchQuery="searchTerm"
          v-else />
      </div>
    </div>
  </div>
</template>

<script>
import ProductsTable from "./components/ProductTable.vue";
import Loader from "./components/Loader.vue";
import { fetchProducts } from "../api/services/inventory";
import { ElLoading } from "element-plus";
import { ref, onMounted } from "vue";

const isLoading = ref(false);
const products = ref([]);
const searchTerm = ref([]);

export default {
  name: "products",
  components: {
    ProductsTable,
    Loader,
  },
  setup() {
    const fetchData = async (search, page) => {
      const entity_params = ref();
      entity_params.value = { search_query: search };
      isLoading.value = true;
      try {
        const response = await fetchProducts(entity_params, page);
        products.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };
    onMounted(() => {
      const entity_params = ref();
      fetchData(entity_params, 1);
    });
    return {
      fullScreenLoader,
      fetchData,
      products,
      isLoading,
      searchTerm
    };
  },
 
};
</script>
