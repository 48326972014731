<template>
  <argon-pagination>
    <argon-pagination-item @click="onClickFirstPage" first />

    <argon-pagination-item @click="onClickPreviousPage" prev />

    <!-- Visible Buttons Start -->

    <div v-for="page in pages" :key="page">
      <argon-pagination-item
        @click="onClickPage(page.name)"
        :class="{
          active: isPageActive(page.name),
          selected: isPageActive(page.name),
        }"
        :label="page.name"
      />
    </div>

    <!-- Visible Buttons End -->
    <argon-pagination-item @click="onClickNextPage" next />

    <argon-pagination-item @click="onClickLastPage" last />
  </argon-pagination>
</template>

<script>
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
export default {
  components: { ArgonPagination, ArgonPaginationItem },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        if (this.totalPages - this.maxVisibleButtons < 0) return 1;
        else return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },

    pages() {
      const range = [];
      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
        });
      }

      return range;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
