import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import moment from "moment";
import VueTelInput from "vue3-tel-input";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue3-tel-input/dist/vue3-tel-input.css";
// Importing the global css file
import "@/assets/css/global.css";
import VueSocketIO from "vue-3-socket.io";
import SocketIO from "socket.io-client";
import VueBarcode from "@chenfengyuan/vue-barcode";
import { QuillEditor } from "@vueup/vue-quill";
import "quill/dist/quill.core.css"; // Required
import "@vueup/vue-quill/dist/vue-quill.snow.css";
const options = { transports: ["websocket"] }; //Options object to pass into SocketIO
const appInstance = createApp(App);
appInstance.use(
  new VueSocketIO({
    debug: true,
    connection: SocketIO(process.env.VUE_APP_PUBLISHER_URL, options), //options object is Optional
  })
);

appInstance.config.errorHandler = () => null;
appInstance.config.warnHandler = () => null;
appInstance.config.globalProperties.$moment = moment;
appInstance.use(VueTelInput);
appInstance.use(VueDatePicker);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus);
appInstance.component(VueBarcode.name, VueBarcode);
appInstance.component("QuillEditor", QuillEditor);
appInstance.mount("#app");

