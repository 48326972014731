<template>
  <div class="card card-nav-tabs">
    <div class="card-header card-header-warning">Monthly Payment Summary</div>
    <div class="card-body">
      <Doughnut
        id="payments_summary-chart-id"
        :options="chartOptions"
        :data="chartData"
        v-if="!isLoading"
      />
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { fetchPaymentSummary } from "../../api/services/reports";
import { onMounted, ref, reactive } from "vue";
ChartJS.register(Title, Tooltip, Legend, ArcElement);
const isLoading = ref(false);

const chartOptions = {
  responsive: true,
};
export default {
  name: "PaymentSummary",
  components: { Doughnut },

  setup() {
    const chartData = reactive({
      labels: [],
      datasets: [],
    });

    const fetchPaymentSummaryData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchPaymentSummary();
        console.log(response);
        chartData.labels = Object.values(response.names);
        chartData.datasets = [
          {
            backgroundColor: Object.values(response.colors),
            data: Object.values(response.values),
          },
        ];
        console.log(chartData);
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      fetchPaymentSummaryData();
    });
    return {
      isLoading,
      chartData,
      chartOptions,
    };
  },
};
</script>
<style scoped>
.card-body {
  max-height: 50vh;
  max-width: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
