<template>
  <div
      v-show="this.$store.state.layout === 'default'"
      class="min-height-300 position-absolute w-100"
      :style="{ backgroundColor: current_organization.navbar_bg }"
    />

  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs z-index-1 border-radius-xl"
    :class="`${
      this.$store.state.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    } 
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebarType}`"
    id="sidenav-main"
  >
  
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/dashboard">
        <!-- "
            this.$store.state.darkMode ||
            this.$store.state.sidebarType === 'bg-default'
              ? logoWhite
              : logo
          " -->

        <img
          :src="
            current_organization.logo != null
              ? current_organization.logo.path
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <div class="author align-items-center p-3">
      <div v-if="showAddOrganizationModal" class="modal-overlay">
        <el-form
          ref="addClinicFormRef"
          :model="addClinicForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card" @click.stop>
            <div class="card-header">
              <i class="fa fa-user-md text-primary me-2" />Add Clinic
            </div>
            <hr class="horizontal dark m-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <el-form-item label="Clinic Name" prop="business_name">
                    <el-input v-model="addClinicForm.business_name" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    label="Clinic Email Address"
                    prop="business_email"
                  >
                    <el-input v-model="addClinicForm.business_email" />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Clinic Address" prop="business_address">
                    <el-input
                      v-model="addClinicForm.business_address"
                      :rows="2"
                      type="textarea"
                      placeholder="P.O Box. 0000, Nairobi,Kenya"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="el-form-item__label">Clinic Phone Number</label>
                  <vue-tel-input
                    v-model="addClinicForm.business_phone"
                    :value="addClinicForm.business_phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                  ></vue-tel-input>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <el-button
                type="primary"
                class="px-5 d-block my-3 mx-auto"
                :loading="isLoading"
                @click="submitForm(addClinicFormRef)"
                >Save</el-button
              >
            </div>
          </div>
        </el-form>

        <div class="close bg-danger" @click="showAddOrganizationModal = false">
          <i class="fa fa-times" />
        </div>
      </div>
    </div>
    <div class="author align-items-center p-3">
      <div v-if="showModal" class="modal-overlay">
        <div class="card" @click.stop>
          <div class="card-header">
            <i class="fa fa-user-md text-primary me-2" />My Clinics
          </div>
          <hr class="horizontal dark m-0" />

          <div class="card-body">
            <ul class="list-group">
              <li
                v-for="(organization, index) in organizations"
                :key="organization.value + index"
                class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center"
                  >
                    <i class="ni ni-world text-white opacity-10"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-dark text-sm">
                      {{ organization.name }}
                    </h6>
                    <span class="text-xs">{{ organization.phone }}</span>
                  </div>
                </div>
                <div class="d-flex" @click="switchOrganization(organization)">
                  <button
                    class="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"
                  >
                    <i class="ni ni-bold-right" aria-hidden="true"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <el-button type="primary" @click="addOrganization()"
              >Add New Branch or Clinic</el-button
            >
          </div>
        </div>
        <div class="close bg-danger" @click="showModal = false">
          <i class="fa fa-times" />
        </div>
      </div>

      <a class="text-primary cursor-pointer" @click="showSwitchOrganization()">
        <div class="name">
          <span
            ><i class="fa fa-toggle-on pe-1" aria-hidden="true"></i
            >{{ current_organization.name }}

            <el-button class="ms-2" size="large" circle>
              <i class="fa fa-caret-down fa-lg text-primary"></i>
            </el-button>
          </span>
        </div>
      </a>
    </div>

    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/stetho-2.jpg";
import logoWhite from "@/assets/img/logo-ct.png";
import { reactive, ref } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { addclinic } from "../../api/services/authentication";
const showModal = ref(false);
const showAddOrganizationModal = ref(false);
const organizations = ref([]);
const current_organization = ref({});
const addClinicFormRef = ref();
const isLoading = ref(false);
const phoneBindingProps = {
  mode: "international",
  styleClasses: "has-default",
  defaultCountry: "KE",
  onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
  inputOptions: {
    showDialCode: false,
    placeholder: "Mobile Number",
    name: "phone",
  },
};
export default {
  name: "index",
  components: {
    SidenavList,
  },

  props: ["custom_class", "layout"],

  setup() {
    const router = useRouter();
    const orgs = JSON.parse(localStorage.getItem("organizations"));
    const roles = JSON.parse(localStorage.getItem("roles"));
    const permissionlist = JSON.parse(localStorage.getItem("permissionlist"));
    current_organization.value = JSON.parse(
      localStorage.getItem("organization")
    );

    organizations.value = orgs;

    const showSwitchOrganization = () => {
      showModal.value = true;
    };

    const switchOrganization = (org) => {
      localStorage.setItem("organization", JSON.stringify(org));
      localStorage.setItem("role", roles[org.id]);
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissionlist[org.id])
      );

      current_organization.value = org;
      showModal.value = false;
      router.push({ path: "/dashboard" });
    };

    const addOrganization = () => {
      showModal.value = false;
      showAddOrganizationModal.value = true;
    };
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          const formData = new FormData();
          formData.append("business_name", addClinicForm.business_name);
          formData.append("business_phone", addClinicForm.business_phone);
          formData.append("business_email", addClinicForm.business_email);
          formData.append("business_address", addClinicForm.business_address);
          formData.append("withholding_rate", "5");
          formData.append("vat_rate", "16");

          addclinic(formData)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem(
                  "organizations",
                  JSON.stringify(response.data.data.organizations)
                );
                localStorage.setItem(
                  "organization",
                  JSON.stringify(response.data.data.organization)
                );
                localStorage.setItem(
                  "permissionlist",
                  JSON.stringify(response.data.data.permissions)
                );
                localStorage.setItem(
                  "permissions",
                  JSON.stringify(
                    response.data.data.permissions[
                      response.data.data.organization.id
                    ]
                  )
                );
                localStorage.setItem(
                  "role",
                  response.data.data.roles[response.data.data.organization.id]
                );
                localStorage.setItem(
                  "roles",
                  JSON.stringify(response.data.data.roles)
                );
                showAddOrganizationModal.value = false;
                current_organization.value = response.data.data.organization;
                router.replace({ path: "/dashboard" });
                ElMessage({
                  message: response.data.message,
                  type: "success",
                });
              } else if (response.status == 422) {
                let error = Object.values(response.data.errors);
                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) => {
              console.log(err);
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              });
            })
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const addClinicForm = reactive({
      business_name: "",
      business_phone: "",
      business_email: "",
      business_address: "",
    });
    const checkMobileNumber = (event) => {
      if (event.valid) {
        addClinicForm.business_phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        addClinicForm.business_phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };
    const rules = reactive({
      business_name: [
        {
          required: true,
          message: "Please input the business name",
          trigger: ["blur", "change"],
        },
      ],
      business_phone: [
        {
          required: true,
          message: "Please input the business phone number",
          trigger: ["blur", "change"],
        },
      ],

      business_address: [
        {
          required: true,
          message: "Please input the business address",
          trigger: ["blur", "change"],
        },
      ],
      business_email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
    });
    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return {
      isLoading,
      resetForm,
      submitForm,
      rules,
      addClinicForm,
      addClinicFormRef,
      phoneBindingProps,
      checkMobileNumber,
      addOrganization,
      showAddOrganizationModal,
      showSwitchOrganization,
      switchOrganization,
      current_organization,
      showModal,
      organizations,
      logo,
      logoWhite,
    };
  },
};
</script>
<style scoped>
img {
  max-width: 100%;
  max-height: 6rem !important;
}
.sidenav-header {
  height: 7.875rem !important;
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 450px;
  height: 450px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}

.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
