<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <categories-table
          :categories="categories"
          :refresh="fetchData"
          :searchQuery="searchTerm"
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesTable from "./components/CategoryTable.vue";
import Loader from "./components/Loader.vue";
import { fetchCategoriesTree } from "../api/services/inventory";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const searchTerm = ref([]);

export default {
  name: "categories",
  components: {
    CategoriesTable,
    Loader,
  },
  setup() {
    const categories = [];
    const fetchData = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try {
        const response = await fetchCategoriesTree(search, page);
        categories.splice(0);
        generateCategoryList(response.data, "");
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const generateCategoryList = (cats, indent) => {
      cats.forEach((category) => {
        var _id = category.value;
        var _name = category.label;
        var _display_name = indent + category.label;
        var _description = category.description;
        var _parent_id = category.parent_id;
        categories.push({
          name: _name,
          parent_id: _parent_id,
          display_name: _display_name,
          description: _description,
          id: _id,
        });
        if (category.children !== undefined) {
          generateCategoryList(
            category.children,
            _display_name + '<span class="text-success"> ● </span> '
          );
        }
      });
    };
    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      isLoading,
      searchTerm,
      categories,
      generateCategoryList,
    };
  },
  mounted() {
    this.fetchData("", 1);
  },
};
</script>
