<template>
  <div v-if="isLoading"><Loader /></div>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="
      this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''
    "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>

        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        </ul>
        <a
          class="text-white cursor-pointer"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i
            class="fa fa-user"
            :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-2'"
          ></i
          ><b class="d-sm-inline nav-link font-weight-bold text-white">
            Hi {{ name }},
          </b>
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </a>

        <ul
          class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
          aria-labelledby="dropdownMenuButton"
        >
          <li class="p-2">
            <router-link to="/profile" class="d-sm-inline d-none"
              >Profile</router-link
            >
          </li>
          <li class="p-2">
            <span class="d-sm-inline d-none" @click="signOut()">Sign Out</span>
          </li>
        </ul>
      </div>
    </div> 
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import { ElNotification, ElMessage } from "element-plus";
import { ArrowDown } from "@element-plus/icons-vue";
import { logout } from "../../api/services/authentication";
import Loader from "../../components/Loader.vue";
import { ref } from "vue";
const isLoading = ref(false);
export default {
  name: "navbar",
  components: {
    Breadcrumbs,
    ArrowDown,
    Loader,
  },
  data() {
    return {
      showMenu: false,
      name: "",
    };
  },

  props: ["minNav", "textWhite"],
  created() {
    this.name = localStorage.getItem("profileName");
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    signOut() {
      logout()
        .then((response) => {
          if (response.status == 200) {
            localStorage.clear();
            this.$router.replace({
              path: "/signin",
            });
            ElMessage({
              message: response.data.message,
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          isLoading.value = false;
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
}
.el-dropdown-link::hover {
  border: none;
  outline: none;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0.05rem;
}

.dropdown-menu.show {
  display: block;
  background-color: aliceblue;
}
.d-sm-inline {
  display: inline !important;
  cursor: pointer;
}
</style>
