<template>
  <div class="p-2 pdf">
    <div class="row">
      <div class="col-md-6">
        <img
          alt="Image placeholder"
          src="@/assets/img/stetho.jpg"
          class="side-glance"
        />
      </div>
      <div class="col-md-6">
        Dr. J. Wanyoike Gichuhi <br />
        Consultant Obstetrician & Gynaecologist<br />
        Senior lecturer, University of Nairobi Dept: Obstetrics & Gynaecology<br />
        Upper Hill Medical Centre, Ralph Bunche Road, 4th Floor Suite 4E<br />
        P.O. Box 333-00202 KNH Nrb. Tel: 2727682 / 2733620 Cell: 0722-522234<br />
      </div>
    </div>
    <hr class="horizontal dark m-3" />
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex d-row">
          <div style="width: 35%">Amount Paid:</div>
          <div class="ps-1">KES{{ props.payment.paid_amount }}</div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 35%">Reference No.:</div>
          <div class="ps-1">{{ props.payment.transaction_reference }}</div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 35%">Transaction Date</div>
          <div class="ps-1">{{ dateTime(props.payment.transaction_date) }}</div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 35%">Mode of Payment</div>
          <div class="ps-1">{{ props.payment.payment_mode.name }}</div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 35%">Note</div>
          <div class="ps-1">{{ props.payment.note }}</div>
        </div>
      </div>
  
      <div class="col-md-6">
        <b class="mb-1 text-dark">Paid to:</b>
        <div v-for="invoice in props.payment.invoices" :key="invoice.id">
          <div class="d-flex d-row">
            <div style="width: 35%">Invoice No.:</div>
            <div class="ps-1">{{ invoice.invoice_number }}</div>
          </div>
          <div class="d-flex d-row">
            <div style="width: 35%">Invoice Total</div>
            <div class="ps-1">KES{{ invoice.total }}</div>
          </div>
          <div class="d-flex d-row">
            <div style="width: 35%">Patient Name.:</div>
            <div class="ps-1">
              {{
                invoice.patient.first_name +
                " " +
                invoice.patient.middle_name +
                " " +
                invoice.patient.last_name
              }}
            </div>
          </div>
          <div class="d-flex d-row">
            <div style="width: 35%">Patient No</div>
            <div class="ps-1">{{ invoice.patient.patient_number }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "Payment details",
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    return {
      props,
      dateTime,
    };
  },
};
</script>
<style scoped>
.pdf {
  font-size: 12px;
}
.side-glance {
  max-height: 100%;
  width: 40%;
  object-fit: cover;
}
</style>
>
