<template>
  <div class="py-1 container-fluid">
    <el-form
        class="el-form-item--label-top"
        ref="leaveFormRef"
        :model="leaveForm"
        :rules="rules"
        status-icon
        :scroll-to-error="true"
        label-position="left"
        :hide-required-asterisk="true"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0 text-dark text-bold">
                  {{ leaveForm?.id != 0 ? "Edit Leave" : "Add Leave" }}
                </p>
              </div>
            </div>
            <div class="card-body leave-body">
              <div class="row">
                <div class="col-md-6 p-3">
                  <el-form-item
                      class="el-form-item-label"
                      label="Choose patient"
                      prop="patient"
                  >
                    <el-select-v2
                        class="w-100"
                        v-model="leaveForm.pt"
                        filterable
                        remote
                        :options="patients"
                        @change="handlePatientSelect"
                        placeholder="Search patient"
                        placement="top"
                        :disabled="leaveForm.id > 0"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          class="card mt-5"
          v-if="leaveForm.selected_appointment_value != null"
      >
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <div
                    class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center"
                >
                  <i class="fa fa-user text-white opacity-10"></i>
                </div>
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">Patient Details</h6>

                  <span class="font-weight-bold"
                  >Name:
                    <span class="text-sm">
                      {{ leaveForm.patient?.first_name || "" }}
                      {{ leaveForm.patient?.middle_name || "" }}
                      {{ patient?.last_name || "" }}
                    </span></span
                  >
                  <span class="font-weight-bold"
                  >Patient No.:
                    <span class="text-sm">{{
                        leaveForm.patient?.patient_number
                      }}</span></span
                  >
                </div>
              </div>
              <hr class="horizontal dark m-3" />
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">
                    Leave Details
                  </h6>
                  <div class="row">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <label prop="doctor" class="el-form-item__label">Issued By</label>
                          <el-form-item>
                            <el-select-v2
                                class="w-100"
                                v-model="leaveForm.practitioner"
                                filterable
                                remote
                                :options="practitioners"
                                @change="handleDocSelect"
                                placeholder="Select Practitioner"
                                placement="top"
                            />
                          </el-form-item>
                        </div>

                        <div class="col-md-12 mb-3">
                          <label class="el-form-item__label">Reason</label>
                          <el-form-item  prop="reason">
                            <el-input
                                v-model="leaveForm.reason"
                                placeholder="Type the reason..."
                                type="textarea" />
                          </el-form-item>
                        </div>

                        <div class="col-md-12 mb-3">
                          <label class="el-form-item__label">Leave Date</label>
                          <el-form-item prop="leave_date">
                            <el-date-picker
                                type="daterange"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                style="width: 100%; padding: 0; margin: 0;"
                                v-model="leaveForm.leave_date"
                            />
                          </el-form-item>
                        </div>

                        <div class="col-md-12 p-3 text-center">
                          <div class="col-md-12 p-3" v-if="showProgress">
                            <el-progress
                                :stroke-width="10"
                                :percentage="download_perc"
                                status="success"
                            />
                          </div>
                          <el-button
                              v-if="leaveForm.selected_appointment_value != null"
                              type="success"
                              class="btn mb-0 btn-success btn-xs null"
                              :loading="isLoading"
                              @click="submitForm(leaveFormRef)"
                          >
                            Save
                          </el-button>

                          <el-button
                              v-if="leaveForm.selected_appointment_value != null"
                              type="danger"
                              class="btn mb-0 btn-danger btn-xs null"
                              @click="printInvoicePdf"
                          >
                            print
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </el-form>

    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="leave.pdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <PrintInvoice
            :leave="leaveForm"
        />
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import PrintInvoice from "./InvoiceDetailsPrint.vue";
import {ElNotification} from "element-plus";
import moment from "moment";
import Vue3Html2pdf from "vue3-html2pdf";
import {useRoute, useRouter} from "vue-router";
import {fetchPatientDetails, fetchUnPaginatedPatients, listPractitioners} from "../../api/services/users";
import {createLeave, fetchLeave, updateLeave} from "../../api/services/leave";

const leaveFormRef = ref();
const isLoading = ref(false);
const practitioners = ref([]);
const patients = ref([]);
const download_perc = ref(0);
const showProgress = ref(false);
const appointment_date = ref(new Date());
export default {
  name: "leave-form",

  props: {
    leave: {
      type: Object,
      required: false,
    },
    image_type: {
      type: String,
      required: false,
      default: "png",
    },
  },
  components: {
    Vue3Html2pdf,
    PrintInvoice,
  },

  setup(props) {
    const htmlToPdfOptions = {
      margin: 0.3,

      image: {
        type: props.image_type,
        quality: 2,
      },

      enableLinks: false,

      html2canvas: {
        scale: 5,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };

    const route = useRoute();
    const router = useRouter();
    const leave_id = route.params?.id;

    const leaveForm = reactive({
      id: leave_id || 0,
      patient: "",
      practitioner: "",
      reason: "",
      leave_date: "",
    });
    const removeInsurance = () => {
      leaveForm.patient_insurance = null;
    };

    const handleDocSelect = async (item) => {
      leaveForm.practitioner = item;
    }

    const handlePatientSelect = async (selectedPatient) => {

      leaveForm.patient = selectedPatient;

      leaveForm.selected_appointment_value = selectedPatient;

      const response = await fetchPatientDetails(selectedPatient);

      leaveForm.patient = response.data;

    };

    const fetchLeaveData = async () => {
      if (leave_id) {
        leaveForm.selected_appointment_value = leave_id;
        try {
          const response = await fetchLeave(leave_id);
          leaveForm.patient = response.data.patient;
          leaveForm.pt = response.data.patient.name;
          leaveForm.practitioner = response.data.doctor.name;
          leaveForm.leave_date = response.data.start_date +"-"+response.data.end_date
          leaveForm.reason = response.data.reason;
        } catch (error) {
          console.log(error);
        }
      }
    }
    const handleAppointmentSelect = (item) => {

      leaveForm.selected_appointment_value = item.value;

      leaveForm.patient = item.value?.patient;

    };
    const handleDiscountChange = (val) => {
      leaveForm.total = leaveForm.sub_total - val;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const rules = reactive({
      leave_date: [
        {
          required: true,
          message: "Please indicate the leave date",
          trigger: ["blur", "change"],
        },
      ],
      reason: [
        {
          required: true,
          message: "Please type reason",
          trigger: ["blur", "change"],
        },
      ],
      doctor: [
        {
          required: false,
          message: "Please select practitioner",
          trigger: ["blur"],
        },
      ],
      hospital: [
        {
          required: false,
          message: "Please select hospital",
          trigger: ["blur", "change"],
        },
      ],
    });

    function formatDate(date) {
      const inputDate = new Date(date);
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();

      return `${year}-${month}-${day}`;
    }
    const submitForm = async (formEl) => {
      if (!formEl) return;

      await formEl.validate((valid, fields) => {
        if (valid) {

          const start_date = formatDate(leaveForm.leave_date[0])
          const end_date = formatDate(leaveForm.leave_date[1])

          isLoading.value = true;
          const formData = new FormData();
          formData.append("reason", leaveForm.reason);
          formData.append("start_date", start_date);
          formData.append("end_date", end_date);
          formData.append("patient_id", leaveForm.patient.id);
          formData.append("issued_by", leaveForm.practitioner);

          if (leaveForm.id > 0) {
            updateLeave(leaveForm)
                .then(function (data) {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });

                    router.push("/accounts/leaves");
                  } else {
                    if (data.errors) {
                      Object.values(data.errors).forEach((val) =>
                          ElNotification({
                            title: "Error",
                            message: val[0],
                            type: "error",
                          })
                      );
                    }
                    if (data.message) {
                      ElNotification({
                        title: "Error",
                        message: data.message,
                        type: "error",
                      });
                    }
                  }
                })
                .catch((err) =>
                    ElNotification({
                      title: "Error",
                      message: err.message,
                      type: "error",
                    })
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            createLeave(formData)
                .then(function (data) {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });

                    router.push("/hr/leaves");
                  } else {
                    if (data.errors) {
                      Object.values(data.errors).forEach((val) =>
                          ElNotification({
                            duration: 4500,
                            title: "Error",
                            message: val[0],
                            type: "error",
                          })
                      );
                    }
                    if (data.message) {
                      ElNotification({
                        duration: 4500,
                        title: "Error",
                        message: data.message,
                        type: "error",
                      });
                    }
                  }
                })
                .catch((err) =>
                    ElNotification({
                      title: "Error",
                      message: err.message,
                      type: "error",
                    })
                )
                .finally(() => {
                  isLoading.value = false;
                });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const fetchPractitioners = async () => {
      try {
        const response = await listPractitioners();
        const tempPatients = response.data.map((user) => ({
          value: `${user.id}`,
          label: `${user.name}`,
        }));
        practitioners.value = tempPatients;
      } catch (error) {
        console.log(error);
      }
    }

    const fetchPatients = async () => {
      try {
        const response = await fetchUnPaginatedPatients();

        const tempPatients = response.data.map((patient) => ({
          value: `${patient.id}`,
          label: `${patient.name}`,
        }));

        patients.value = tempPatients;

      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      fetchPatients();
      fetchLeaveData();
      fetchPractitioners();
    });
    const onProgress = async (percentage) => {
      if (percentage == 100) showProgress.value = false;
      else {
        showProgress.value = true;
      }
      download_perc.value = percentage;
    };

    return {
      removeInsurance,
      appointment_date,
      onProgress,
      showProgress,
      download_perc,
      htmlToPdfOptions,
      leaveForm,
      submitForm,
      rules,
      leaveFormRef,
      isLoading,
      props,
      handleAppointmentSelect,
      handlePatientSelect,
      handleDocSelect,
      dateTime,
      handleDiscountChange,
      practitioners,
      patients
    };
  },
  methods: {
    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
    printInvoicePdf() {
      this.$refs.html2Pdf.generatePdf();
    }
  },
};
</script>
<style scoped>
.appointment_type {
  border-left: solid 2px red;
}

.el-form-item--label-top .el-form-item__label {
  width: 100% !important;
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 10px;
}
.el-form-item--label-top .el-form-item__content {
  margin-left: 0 !important;
}

.el-input-number .el-input__inner {
  text-align: right !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  outline: 0;
  border-radius: 7px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card-body {
  max-height: 80%;
  overflow-x: hidden;
  overflow-y: auto;
}
.leave-body {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
.el-form-item-label {
  font-weight: bold;
}
</style>
