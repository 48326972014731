<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <patients-table :patients="patients" :refresh="fetchData" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import PatientsTable from "./components/PatientsTable.vue";
import Loader from "./components/Loader.vue";
import { fetchPatients } from "../api/services/users";
import { ref } from "vue";

const isLoading = ref(false);
const patients = ref([]);
export default {
  name: "patients",
  components: {
    PatientsTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchPatients(entity_params, page);
        patients.value = response.data;
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      fetchData,
      patients,
      isLoading,
    };
  },
  mounted() {
    this.fetchData({}, 1);
  },
};
</script>
