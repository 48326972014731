<template>
  <el-form
    ref="consumableFormRef"
    :model="consumableForm"
    :rules="rules"
    label-width="120px"
    size=""
    status-icon
    :scroll-to-error="true"
    label-position="top"
    :hide-required-asterisk="true"
  >
    <div class="card">
      <div class="card-header">
        <i class="fa fa-user-md text-primary me-2" />
        Manage Consumables
      </div>
      <div class="card-body">
        <div class="row p-3">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header"><h6>Appointment Details</h6></div>
              <div class="card-body">
                <div class="d-flex flex-row">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold"
                      >Appointment No.:
                      <span class="text-sm">{{
                        appointment.appointment_number
                      }}</span></span
                    >
                    <span class="font-weight-bold"
                      >Appointment Date.:
                      <span class="text-sm">{{
                        appointment.appointment_date
                      }}</span></span
                    >
                  </div>
                  <div class="d-flex flex-column ps-5">
                    <span class="font-weight-bold"
                      >Patient Name.:
                      <span class="text-sm"
                        >{{ appointment?.patient?.first_name || "" }}
                        {{ appointment?.patient?.middle_name || "" }}
                        {{ appointment?.patient?.last_name || "" }}</span
                      ></span
                    >
                    <span class="font-weight-bold"
                      >Patient No.:
                      <span class="text-sm">{{
                        appointment.patient?.patient_number
                      }}</span></span
                    >
                  </div>
                </div>
                <div class="d-flex flex-column pt-3">
                  <span class="font-weight-bold">Services </span>
                  <hr />
                  <div class="d-flex flex-row">
                    <span
                      class="badge badge-secondary ps-2"
                      v-for="item in appointment.service"
                      :key="item.id"
                      >{{ item.item }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header"><h6>Consumables Date</h6></div>
              <div class="card-body">
                <el-form-item prop="date">
                  <el-date-picker
                    type="date"
                    placeholder="Pick a date"
                    style="width: 100%"
                    @change="fetchAppointmentConsumablesHistory"
                    v-model="consumable_date"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h6>Consumables Invoice Allocation</h6>
              </div>
              <div class="card-body">
                <el-form-item prop="consumable_invoice">
                  <el-select-v2
                    v-model="consumableForm.consumable_invoice"
                    filterable
                    remote
                    clearable
                    :options="invoices"
                    placeholder="Please search invoices"
                    style="width: 100%"
                    :remote-method="loadInvoices"
                    :loading="isLoading"
                    placement="bottom"
                  >
                  </el-select-v2>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="filterTableProducts.length > 0">
          <div class="col-md-8">
            <div class="row mt-1">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header"><h6>Products</h6></div>
                  <div class="card-body">
                    <table class="table align-items-center">
                      <thead>
                        <tr>
                          <th
                            class="text-left text-secondary text-xxs font-weight-bolder"
                          >
                            <el-input
                              v-model="ProdSearch"
                              size="small"
                              placeholder="Type to search"
                            />
                          </th>
                          <th
                            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder ps-2"
                          >
                            Product
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="product in filterTableProducts"
                          :key="product.id"
                        >
                          <td>
                            <el-switch
                              v-model="
                                product_consumables.selected_product[product.id]
                              "
                              style="
                                --el-switch-on-color: #13ce66;
                                --el-switch-off-color: #ff4949;
                              "
                            />
                          </td>
                          <td>
                            <h6 class="m-0 text-sm text-capitalize">
                              {{ product.name }}
                            </h6>
                            <div class="d-flex flex-row">
                              <div class="d-flex flex-column">
                                <div class="p-0 text-sm">Quantity</div>
                                <div class="p-0">
                                  <el-input-number
                                    size="small"
                                    :min="1"
                                    v-model="
                                      product_consumables.quantity[product.id]
                                    "
                                  />
                                </div>
                              </div>
                              <div class="d-flex flex-column">
                                <div class="ps-1 text-sm">Cost</div>
                                <div class="ps-1">
                                  <el-input-number
                                    size="small"
                                    :min="1"
                                    v-model="
                                      product_consumables.unit_cost[product.id]
                                    "
                                  />
                                </div>
                              </div>
                              <div class="d-flex flex-column">
                                <div class="ps-1 text-sm">Mark Up</div>
                                <div class="ps-1">
                                  <el-input-number
                                    size="small"
                                    :min="1"
                                    v-model="
                                      product_consumables.mark_up[product.id]
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-3" v-if="filterTableServices.length > 0">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header"><h6>Services</h6></div>
                  <div class="card-body">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="text-left text-secondary text-xxs font-weight-bolder"
                          >
                            <el-input
                              v-model="ServSearch"
                              size="small"
                              placeholder="Type to search"
                            />
                          </th>
                          <th
                            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder ps-2"
                          >
                            Service
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="service in filterTableServices"
                          :key="service.id"
                        >
                          <td>
                            <el-switch
                              v-model="
                                service_consumables.selected_service[service.id]
                              "
                              class="ml-2"
                              style="
                                --el-switch-on-color: #13ce66;
                                --el-switch-off-color: #ff4949;
                              "
                            />
                          </td>
                          <td>
                            <h6 class="mb-0 text-sm text-capitalize">
                              {{ service.name }}
                            </h6>

                            <div class="d-flex flex-row">
                              <div class="d-flex flex-column">
                                <div class="p-0 text-sm">Quantity</div>
                                <div class="p-0">
                                  <el-input-number
                                    size="small"
                                    :min="1"
                                    v-model="
                                      service_consumables.quantity[service.id]
                                    "
                                  />
                                </div>
                              </div>
                              <div class="d-flex flex-column">
                                <div class="ps-1 text-sm">Cost</div>
                                <div class="ps-1">
                                  <el-input-number
                                    size="small"
                                    :min="1"
                                    v-model="
                                      service_consumables.unit_cost[service.id]
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-1 ps-0">
            <div class="card">
              <div class="card-header">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <i class="fa fa-list text-primary pt-1 me-2" /> Consumables
                    dated: {{ dateTime(consumable_date) }}
                  </div>
                  <hr />
                  <div class="d-flex flex-row">
                    <el-switch
                      v-if="consumables_today.length"
                      v-model="enable_editting"
                      class="ml-2"
                      :loading="updating_lock_state"
                      @change="lockChange"
                      inline-prompt
                      style="
                        --el-switch-on-color: #ff4949;
                        --el-switch-off-color: #13ce66;
                      "
                      active-text="Disable Editing"
                      inactive-text="Enable Editting"
                    />
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div v-if="consumables_today.length > 0">
                  <div
                    class="d-flex flex-row"
                    v-for="consumable in consumables_today"
                    :key="consumable.id"
                  >
                    <div class="d-flex flex-column">
                      <hr />
                      <div class="d-flex flex-row">
                        <span class="font-weight-bold"
                          >{{ consumable.name }}
                        </span>
                        <small class="ps-1"
                          >({{ consumable.entity_type }})</small
                        >
                      </div>
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          Qnty:{{ consumable.quantity }}
                        </div>
                        <div class="d-flex flex-column ps-3">
                          @cost:{{ consumable.cost }}
                        </div>
                        <div class="d-flex flex-column ps-3">
                          Total: {{ consumable.total }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <el-button
          type="success"
          @click="saveAppointmentConsumablesConfirm(consumableFormRef)"
          >Save</el-button
        >
      </div>
    </div>
  </el-form>
</template>
<script>
import { onMounted, h, ref, reactive, computed } from "vue";
import {
  fetchConsumables,
  fetchConsumablesHistory,
  saveConsumables,
  updateLockState,
} from "../../api/services/appointments";
import { fetchInvoices } from "../../api/services/accounts";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import moment from "moment";
const ProdSearch = ref("");
const ServSearch = ref("");
const isLoading = ref(false);
const products = ref([]);
const services = ref([]);
const appointment = ref({});
const invoices = ref([]);
const enable_editting = ref(true);
const updating_lock_state = ref(false);
const consumableFormRef = ref();
export default {
  name: "service-consumables",
  props: {},
  setup() {
    const consumable_date = ref(new Date());

    const product_consumables = reactive({
      quantity: {},
      unit_cost: {},
      mark_up: {},
      selected_product: {},
    });
    const consumables_today = ref({});
    const service_consumables = reactive({
      quantity: {},
      unit_cost: {},
      selected_service: {},
    });

    const consumableForm = reactive({
      consumable_invoice: null,
    });
    const rules = reactive({
      consumable_invoice: [
        {
          required: true,
          message: "Please chose an invoice to associate the consumables with.",
          trigger: ["blur", "change"],
        },
      ],
    });

    const route = useRoute();
    const appnt_id = route.params?.id;

    const filterTableProducts = computed(() =>
      products.value.filter(
        (data) =>
          !ProdSearch.value ||
          data.name.toLowerCase().includes(ProdSearch.value.toLowerCase())
      )
    );
    const filterTableServices = computed(() =>
      services.value.filter(
        (data) =>
          !ServSearch.value ||
          data.name.toLowerCase().includes(ServSearch.value.toLowerCase())
      )
    );
    const fetchAppointmentConsumablesHistory = async () => {
      try {
        const consumables_response = await fetchConsumablesHistory(
          appnt_id,
          moment(consumable_date.value).format("DD-MM-Y")
        );
        consumables_today.value = consumables_response?.data.consumables;
        enable_editting.value = consumables_response?.data.consumable_lock;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchData = async () => {
      products.value = [];
      services.value = [];
      isLoading.value = true;
      try {
        const appointment_consumables_response = await fetchConsumables(
          appnt_id
        );
        appointment.value = appointment_consumables_response?.data?.appointment;
        appointment_consumables_response?.data?.products?.forEach((prod) => {
          product_consumables.selected_product[prod.id] = false;
          product_consumables.quantity[prod.id] = prod.quantity;
          product_consumables.unit_cost[prod.id] = prod.cost;
          product_consumables.mark_up[prod.id] = prod.markup;
          products.value.push(prod);
        });
        appointment_consumables_response?.data?.services?.forEach((serv) => {
          service_consumables.selected_service[serv.id] = false;
          service_consumables.quantity[serv.id] = serv.quantity;
          service_consumables.unit_cost[serv.id] = serv.cost;
          services.value.push(serv);
        });
        loadInvoices();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const lockChange = (state) => {
      const formData = new FormData();
      updating_lock_state.value = true;
      formData.append("state", state ? 1 : 0);
      formData.append(
        "consumable_date",
        moment(consumable_date.value).format("DD-MM-Y")
      );

      updateLockState(appnt_id, formData)
        .then((data) => {
          updating_lock_state.value = false;
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          updating_lock_state.value = false;
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          });
        })
        .finally(() => {
          updating_lock_state.value = false;
        });
    };

    const saveAppointmentConsumablesConfirm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          ElMessageBox.confirm(
            h(
              "div",
              { style: "color: black, font-size: 14px" },
              "Are you sure you want to save the appointment consumables? This action will save the information as submitted as today's daily log."
            ),

            "Daily Consumables Log",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
              draggable: true,
              center: true,
            }
          )
            .then(async () => {
              saveAppointmentConsumables(appnt_id);
            })
            .catch(() => {
              ElMessage({
                type: "info",
                message: "Saving cancelled",
              });
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const saveAppointmentConsumables = (appnt_id) => {
      const formData = new FormData();
      formData.append(
        "product_consumables",
        JSON.stringify(product_consumables)
      );
      formData.append(
        "service_consumables",
        JSON.stringify(service_consumables)
      );
      formData.append(
        "consumable_date",
        moment(consumable_date.value).format("DD-MM-Y")
      );
      formData.append("invoice_id", consumableForm.consumable_invoice.value);

      saveConsumables(appnt_id, formData)
        .then((data) => {
          isLoading.value = false;
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
            fetchAppointmentConsumablesHistory();
          } else {
            ElNotification({
              title: "Error",
              message: data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          isLoading.value = false;
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          });
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    const loadInvoices = async (query) => {
      const entity_params = ref();
      entity_params.value = {
        search_query: query,
        patient_id: appointment.value?.patient_id || 0,
      };
      isLoading.value = true;
      try {
        const response = await fetchInvoices(entity_params);

        const tempInvoices = response.data.data.map((invoice) => ({
          value: {
            value: invoice.id,
            data: invoice,
          },
          label:
            dateTime(invoice.invoice_date) +
            " " +
            invoice.invoice_number +
            " " +
            invoice?.patient?.first_name +
            " " +
            invoice?.patient?.last_name +
            " | Invoice Amount: KES" +
            invoice?.total,
        }));

        invoices.value = tempInvoices;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      fetchData();
      fetchAppointmentConsumablesHistory();
    });
    return {
      enable_editting,
      updating_lock_state,
      lockChange,
      dateTime,
      loadInvoices,
      appointment,
      ProdSearch,
      ServSearch,
      consumable_date,
      fetchAppointmentConsumablesHistory,
      products,
      services,
      invoices,
      filterTableProducts,
      filterTableServices,
      product_consumables,
      service_consumables,
      saveAppointmentConsumables,
      saveAppointmentConsumablesConfirm,
      consumables_today,
      rules,
      consumableFormRef,
      consumableForm,
    };
  },
};
</script>
