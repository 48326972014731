<template>
  <div v-if="isLoading"><Loader /></div>

  <div class="py-4 container-fluid" v-else>
    <div class="dropdown">
      <button
        class="btn btn-sm btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        My Queue
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li
          @click="handleQueueDropdownChange(appmt)"
          class="list-group-item"
          v-for="appmt in doctor_appointments"
          :key="appmt.id"
        >
          {{ appmt.patient.first_name }} {{ appmt.patient.middle_name }}
          {{ appmt.patient.last_name }} | {{ appmt.appointment_number }} |
          {{ appmt.appointment_type }} |

          <span class="text-primary">View Appointment</span>
        </li>
      </ul>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card main_card">
          <div class="card-body">
            <div class="row pb-5">
              <PatientInfo :patient_id="appointment.patient_id" />
            </div>
            <div class="row">
              <el-tabs
                tab-position="top"
                v-model="activeTabName"
                stretch="true"
                style="height: 50%"
              >
                <el-tab-pane name="0">
                  <template #label>
                    <span class="custom-tabs-label">
                      <i class="fa fa-heartbeat p-3" aria-hidden="true"></i>

                      <span>Vitals Summary</span>
                    </span>
                  </template>
                  <div class="px-1">
                    <p class="text-uppercase text-sm">VITALS SUMMARY</p>
                    <hr class="horizontal dark" />
                    <el-form
                      ref="triageFormRef"
                      :model="triageForm"
                      label-width="auto"
                      class="demo-ruleForm"
                      size="large"
                      status-icon
                      :scroll-to-error="true"
                      label-position="top"
                      :hide-required-asterisk="true"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item
                            label="Last Monthly Period"
                            prop="pressure"
                          >
                            <el-date-picker
                              v-model="triageForm.lmp"
                              type="date"
                              label="Pick a date"
                              placeholder="Pick a date"
                              style="width: 100%"
                            />
                          </el-form-item>
                          <el-form-item label="Blood Pressure" prop="pressure">
                            <el-input v-model="triageForm.pressure" />
                          </el-form-item>
                          <el-form-item
                            label="Temperature (cº)"
                            prop="temperature"
                          >
                            <el-input v-model="triageForm.temperature" />
                          </el-form-item>
                          <el-form-item label="SPO²" prop="spo">
                            <el-input v-model="triageForm.spo" />
                          </el-form-item>
                          <el-form-item label="Pulse (beats/min) " prop="pulse">
                            <el-input v-model="triageForm.pulse" />
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item label="Height (CM)" prop="height">
                            <el-input
                              width="100%"
                              v-model="height"
                              :controls="false"
                            />
                          </el-form-item>
                          <el-form-item label="Weight (Kgs)" prop="weight">
                            <el-input v-model="weight" :controls="false" />
                          </el-form-item>
                          <el-form-item
                            label="Body Mass Index (BMI) "
                            prop="bmi"
                          >
                            <el-input
                              :precision="0"
                              v-model="triageForm.bmi"
                              :controls="false"
                            />
                          </el-form-item>

                          <el-form-item label="Respiratory (cpm) " prop="cpm">
                            <el-input v-model="triageForm.cpm" />
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="d-flex justify-content-between bd-highlight mb-3"
                        >
                          <div class="p-2 bd-highlight"></div>
                          <div class="p-2 bd-highlight">
                            <el-button
                              type="success"
                              class="px-5 right"
                              size="small"
                              :loading="isLoading"
                              @click="submitTriageForm(triageFormRef)"
                            >
                              Save & Next
                            </el-button>
                          </div>
                          <div class="p-2 bd-highlight"></div>
                        </div>
                      </div>
                    </el-form>
                  </div>
                </el-tab-pane>
                <el-tab-pane name="1">
                  <template #label>
                    <span class="custom-tabs-label">
                      <i class="fa fa-eye p-3" aria-hidden="true"></i>
                      <span>Vision/IOP</span>
                    </span>
                  </template>
                  <el-form
                    ref="visionFormRef"
                    :model="visionForm"
                    label-width="auto"
                    class="demo-ruleForm"
                    size="large"
                    status-icon
                    :scroll-to-error="true"
                    label-position="top"
                    :hide-required-asterisk="true"
                  >
                    <div class="px-1">
                      <div class="row">
                        <div class="table-responsive p-0">
                          <table class="table align-items-center table-dark">
                            <thead class="thead-dark">
                              <tr>
                                <th
                                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                >
                                  Vision
                                </th>
                                <th
                                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                >
                                  Right Eye
                                </th>
                                <th
                                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                >
                                  Left Eye
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    D.V Unaided
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.dv_unaided_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.dv_unaided_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    D.V Pin-Hole
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.dv_pin_hole_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.dv_pin_hole_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    D.V With own correction
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.dv_own_correction_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.dv_own_correction_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    N.V Unaided
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.nv_unaided_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.nv_unaided_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    N.V With own correction
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.nv_own_correction_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.nv_own_correction_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">IOP (mmHg)</div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.iop_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.iop_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    Color Vision
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.color_vision_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.color_vision_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">Lang Test</div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.lang_test_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.lang_test_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">Fly Test</div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.fly_test_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.fly_test_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                              <tr class="mr-3">
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    Amasler Grid
                                  </div>
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.amasler_grid_right"
                                    :controls="false"
                                  />
                                </td>
                                <td>
                                  <el-input
                                    class="px-3"
                                    :precision="0"
                                    v-model="visionForm.amasler_grid_left"
                                    :controls="false"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="d-flex justify-content-between bd-highlight mb-3"
                        >
                          <div class="p-2 bd-highlight">
                            <el-button
                              type="primary"
                              class="px-5 right"
                              size="small"
                              :loading="isLoading"
                              link
                              @click="switchPotision('0')"
                            >
                              Previous
                            </el-button>
                          </div>
                          <div class="p-2 bd-highlight">
                            <el-button
                              type="success"
                              class="px-5 right"
                              size="small"
                              :loading="isLoading"
                              @click="submitVisionForm(visionFormRef)"
                            >
                              Save & Next
                            </el-button>
                          </div>
                          <div class="p-2 bd-highlight"></div>
                        </div>
                      </div>
                    </div>
                  </el-form>
                </el-tab-pane>

                <el-tab-pane name="2">
                  <template #label>
                    <span class="custom-tabs-label">
                      <i class="fa fa-paperclip p-3" aria-hidden="true"></i>
                      <span>Attachments</span>
                    </span>
                  </template>
                  <div class="mb-3">
                    <label for="formFileMultiple" class="form-label"
                      >Current Files</label
                    >
                    <div class="card" style="width: 100%">
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item"
                          v-for="(item, index) in current_files"
                          :key="item.id"
                        >
                          <div class="d-flex flex-row">
                            <div class="p-2">
                              <div class="demo-image__preview">
                                <el-image
                                  v-if="item.ext != 'pdf'"
                                  style="width: 150px; height: 150px"
                                  :src="item.path"
                                  :zoom-rate="1.2"
                                  :preview-src-list="current_files_preview_list"
                                  :initial-index="index"
                                  fit="cover"
                                />
                                <img
                                  v-if="item.ext == 'pdf'"
                                  style="width: 150px; height: 150px"
                                  alt="Image placeholder"
                                  @click="viewPdf(item.path)"
                                  src="@/assets/img/pdf_holder.png"
                                  class="side-glance"
                                  fit="cover"
                                />
                              </div>
                            </div>
                            <div class="p-2">{{ item.file_name }}</div>
                            <div class="p-2">
                              <el-button
                                type="danger"
                                @click="delete_file(index)"
                                :icon="Delete"
                                >Delete</el-button
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="formFileMultiple" class="form-label"
                      >Select files to upload</label
                    >
                    <!-- image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document  -->
                    <input
                      class="form-control"
                      type="file"
                      @change="handleFileSelect"
                      id="formFileMultiple"
                      accept="image/*,application/pdf"
                      multiple
                    />
                    <div class="card" style="width: 100%">
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item"
                          v-for="(item, index) in preview_list"
                          :key="item.name"
                        >
                          <div class="d-flex flex-row">
                            <div class="p-2">
                              <div class="demo-image__preview">
                                <el-image
                                  v-if="file_extention[index] != 'pdf'"
                                  style="width: 150px; height: 150px"
                                  :src="item"
                                  :zoom-rate="1.2"
                                  :preview-src-list="preview_list"
                                  :initial-index="index"
                                  fit="cover"
                                />
                                <img
                                  v-if="file_extention[index] == 'pdf'"
                                  style="width: 150px; height: 150px"
                                  alt="Image placeholder"
                                  src="@/assets/img/pdf_holder.png"
                                  class="side-glance"
                                  fit="cover"
                                />
                              </div>
                            </div>
                            <div class="p-2">
                              {{ selectedFiles[index].name }}
                            </div>
                            <div class="p-2">
                              <el-button
                                @click="remove_file(index)"
                                type="danger"
                                :icon="Delete"
                                >Remove</el-button
                              >
                            </div>
                            <div class="p-2" style="width: 50%">
                              <el-progress
                                :percentage="uploadPerc[index]"
                                :status="uploadStatus[index]"
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="row">
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <el-button
                            type="primary"
                            class="px-5 right"
                            size="small"
                            :loading="isLoading"
                            link
                            @click="switchPotision('1')"
                          >
                            Previous
                          </el-button>
                        </div>
                        <div class="p-2 bd-highlight">
                          <el-button
                            type="success"
                            class="px-5 right"
                            size="small"
                            :loading="isLoading"
                            @click="handleFileUpload()"
                          >
                            Save & Next
                          </el-button>
                        </div>
                        <div class="p-2 bd-highlight"></div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>

                <el-tab-pane name="3">
                  <template #label>
                    <span class="custom-tabs-label">
                      <i class="fa fa-sticky-note p-3" aria-hidden="true"></i>
                      <span>Case Notes</span>
                    </span>
                  </template>
                  <div class="px-1">
                    <el-form
                      ref="caseNotesFormRef"
                      :model="visionForm"
                      label-width="auto"
                      class="demo-ruleForm"
                      size="large"
                      status-icon
                      :scroll-to-error="true"
                      label-position="top"
                      :hide-required-asterisk="true"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item
                            label="Presenting Complaint"
                            prop="complains"
                          >
                            <QuillEditor
                              theme="snow"
                              toolbar="full"
                              contentType="html"
                              v-model:content="caseNotesForm.complains"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item
                            label="Clinical Findings"
                            prop="findings"
                          >
                            <QuillEditor
                              theme="snow"
                              toolbar="full"
                              contentType="html"
                              v-model:content="caseNotesForm.findings"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item label="Treatment Plan" prop="notes">
                            <QuillEditor
                              theme="snow"
                              toolbar="full"
                              contentType="html"
                              v-model:content="caseNotesForm.notes"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item label="Diagnosis (ICD 11)" prop="diagnosis">
                            <el-select
                              placeholder="Please choose a diagnosis"
                              v-model="selected_diagnosis"
                              searchable
                              filterable
                              style="width: 100%"
                              multiple
                              remote
                              :remote-method="loadDiagnosis"
                              :reserve-keyword="false"
                              placement="left"
                            >
                              <el-option
                                v-for="diag in diagnosisList"
                                :key="diag.id"
                                :label="diag.label"
                                :value="diag.value"
                              >
                              </el-option>

                            </el-select>
                          </el-form-item>
                          <el-form-item
                            label="Diagnosis Details"
                            prop="diagnosis"
                          >
                            <QuillEditor
                              theme="snow"
                              toolbar="full"
                              contentType="html"
                              v-model:content="caseNotesForm.diagnosis"
                            />
                          </el-form-item>
                        </div>
                      </div>

                      <div class="row">
                        <div
                          class="d-flex justify-content-between bd-highlight mb-3"
                        >
                          <div class="p-2 bd-highlight">
                            <el-button
                              type="primary"
                              class="px-5 right"
                              size="small"
                              :loading="isLoading"
                              link
                              @click="switchPotision('2')"
                            >
                              Previous
                            </el-button>
                          </div>
                          <div class="p-2 bd-highlight">
                            <el-button
                              type="success"
                              class="px-5 right"
                              size="small"
                              :loading="isLoading"
                              @click="saveNotes(caseNotesFormRef)"
                            >
                              Save & Next
                            </el-button>
                          </div>
                          <div class="p-2 bd-highlight"></div>
                        </div>
                      </div>
                    </el-form>
                  </div>
                </el-tab-pane>

                <el-tab-pane name="4">
                  <template #label>
                    <span class="custom-tabs-label">
                      <i class="fa fa-heart p-3" aria-hidden="true"></i>
                      <span>Services Offered</span>
                    </span>
                  </template>
                  <div class="px-1">
                    <p class="text-uppercase text-sm">Services Offered</p>
                    <hr class="horizontal dark" />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="pb-5">
                          <el-select
                            placeholder="Please choose Services Offered"
                            v-model="selected_services"
                            searchable
                            filterable
                            style="width: 100%"
                            multiple
                            :reserve-keyword="false"
                            placement="top"
                          >
                            <el-option
                              v-for="service in services"
                              :key="service"
                              :label="service.label"
                              :value="service.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <ul class="list-group">
                          <li
                            class="list-group-item"
                            v-for="service in selected_services"
                            :key="service"
                          >
                            <div class="text-bold text-gray-900 text-sm">
                              {{ service.label }} :
                              {{ service.description }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="d-flex justify-content-between bd-highlight mb-3"
                    >
                      <div class="p-2 bd-highlight">
                        <el-button
                          type="primary"
                          class="px-5 right"
                          size="small"
                          :loading="isLoading"
                          link
                          @click="switchPotision('3')"
                        >
                          Previous
                        </el-button>
                      </div>
                      <div class="p-2 bd-highlight">
                        <el-button
                          type="success"
                          class="px-5 right"
                          size="small"
                          :loading="isLoading"
                          @click="saveServices(selected_services)"
                        >
                          Save & Next
                        </el-button>
                      </div>
                      <div class="p-2 bd-highlight"></div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane name="5">
                  <template #label>
                    <span class="custom-tabs-label">
                      <i class="fa fa-medkit p-3" aria-hidden="true"></i>
                      <span>Prescription</span>
                    </span>
                  </template>
                  <div class="px-1">
                    <p class="text-uppercase text-sm">
                      <u> In-house Pharmacy/Store</u>
                    </p>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="pb-5">
                          <el-select
                            placeholder="Please choose a product to be prescribed"
                            v-model="selected_products"
                            searchable
                            filterable
                            style="width: 100%"
                            multiple
                            :reserve-keyword="false"
                            placement="top"
                          >
                            <el-option
                              v-for="product in products"
                              :key="product"
                              :label="product.label"
                              :value="product.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <template v-for="(product, index) in selected_products">
                          <div v-if="product != undefined" :key="index">
                            <div class="d-flex align-items-start flex-row">
                              <div
                                class="d-flex align-content-center flex-column"
                              >
                                <div
                                  class="text-left text-uppercase text-secondary text-xxs font-weight-bolder m-2"
                                >
                                  {{ product.label }}
                                </div>
                              </div>
                              <el-divider
                                direction="vertical"
                                border-style="dashed"
                                style="min-height: 100px"
                              />
                              <div
                                class="d-flex align-content-center flex-column"
                              >
                                <div class="d-flex align-items-start flex-row">
                                  <div
                                    class="d-flex align-items-start flex-column p-2"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                    >
                                      Duration
                                    </div>
                                    <div>
                                      <el-input
                                        v-model="prescription.duration[index]"
                                        class="w-70"
                                        size="small"
                                      >
                                        <template #append>
                                          <el-select
                                            v-model="
                                              prescription.duration_option[
                                                index
                                              ]
                                            "
                                            size="small"
                                            style="width: 100px"
                                          >
                                            <el-option
                                              v-for="duration_option in duration_options"
                                              :key="duration_option.value"
                                              :label="duration_option.label"
                                              :value="duration_option.value"
                                            />
                                          </el-select>
                                        </template>
                                      </el-input>
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex align-items-start flex-column p-2"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                    >
                                      Eye Treated
                                    </div>
                                    <div>
                                      <el-select
                                        v-model="prescription.eye_option[index]"
                                        placeholder="Select"
                                        size="small"
                                      >
                                        <el-option
                                          v-for="eye_treated_option in eye_options"
                                          :key="eye_treated_option.value"
                                          :label="eye_treated_option.label"
                                          :value="eye_treated_option.value"
                                        />
                                      </el-select>
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex align-items-start flex-column p-2"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                    >
                                      Timing
                                    </div>
                                    <div>
                                      <el-select
                                        v-model="prescription.timings[index]"
                                        placeholder="Select"
                                        size="small"
                                      >
                                        <el-option
                                          v-for="timings_option in timings_options"
                                          :key="timings_option.value"
                                          :label="timings_option.label"
                                          :value="timings_option.value"
                                        />
                                      </el-select>
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex align-items-start flex-column p-2"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                    >
                                      Generic Allowed
                                    </div>
                                    <div>
                                      <el-checkbox
                                        class="px-5"
                                        v-model="
                                          prescription.allow_generic[index]
                                        "
                                        size="large"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex align-items-start flex-column p-2"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                    >
                                      Meal Criteria
                                    </div>
                                    <div>
                                      <el-select
                                        v-model="
                                          prescription.meal_criteria[index]
                                        "
                                        placeholder="Select"
                                        size="small"
                                      >
                                        <el-option
                                          v-for="meal_time_option in meal_time_options"
                                          :key="meal_time_option.value"
                                          :label="meal_time_option.label"
                                          :value="meal_time_option.value"
                                        />
                                      </el-select>
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex align-items-start flex-column p-2"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                    >
                                      Quantity
                                    </div>
                                    <div>
                                      <el-input-number
                                        size="small"
                                        :min="1"
                                        v-model="prescription.quantity[index]"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="d-flex align-items-start flex-row">
                                  <div
                                    class="text-left text-uppercase text-secondary text-xxs font-weight-bolder m-2"
                                    style="width: 100%"
                                  >
                                    <el-input
                                      v-model="prescription.note[index]"
                                      maxlength="500"
                                      placeholder="Type instructions / additional notes "
                                      show-word-limit
                                      type="textarea"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <el-divider
                              direction="horizontal"
                              border-style="dashed"
                            />
                          </div>
                        </template>

                        <div class="mt-3">
                          <div class="d-flex justify-content-between">
                            <u class="text-uppercase text-sm"
                              >External Prescription</u
                            >

                            <el-button
                              @click="addExternalPrescriptionRow"
                              type="primary"
                              size="small"
                              :icon="Plus"
                              circle
                            />
                          </div>

                          <template
                            v-for="(
                              external_prescription, index
                            ) in external_prescriptions"
                          >
                            <div
                              v-if="external_prescription != undefined"
                              :key="index"
                            >
                              <div class="d-flex align-items-start flex-row">
                                <div
                                  class="d-flex align-content-center flex-column"
                                >
                                  <div
                                    class="text-left text-uppercase text-secondary text-xxs font-weight-bolder m-2"
                                  >
                                    <el-input
                                      placeholder="Name"
                                      :rows="2"
                                      type="textarea"
                                      style="max-width: 100px"
                                      v-model="external_prescription.name"
                                    />
                                  </div>
                                </div>
                                <el-divider
                                  direction="vertical"
                                  border-style="dashed"
                                  style="min-height: 100px"
                                />
                                <div
                                  class="d-flex align-content-center flex-column"
                                >
                                  <div
                                    class="d-flex align-items-start flex-row"
                                  >
                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        Duration
                                      </div>
                                      <div>
                                        <el-input
                                          v-model="
                                            external_prescription.duration
                                          "
                                          size="small"
                                          class="w-70"
                                        >
                                          <template #append>
                                            <el-select
                                              v-model="
                                                external_prescription.duration_option
                                              "
                                              size="small"
                                              style="width: 100px"
                                            >
                                              <el-option
                                                v-for="duration_option in duration_options"
                                                :key="duration_option.value"
                                                :label="duration_option.label"
                                                :value="duration_option.value"
                                              />
                                            </el-select>
                                          </template>
                                        </el-input>
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        Eyes Treated
                                      </div>
                                      <div>
                                        <el-select
                                          v-model="
                                            external_prescription.eye_option
                                          "
                                          placeholder="Select"
                                          size="small"
                                        >
                                          <el-option
                                            v-for="eye_treated_option in eye_options"
                                            :key="eye_treated_option.value"
                                            :label="eye_treated_option.label"
                                            :value="eye_treated_option.value"
                                          />
                                        </el-select>
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        Timing
                                      </div>
                                      <div>
                                        <el-select
                                          v-model="
                                            external_prescription.timings
                                          "
                                          placeholder="Select"
                                          size="small"
                                        >
                                          <el-option
                                            v-for="timings_option in timings_options"
                                            :key="timings_option.value"
                                            :label="timings_option.label"
                                            :value="timings_option.value"
                                          />
                                        </el-select>
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        Generic Allowed
                                      </div>
                                      <div>
                                        <el-checkbox
                                          class="px-5"
                                          v-model="
                                            external_prescription.allow_generic
                                          "
                                          size="large"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        Meal Criteria
                                      </div>
                                      <div>
                                        <el-select
                                          v-model="
                                            external_prescription.meal_criteria
                                          "
                                          style="width: 120px"
                                          placeholder="Select"
                                          size="small"
                                        >
                                          <el-option
                                            v-for="meal_time_option in meal_time_options"
                                            :key="meal_time_option.value"
                                            :label="meal_time_option.label"
                                            :value="meal_time_option.value"
                                          />
                                        </el-select>
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        Quantity
                                      </div>
                                      <div>
                                        <el-input-number
                                          size="small"
                                          :min="1"
                                          v-model="
                                            external_prescription.quantity
                                          "
                                        />
                                      </div>
                                    </div>

                                    <div
                                      class="d-flex align-items-start flex-column p-2"
                                    >
                                      <div
                                        class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                                      >
                                        <el-button
                                          v-if="
                                            index + 1 <
                                            external_prescriptions.length
                                          "
                                          @click="
                                            removeExternalPrescriptionRow(
                                              external_prescription.id
                                            )
                                          "
                                          type="danger"
                                          size="small"
                                          :icon="Delete"
                                          circle
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex align-items-start flex-row"
                                  >
                                    <div
                                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder m-2"
                                      style="width: 100%"
                                    >
                                      <el-input
                                        v-model="external_prescription.note"
                                        maxlength="500"
                                        placeholder="Type instructions / additional notes "
                                        show-word-limit
                                        type="textarea"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <el-divider
                                direction="horizontal"
                                border-style="dashed"
                              />
                            </div>
                          </template>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div
                              class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                            >
                              Additional Prescription Details
                            </div>
                            <QuillEditor
                              theme="snow"
                              toolbar="full"
                              contentType="html"
                              v-model:content="external_prescription.details"
                            />
                          </div>
                          <div class="col-md-6">
                            <div
                              class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
                            >
                              Frame Details
                            </div>
                            <QuillEditor
                              theme="snow"
                              toolbar="full"
                              contentType="html"
                              v-model:content="
                                external_prescription.frame_details
                              "
                            />
                          </div>
                        </div>

                        <div class="row mt-7">
                          <div
                            class="d-flex justify-content-between bd-highlight mb-3"
                          >
                            <div class="p-2 bd-highlight">
                              <el-button
                                type="primary"
                                class="px-5 right"
                                size="small"
                                :loading="isLoading"
                                link
                                @click="switchPotision('4')"
                              >
                                Previous
                              </el-button>
                            </div>
                            <div class="p-2 bd-highlight">
                              <el-button
                                type="success"
                                class="px-5 right"
                                size="small"
                                :loading="isLoading"
                                @click="savePrescription()"
                              >
                                Save
                              </el-button>
                            </div>
                            <div class="p-2 bd-highlight">
                              <el-button
                                type="warning"
                                class="px-5 right"
                                size="small"
                                :loading="isLoading"
                                @click="printPrescriptionPdf()"
                              >
                                Save & Print
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PdfViewerModal
      v-if="showPdfViewerModal"
      @close-modal="showPdfViewerModal = false"
      :pdfUrl="pdf_url"
    />
  </div>
</template>

<script>
import PdfViewerModal from "../components/Modals/PdfViewerModal.vue";
import Loader from "@/components/Loader.vue";
import PatientInfo from "./components/PatientInfo.vue";
import {
  deleteUpload,
  downloadPrescriptionPdf,
  fetchAppointment,
  fetchAppointments,
  saveRecord,
} from "../api/services/appointments";

import { ElMessage, ElNotification } from "element-plus";
import { onMounted, reactive, ref, watch } from "vue";
import { fetchServices } from "@/api/services/services";
import { fetchDiagnosis } from "@/api/services/diagnosis";

import { fetchProducts } from "@/api/services/inventory";
import { useRoute } from "vue-router";
import { api } from "../api";
import { Delete, Plus } from "@element-plus/icons-vue";
import moment from "moment";

const meal_time_options = [
  {
    value: "either",
    label: "Either",
  },
  {
    value: "before",
    label: "Before Meal",
  },
  {
    value: "after",
    label: "After Meal",
  },
];
const duration_options = [
  {
    value: "day",
    label: "Day(s)",
  },
  {
    value: "week",
    label: "Week(s)",
  },
  {
    value: "month",
    label: "Month(s)",
  },
];
const eye_options = [
  {
    value: "both",
    label: "BE",
  },
  {
    value: "right",
    label: "RE",
  },
  {
    value: "left",
    label: "LE",
  },
];
const timings_options = [
  {
    value: "prn",
    label: "PRN",
  },
  {
    value: "every_1/2_hour",
    label: "Every Half Hour",
  },
  {
    value: "every_1_hour",
    label: "Every 1 Hr",
  },
  {
    value: "every_2_hour",
    label: "Every 2 Hr",
  },
  {
    value: "every_3_hour",
    label: "Every 3 Hr",
  },
  {
    value: "every_4_hour",
    label: "Every 4 Hr",
  },
  {
    value: "every_6_hour",
    label: "Every 6 Hr",
  },
  {
    value: "every_8_hour",
    label: "Every 8 Hr",
  },
  {
    value: "every_12_hour",
    label: "Every 12 Hr",
  },
];
const pdf_url = ref("");
const showPdfViewerModal = ref(false);
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const triageFormRef = ref();
const visionFormRef = ref();
const caseNotesFormRef = ref();
const services = ref([]);
const diagnosisList = ref([]);
const products = ref([]);
const selected_services = ref([]);
const selected_diagnosis = ref([]);
const selected_products = ref([]);
const appointment = ref({});
const isLoading = ref(false);
const notes = ref();
const diagnosis = ref();
const height = ref(0);
const weight = ref(0);
const selectedFiles = ref([]);
const preview_list = ref([]);
const uploadPerc = ref([]);
const uploadStatus = ref([]);
const file_extention = ref([]);
const current_files = ref([]);
const current_files_preview_list = ref([]);
const external_prescriptions = ref([]);
const counter = ref(0);
const activeTabName = ref("0");
const prescription = reactive({
  quantity: [],
  frequency: [],
  duration: [],
  duration_option: [],
  allow_generic: [],
  meal_criteria: [],
  eye_option: [],
  timings: [],
  note: [],
});
const doctor_appointments = ref({});
const isAppoimentsLoading = ref(false);
export default {
  emits: ["update:value"],
  name: "profile",
  components: { PdfViewerModal, PatientInfo, Loader },

  setup() {
    watch(weight, (currentValue) => {
      triageForm.weight = currentValue;
      if (currentValue > 0) {
        triageForm.bmi =
          (triageForm.weight * 10000) / (triageForm.height * triageForm.height);
      }
    });
    watch(height, (currentValue) => {
      triageForm.height = currentValue;
      if (currentValue > 0) {
        triageForm.bmi =
          (triageForm.weight * 10000) / (triageForm.height * triageForm.height);
      }
    });

    const formData = new FormData();
    const route = useRoute();
    const triageForm = reactive({
      bmi: "",
      cpm: "",
      height: "",
      pressure: "",
      temperature: "",
      pulse: "",
      spo: "",
      lpm: "",
      weight: "",
    });
    const caseNotesForm = reactive({
      complains: "",
      findings: "",
      notes: "",
      diagnosis: "",
    });
    const external_prescription = reactive({
      details: "",
      frame_details: "",
    });
    const visionForm = reactive({
      dv_unaided_left: "",
      dv_unaided_right: "",
      dv_pin_hole_left: "",
      dv_pin_hole_right: "",
      dv_own_correction_left: "",
      dv_own_correction_right: "",
      nv_unaided_left: "",
      nv_unaided_right: "",
      nv_own_correction_left: "",
      nv_own_correction_right: "",
      iop_left: "",
      iop_right: "",
      color_vision_left: "",
      color_vision_right: "",
      lang_test_left: "",
      lang_test_right: "",
      fly_test_left: "",
      fly_test_right: "",
      amasler_grid_left: "",
      amasler_grid_right: "",
    });
    const calculateBMI = () => {
      triageForm.bmi = "";
    };
    const fetchAppointmentInfo = async (id) => {
      isLoading.value = true;
      let appt_id = 0;
      if (id != undefined) {
        appt_id = id;
      } else {
        appt_id = route.params.id;
      }
      try {
        const response = await fetchAppointment(appt_id);

        const vision = JSON.parse(response.data?.record?.vision || "{}");
        visionForm.dv_unaided_left = vision?.dv_unaided_left || "";
        visionForm.dv_unaided_right = vision?.dv_unaided_right || "";
        visionForm.dv_pin_hole_left = vision?.dv_pin_hole_left || "";
        visionForm.dv_pin_hole_right = vision?.dv_pin_hole_right || "";
        visionForm.dv_own_correction_left =
          vision?.dv_own_correction_left || "";
        visionForm.dv_own_correction_right =
          vision?.dv_own_correction_right || "";
        visionForm.nv_unaided_left = vision?.nv_unaided_left || "";
        visionForm.nv_unaided_right = vision?.nv_unaided_right || "";
        visionForm.nv_own_correction_left =
          vision?.nv_own_correction_left || "";
        visionForm.nv_own_correction_right =
          vision?.nv_own_correction_right || "";
        visionForm.iop_left = vision?.iop_left || "";
        visionForm.iop_right = vision?.iop_right || "";
        visionForm.color_vision_left = vision?.color_vision_left || "";
        visionForm.color_vision_right = vision?.color_vision_right || "";
        visionForm.lang_test_left = vision?.lang_test_left || "";
        visionForm.lang_test_right = vision?.lang_test_right || "";
        visionForm.fly_test_left = vision?.fly_test_left || "";
        visionForm.fly_test_right = vision?.fly_test_right || "";
        visionForm.amasler_grid_left = vision?.amasler_grid_left || "";
        visionForm.amasler_grid_right = vision?.amasler_grid_right || "";

        external_prescription.details =
          response.data?.record?.external_prescription_details || "";

        external_prescription.frame_details =
          response.data?.record?.external_prescription_frame_details || "";

        const triage = JSON.parse(response.data?.record?.triage || "{}");
        triageForm.bmi = triage?.bmi || "";
        triageForm.cpm = triage?.cpm || "";
        triageForm.pressure = triage?.pressure || "";
        triageForm.temperature = triage?.temperature || "";
        triageForm.pulse = triage?.pulse || "";
        triageForm.spo = triage?.spo || "";
        triageForm.lmp = triage?.lmp || "";
        height.value = triage?.height || "";
        weight.value = triage?.weight || "";

        caseNotesForm.complains = response.data?.record?.complains || "";
        caseNotesForm.notes = response.data?.record?.notes || "";
        caseNotesForm.findings = response.data?.record?.findings || "";
        caseNotesForm.diagnosis = response.data?.record?.diagnosis || "";

        selected_services.value = response.data?.record?.services.map(
          (srv) => ({
            value: `${srv.id}`,
            label: `${srv.item}`,
            description: `${srv.description}`,
          })
        );
        selected_diagnosis.value = response.data?.record?.diagnoses.map(
          (diag) => ({
            value: `${diag.id}`,
            label: `( ${diag.code} ) ${diag.name}`,
          })
        );

        const tempProducts = response.data?.record?.products?.map((prod) => ({
          value: `${prod.id}`,
          label: `${prod.name}`,
          description: `${prod.description}`,
        }));

        response.data?.record?.products?.map((md, idx) => {
          prescription.quantity[idx] = md.pivot.quantity;
          prescription.frequency[idx] = md.pivot.frequency;
          prescription.duration[idx] = md.pivot.duration;
          prescription.duration_option[idx] = md.pivot.duration_option;
          prescription.allow_generic[idx] = Boolean(md.pivot.allow_generic);
          prescription.meal_criteria[idx] = md.pivot.meal_criteria;
          prescription.timings[idx] = md.pivot.timings;
          prescription.eye_option[idx] = md.pivot.eye_option;
          prescription.note[idx] = md.pivot.note;
        });

        selected_products.value = tempProducts;

        response.data?.record?.uploads?.map((file) => {
          current_files.value.push(file);
          if (file.ext != "pdf") {
            current_files_preview_list.value.push(file.path);
          }
        });

        if (response.data?.record?.external_prescription != undefined)
          external_prescriptions.value = JSON.parse(
            response.data?.record?.external_prescription || ""
          );

        appointment.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const addExternalPrescriptionRow = () => {
      external_prescriptions.value.push({
        id: counter.value,
        name: "",
        quantity: "",
        frequency: "",
        duration: "",
        duration_option: "",
        allow_generic: "",
        meal_criteria: "",
        timings: "",
        eye_option: "",
        note: "",
      });

      counter.value += 1;
    };
    const removeExternalPrescriptionRow = (id) => {
      const index = external_prescriptions.value.findIndex(
        (item) => item.id === id
      );
      external_prescriptions.value.splice(index, 1);
      counter.value -= 1;
    };

    const viewPdf = (path) => {
      pdf_url.value = path;
      showPdfViewerModal.value = true;
    };

    const saveServices = (srvs) => {
      const tempServicesIds = reactive([]);

      srvs.map((srv) => {
        tempServicesIds.push(parseInt(srv.value));
      });

      formData.append("service_ids", JSON.stringify(tempServicesIds));
      saveRecord(route.params.id, formData)
        .then((data) => {
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
            switchPotision("5");
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          isLoading.value = false;
        });
    };
    const savePrescription = () => {
      const formData = new FormData();
      const prescription_data = [];
      selected_products.value.map((med, idx) => {
        med.quantity = prescription.quantity[idx];
        med.frequency = prescription.frequency[idx];
        med.duration = prescription.duration[idx];
        med.allow_generic = prescription.allow_generic[idx] ? true : false;
        med.meal_criteria = prescription.meal_criteria[idx];
        med.duration_option = prescription.duration_option[idx];
        med.timings = prescription.timings[idx];
        med.eye_option = prescription.eye_option[idx];
        med.note = prescription.note[idx];
        prescription_data.push(med);
      });

      formData.append("prescription", JSON.stringify(prescription_data));

      const external_prescription_data = [];
      external_prescriptions.value.map((med) => {
        external_prescription_data.push(med);
      });

      formData.append(
        "external_prescription",
        JSON.stringify(external_prescription_data)
      );
      formData.append(
        "external_prescription_details",
        external_prescription.details
      );
      formData.append(
        "external_prescription_frame_details",
        external_prescription.frame_details
      );

      saveRecord(route.params.id, formData)
        .then((data) => {
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          isLoading.value = false;
        });
    };

    const saveNotes = async (formEl) => {
      const tempDiagnosisIds = reactive([]);
      selected_diagnosis.value.map((diag) => {
        tempDiagnosisIds.push(parseInt(diag.value ?? diag));
      });

      if (!formEl) return;
      const formData = new FormData();
      formData.append("complains", caseNotesForm.complains);
      formData.append("notes", caseNotesForm.notes);
      formData.append("findings", caseNotesForm.findings);
      formData.append("diagnosis", caseNotesForm.diagnosis);
      formData.append("diagnosis_ids", JSON.stringify(tempDiagnosisIds));

      saveRecord(route.params.id, formData)
        .then((data) => {
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });

            //switchPotision("4");
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          isLoading.value = false;
        });
    };
    const submitTriageForm = async (formEl) => {
      if (!formEl) return;
      const formData = new FormData();
      formData.append("triage", JSON.stringify(triageForm));

      saveRecord(route.params.id, formData)
        .then((data) => {
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
            switchPotision("1");
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {});
    };
    const submitVisionForm = async (formEl) => {
      console.log(JSON.stringify(visionForm));
      if (!formEl) return;
      const formData = new FormData();
      formData.append("vision", JSON.stringify(visionForm));

      saveRecord(route.params.id, formData)
        .then((data) => {
          if (data.success) {
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
            switchPotision("2");
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {});
    };
    const loadServices = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchServices(entity_params);
        const tempServices = response.data.data.map((service) => ({
          value: `${service.id}`,
          label: `${service.item}`,
        }));
        services.value = tempServices;
      } catch (error) {
        console.log(error);
      }
    };

    const loadDiagnosis = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchDiagnosis(entity_params);

        const tempDiagnosis = response.data.data.map((diagnosis) => ({
          value: `${diagnosis.id}`,
          label: `( ${diagnosis.code} )${diagnosis.name}`,
        }));
        diagnosisList.value = tempDiagnosis;
      } catch (error) {
        console.log(error);
      }
    };

    const loadProducts = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchProducts(entity_params);
        const tempProducts = response.data.data.map((med) => ({
          value: {
            value: `${med.id}`,
            label: `${med.name}`,
            description: `${med.description}`,
          },
          label: `${med.name}`,
          description: `${med.description}`,
        }));

        response.data.data.map(() => {
          prescription.quantity.push(1);
          prescription.frequency.push(1);
          prescription.duration.push(1);
          prescription.duration_option.push("day");
          prescription.meal_criteria.push("either");
          prescription.timings.push("prn");
          prescription.eye_option.push("both");
          prescription.note.push("");
        });

        products.value = tempProducts;
      } catch (error) {
        console.log(error);
      }
    };
    const remove_file = (index) => {
      selectedFiles.value.splice(index, 1);
      preview_list.value.splice(index, 1);
      file_extention.value.splice(index, 1);
    };
    const delete_file = (index) => {
      if (current_files.value[index]) {
        deleteUpload(current_files.value[index].id)
          .then((data) => {
            if (data.success) {
              current_files.value.splice(index, 1);
              current_files_preview_list.value.splice(index, 1);
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });
            } else {
              Object.values(data.errors).forEach((val) =>
                ElNotification({
                  title: "Error",
                  message: val[0],
                  type: "error",
                })
              );
            }
          })
          .catch(function (err) {
            console.log(err.message);
          });
      }
    };
    const handleFileSelect = (event) => {
      // Get the selected files from the input element
      const files = event.target.files;

      // Loop through the selected files and add them to the array
      for (let i = 0; i < files.length; i++) {
        selectedFiles.value.push(files[i]);
        const reader = new FileReader();
        if (files[i]) {
          // Read the file as a URL
          reader.onload = (e) => {
            preview_list.value.push(e.target.result);
          };
          reader.readAsDataURL(files[i]);

          file_extention.value.push(
            files[i].name.slice(
              (Math.max(0, files[i].name.lastIndexOf(".")) || Infinity) + 1
            )
          );
        }
        uploadPerc.value.push(0);
        uploadStatus.value.push("");
      }
    };
    const handleFileUpload = () => {
      for (let i = 0; i < selectedFiles.value.length; i++) {
        uploadPerc.value[i] = 0;
        var data = new FormData();
        data.append("attachment", selectedFiles.value[i]);
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            uploadPerc.value[i] = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };

        api
          .post(`/records/attachments/${route.params.id}`, data, config)
          .then(function () {
            uploadStatus.value[i] = "success";
            selectedFiles.value.splice(i);
            switchPotision("3");
          })
          .catch(function (err) {
            uploadStatus.value[i] = "warning";
            console.log(err.message);
          });
      }
    };
    const getAppointments = async (filter, page) => {
      isAppoimentsLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchAppointments(entity_params, page);
        doctor_appointments.value = response.data.data;
        isAppoimentsLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isAppoimentsLoading.value = false;
      }
    };

    onMounted(() => {
      const user = JSON.parse(localStorage.getItem("user"));
      preview_list.value = [];
      current_files.value = [];
      loadServices("", 1);
      loadProducts("", 1);
      fetchAppointmentInfo();
      const entity_params = ref();
      entity_params.value = {
        doctor_id: user.id,
        appointment_status: "awaiting",
        date: moment().format("YYYY-MM-DD"),
      };
      getAppointments(entity_params, 1);
      addExternalPrescriptionRow();
    });
    const switchPotision = (position) => {
      activeTabName.value = position;
    };
    const printPrescriptionPdf = async () => {
      try {
        savePrescription();
        ElMessage({
          type: "info",
          message:
            "Your prescription will be downloading shortly. Please wait...",
        });
        await downloadPrescriptionPdf(route.params.id)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "appointments_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const handleQueueDropdownChange = async (appmt) => {
      fetchAppointmentInfo(appmt.id);
    };
    return {
      handleQueueDropdownChange,
      getAppointments,
      switchPotision,
      loadProducts,
      loadServices,
      loadDiagnosis,
      fetchAppointmentInfo,
      height,
      weight,
      calculateBMI,
      submitTriageForm,
      triageFormRef,
      triageForm,
      submitVisionForm,
      caseNotesFormRef,
      caseNotesForm,
      visionFormRef,
      visionForm,
      notes,
      saveNotes,
      diagnosis,
      appointment,
      selected_services,
      selected_diagnosis,
      selected_products,
      products,
      services,
      saveServices,
      diagnosisList,
      meal_time_options,
      duration_options,
      eye_options,
      timings_options,
      isLoading,
      prescription,
      savePrescription,
      selectedFiles,
      preview_list,
      handleFileSelect,
      handleFileUpload,
      dialogImageUrl,
      dialogVisible,
      uploadPerc,
      uploadStatus,
      current_files,
      current_files_preview_list,
      Delete,
      Plus,
      remove_file,
      delete_file,
      file_extention,
      showPdfViewerModal,
      pdf_url,
      viewPdf,
      external_prescriptions,
      addExternalPrescriptionRow,
      removeExternalPrescriptionRow,
      external_prescription,
      activeTabName,
      printPrescriptionPdf,
      doctor_appointments,
      isAppoimentsLoading,
    };
  },
};
</script>
<style scoped>
.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.main_card {
  min-height: 90vh;
}
dt {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: #495057;
  margin-bottom: 0.5rem;
}
dl {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 20px 0;
  padding: 0 16px;
}
dd {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #495057;
}
.sidebar dl {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 30px 0 10px;
}
.contant-info dl {
  display: grid;
  grid-template-columns: 30% 70%;
}
.side-glance {
  max-height: 135px;
  width: 100%;
  object-fit: cover;
}
.sidebar button {
  margin: 20px auto;
  display: block;
}
</style>
