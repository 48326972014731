<template>
  <div class="modal-overlay">
    <div class="card">
      <div class="card-header pb-0 ">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-stock-o text-primary text-md opacity-10"></i>
          <h6>Stock</h6>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2" v-if="stock.data.length > 0">
        <div v-if="stock.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Buying Price
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Selling Price
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Purchased Stock
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Current Stock
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Created Date
                </th>

                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in stock.data" :key="index">
                <td>
                  <h6 class="mb-0 text-sm text-capitalize ps-3">
                    {{ item.unit_buying_price }}
                  </h6>
                </td>
                <td>
                  <h6 class="mb-0 text-sm text-capitalize ps-3">
                    {{ item.unit_selling_price }}
                  </h6>
                </td>
                <td>
                  <span class="mb-0 text-sm text-capitalize ps-3">
                    {{ item.purchase_stock }}</span
                  >
                </td>
                <td>
                  <span class="mb-0 text-sm text-capitalize ps-3">
                    {{ item.current_stock }}</span
                  >
                </td>
                <td>
                  <span
                    class="badge badge-sm mb-0 text-capitalize ps-3"
                    :class="getStatusColor(item.state)"
                  >
                    {{ item.state }}</span
                  >
                </td>
                <td>
                  <span class="mb-0 text-sm text-capitalize ps-3">
                    {{ dateTime(item.created_at) }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          v-if="
                            hasPermission('manage.stock') &&
                            item.state == 'closed'
                          "
                          @click="OpenStock(item.id)"
                        >
                          Open Stock
                        </el-dropdown-item>
                        <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          v-if="
                            hasPermission('manage.stock') &&
                            item.state == 'open'
                          "
                          @click="CloseStock(item.id)"
                        >
                          Close Stock
                        </el-dropdown-item>

                        <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          v-if="hasPermission('delete.stock')"
                          @click="deleteStock(item.id)"
                        >
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
          :totalPages="Pagination.total_pages"
          :perPage="Pagination.per_page"
          :currentPage="Pagination.current_page"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ref, h, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  removeStock,
  fetchProductStock,
  openStock,
  closeStock,
} from "../../api/services/inventory";
import Pagination from "./Pagination.vue";
import EmptyStateTable from "./EmptyTableState.vue";

const isLoading = ref(false);
const stock = ref([]);
export default {
  name: "stock-list",

  components: {
    Pagination,
    EmptyStateTable,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const pid = props?.product?.id || 0;
    const Pagination = reactive({
      current_page: 1,
      total_pages: 0,
      per_page: 0,
    });

    const onPageChange = (page) => {
      console.log(page);
    };

    const getStatusColor = (status) => {
      switch (status) {
        case "closed":
          return "bg-gradient-warning";
        case "open":
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const OpenStock = async (stock_id) => {
      try {
        isLoading.value = true;
        const { message, success } = await openStock(stock_id);

        if (success) {
          ElMessage({
            type: "success",
            message: message,
          });
          getProductStock();
        } else {
          ElMessage({
            type: "error",
            message: message,
          });
        }
      } catch (error) {
        ElMessage({
          type: "error",
          message: error.message,
        });
      } finally {
        isLoading.value = false;
      }
    };
    const CloseStock = async (stock_id) => {
      try {
        isLoading.value = true;
        const { message, success } = await closeStock(stock_id);

        if (success) {
          ElMessage({
            type: "success",
            message: message,
          });
          getProductStock();
        } else {
          ElMessage({
            type: "error",
            message: message,
          });
        }
      } catch (error) {
        ElMessage({
          type: "error",
          message: error.message,
        });
      } finally {
        isLoading.value = false;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const deleteStock = async (stock_id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "This stock will permanently delete. Continue?"
        ),

        "Delete Stock",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeStock(stock_id);

          if (success) {
            ElMessage({
              type: "success",
              message: message,
            });
            getProductStock();
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };
    const getProductStock = async () => {
      isLoading.value = true;
      try {
        const response = await fetchProductStock(pid);
        stock.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      onPageChange,
      Pagination,
      dateTime,
      getStatusColor,
      OpenStock,
      CloseStock,
      isLoading,
      stock,
      props,
      getProductStock,
      deleteStock,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
  mounted() {
    this.getProductStock();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  min-width: 800px;
  max-width: 900px;
  max-height: 700px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
