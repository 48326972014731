<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="row">
          <div
            class="top-0 my-auto text-center col-6 d-lg-flex d-none pe-0 justify-content-center flex-column"
            style="height: 100vh"
          >
            <div
              class="position-relative bg-gradient-primary h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/sign-in-side-image.jpg') + ')',
                backgroundSize: 'cover',
              }"
            >
              <span class="mask bg-gradient-secondary opacity-4"></span>
              <h4 class="mt-5 text-white font-weight-bolder position-relative">
                "Here to Simplify Your Hospital’s Management, One Click at a
                Time"
              </h4>
              <p class="text-white position-relative">
                A hospital and clinic management software which enables you to
                run your core financial, clinical, and operational processes
              </p>
            </div>
          </div>
          <div
            class="col-xl-6 col-lg-6 col-md-6 d-flex flex-column mx-auto my-auto align-items-end"
          >
            <div class="card card-plain">
              <div class="pb-0 card-header text-start">
                <h4 class="font-weight-bolder">Sign Up</h4>
                <p class="mb-0">It's free and only takes a minute</p>
              </div>
              <div class="card-body">
                <el-form
                  ref="signupFormRef"
                  :model="signupForm"
                  :rules="rules"
                  label-width="auto"
                  class="demo-ruleForm"
                  size="large"
                  status-icon
                  :scroll-to-error="true"
                  label-position="top"
                  :hide-required-asterisk="true"
                >
                  <div class="row">
                    <h6 class="mb-1 text-dark text-sm">Admin Details</h6>
                    <hr class="horizontal dark m-3" />
                    <div class="col-md-4">
                      <el-form-item label="First Name" prop="first_name">
                        <el-input v-model="signupForm.first_name" />
                      </el-form-item>
                    </div>
                    <div class="col-md-4">
                      <el-form-item label="Middle Name" prop="middle_name">
                        <el-input v-model="signupForm.middle_name" />
                      </el-form-item>
                    </div>

                    <div class="col-md-4">
                      <el-form-item label="Last Name" prop="last_name">
                        <el-input v-model="signupForm.last_name" />
                      </el-form-item>
                    </div>

                    <div class="col-md-6 mb-3">
                      <label class="el-form-item__label">Phone Number</label>
                      <vue-tel-input
                        v-model="signupForm.phone"
                        :value="signupForm.phone"
                        @validate="checkMobileNumber"
                        v-bind="phoneBindingProps"
                      ></vue-tel-input>
                    </div>
                    <div class="col-md-6">
                      <el-form-item label="Email Address" prop="email">
                        <el-input v-model="signupForm.email" />
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item label="Password" prop="password">
                        <el-input
                          v-model="signupForm.password"
                          type="password"
                          :show-password="true"
                        />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <h6 class="mb-1 text-dark text-sm">Business Details</h6>
                    <hr class="horizontal dark m-3" />
                    <div class="col-md-6">
                      <el-form-item label="Business Name" prop="business_name">
                        <el-input v-model="signupForm.business_name" />
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Business Email Address"
                        prop="business_email"
                      >
                        <el-input v-model="signupForm.business_email" />
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Business Address"
                        prop="business_address"
                      >
                        <el-input
                          v-model="signupForm.business_address"
                          :rows="2"
                          type="textarea"
                          placeholder="P.O Box. 0000, Nairobi,Kenya"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Business Phone No"
                        prop="business_phone"
                      >
                        <el-input v-model="signupForm.business_phone" />
                      </el-form-item>
                    </div>
                  </div>

                  <el-button
                    type="success"
                    class="px-5 d-block my-3 mx-auto"
                    :loading="isLoading"
                    @click="submitForm(signupFormRef)"
                  >
                    Sign Up
                  </el-button>
                </el-form>
              </div>
              <div class="px-1 pt-0 text-center card-footer px-lg-2">
                <p class="mx-auto mb-4 text-sm">
                  Already have an account?
                  <router-link
                    to="/signin"
                    class="text-success text-gradient font-weight-bold"
                    >Sign In</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { reactive, ref } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { signup } from "../api/services/authentication";
import { useRouter } from "vue-router";

const body = document.getElementsByTagName("body")[0];
const signupFormRef = ref();
const isLoading = ref(false);
const phoneBindingProps = {
  mode: "international",
  styleClasses: "has-default",
  defaultCountry: "KE",
  onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
  inputOptions: {
    showDialCode: false,
    placeholder: "Mobile Number",
    name: "phone",
  },
};
export default {
  setup() {
    const router = useRouter();
    const signupForm = reactive({
      email: "",
      password: "",
      role: "admin",
      first_name: "",
      middle_name: "",
      last_name: "",
      phone: "",
      business_name: "",
      business_phone: "",
      business_email: "",
      business_address: "",
    });
    const checkMobileNumber = (event) => {
      if (event.valid) {
        signupForm.phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        signupForm.phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };
    const rules = reactive({
      first_name: [
        {
          required: true,
          message: "Please input your first name",
          trigger: ["blur", "change"],
        },
      ],
      middle_name: [
        {
          required: true,
          message: "Please input your middle name",
          trigger: ["blur", "change"],
        },
      ],
      last_name: [
        {
          required: true,
          message: "Please input your last name",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        {
          required: true,
          message: "Please input your phone number",
          trigger: ["blur", "change"],
        },
      ],
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          const formData = new FormData();

          formData.append("first_name", signupForm.first_name);
          formData.append("middle_name", signupForm.middle_name);
          formData.append("last_name", signupForm.last_name);
          formData.append("phone", signupForm.phone);
          formData.append("email", signupForm.email);
          formData.append("role", "admin");
          formData.append("password", signupForm.password);
          formData.append("password_confirmation", signupForm.password);
          formData.append("business_name", signupForm.business_name);
          formData.append("business_phone", signupForm.business_phone);
          formData.append("business_email", signupForm.business_email);
          formData.append("business_address", signupForm.business_address);
          formData.append("withholding_rate", "5");
          formData.append("vat_rate", "16");

          signup(formData)
            .then((response) => {
              if (response.status == 200) {
                ElMessage({
                  message: response.data.message,
                  type: "success",
                });
                router.replace({
                  path: `/welcome`,
                  query: {
                    name: signupForm.first_name,
                    email: signupForm.email,
                  },
                });
              } else if (response.status == 422) {
                let error = Object.values(response.data.errors);

                console.log(error);
                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return {
      isLoading,
      resetForm,
      submitForm,
      rules,
      signupForm,
      signupFormRef,
      phoneBindingProps,
      checkMobileNumber,
    };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
