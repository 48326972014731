<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading">
          <Loader />
        </div>
        <employers-table 
          :employers="employers" 
          :refresh="fetchData" 
          :searchQuery="searchTerm"
          v-else />
      </div>
    </div> 
  </div>
</template> 

<script>
import EmployersTable from "./components/EmployersTable.vue";
import Loader from "./components/Loader.vue";
import { fetchEmployers } from "../api/services/services";
import { ref } from "vue";

const isLoading = ref(false);
const employers = ref([]);
const searchTerm = ref("");

export default {
  name: "Employers",
  components: {
    EmployersTable,
    Loader,
  },
  setup() {
    const fetchData = async (search, page) => {
      const entity_params = ref();
      entity_params.value = { search_query: search };
      isLoading.value = true;
      try {
        const response = await fetchEmployers(entity_params, page);
        employers.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      fetchData,
      employers,
      isLoading,
      searchTerm
    };
  },
  mounted() {
    this.fetchData("", 1);
  },
};
</script>
