<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="ni ni-calendar-grid-58 text-primary text-md opacity-10"></i>
        <h6>Appointments</h6>
      </div>
      <div class="cta">
        <el-dropdown trigger="click" v-if="selected.length > 1">
          <span class="el-dropdown-link">
            <el-button class="me-2" size="large" circle>
              <i class="fa fa-ellipsis-v text-primary"></i>
            </el-button>
          </span>
          <template #dropdown>
            <el-dropdown-item
              class="text-danger font-weight-bold text-xs"
              v-if="hasPermission('delete.appointment')"
              @click="ConfirmDeleteAppointments()"
            >
              Delete selected Appointments
            </el-dropdown-item>
            <el-dropdown-item
              class="text-primary font-weight-bold text-xs"
              @click="UpdateStatusSelectedAppointments()"
            >
              Update Selected Appointments Status
            </el-dropdown-item>
          </template>
        </el-dropdown>
        <el-button-group>
          <FilterTables
            :show_search_filter="true"
            :show_date_filter="true"
            :show_doctor_filter="true"
            :show_patient_filter="true"
            :show_appointment_type_filter="true"
            date_range_label="Appointment Date"
            search_label="Search appointment records"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
          />
          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
        <button
          type="button"
          class="btn mb-0 btn-primary btn-xs ms-3 p-2"
          @click="addAppointment"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
          Appointment
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="appointments.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="appointments.data.length > 0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
              >
                <input type="checkbox" v-model="selectAll" />
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-uppercase"
              >
                Patient Name
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Appointment Number
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Appointment Type
              </th>
              <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Doctor's Name
              </th>
              <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Appointment Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in appointments.data"
              :key="item.id + index"
            >
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <router-link
                          v-if="hasPermission('view.appointment')"
                          :to="{ path: `/appointment/${item.id}/summary` }"
                          class="text-secondary font-weight-bold text-xs"
                          >View Appointment</router-link
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('update.appointment')"
                        @click="editAppointment(item)"
                      >
                        Edit
                      </el-dropdown-item>

                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('view.appointment')"
                        @click="printPrescriptionPdf(item.id)"
                      >
                        Print Prescription
                      </el-dropdown-item>

                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="
                          item.status === 1 &&
                          (role() === 'admin' || role() === 'receptionist')
                        "
                        @click="checkinPatient(item.id)"
                      >
                        Check In</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="
                          item.status === 2 &&
                          (role() === 'admin' || role() === 'receptionist')
                        "
                        @click="checkoutPatient(item.id)"
                      >
                        Check Out</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        v-if="hasPermission('update.invoice')"
                        @click="
                          $router.push(
                            `/accounts/appointment/consumables/${item.id}`
                          )
                        "
                      >
                        Manage Consumables
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="text-danger font-weight-bold text-xs"
                        v-if="hasPermission('delete.appointment')"
                        @click="ConfirmDeleteAppointment(item.id)"
                      >
                        Delete Appointment
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
              <td>
                <input
                  type="checkbox"
                  v-model="selected"
                  :value="item.id"
                  number
                />
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div
                      v-if="item.appointment_type == 'inpatient'"
                      class="avatar avatar-sm me-3 bg-gradient-primary"
                    >
                      {{ getInitials(item?.patient?.name) }}
                    </div>
                    <div
                      v-else
                      class="avatar avatar-sm me-3 bg-gradient-success"
                    >
                      {{ getInitials(item?.patient?.name) }}
                    </div>
                    <!-- <img
                      src="../../assets/img/team-2.jpg"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    /> -->
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item?.patient?.name }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ item?.patient?.phone }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <h6 class="mb-0 text-sm">{{ item.appointment_number }}</h6>

                <span
                  v-if="item.appointment_status != null"
                  class="badge badge-sm"
                  :class="
                    getAppointmentStatusColor(item.appointment_status.status)
                  "
                  >{{ item.appointment_status.status }}</span
                >
              </td>
              <td>
                <h6 class="mb-0 text-sm">{{ item.appointment_type }}</h6>
              </td>
              <td>
                <h6 class="mb-0 text-sm">{{ item.doctor?.name }}</h6>
              </td>
              <td class="align-middle text-center text-sm">
                <span  v-if="item.appointment_status != null"
                  class="badge badge-sm"
                  :class="getStatusColor(item.appointment_status.status)"
                  >{{ item.appointment_status.status }}</span
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  formatDate(item.appointment_date)
                }}</span>
                <p class="text-xs text-secondary mb-0">
                  {{ item.appointment_time }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
  <AppointmentStatusForm
    v-if="showAppointmentStatusModal"
    @close-modal="showAppointmentStatusModal = false"
    :appointment="appointmentDetails"
    :appointments="selected"
    :multiple="isMultiple"
    @refresh-appointments="refresh"
  />
  <AppointmentModal
    v-if="showAppointmentModal"
    @close-appointment-modal="showAppointmentModal = false"
    @open-appointment-modal="showAppointmentModal = true"
    :appointment="appointmentDetails"
    @refresh-appointments="refresh"
  />
</template>

<script>
import { ref } from "vue";
import AppointmentStatusForm from "../../components/Modals/AppointmentStatusForm.vue";
import AppointmentModal from "../../components/Modals/AppointmentForm.vue";
import FilterTables from "./FilterTables.vue";
import { formatDate, formatTime } from "../../utils/date";
import { ElMessage, ElMessageBox } from "element-plus";
import EmptyStateTable from "../components/EmptyTableState.vue";
import Pagination from "./Pagination.vue";
import { h, reactive, computed } from "vue";
import {
  checkInAppointment,
  checkOutAppointment,
  deleteAppointment,
  downloadAppointmentExcel,
  downloadAppointmentPdf,
  deleteAppointments,
  downloadPrescriptionPdf,
} from "../../api/services/appointments";

const showAppointmentModal = ref(false);
const appointmentDetails = ref({});
const isLoading = ref(false);
const filter_params = ref({});
const selected = ref([]);
const showAppointmentStatusModal = ref(false);
const isMultiple = ref(false);
export default {
  name: "appointments-table",
  props: {
    appointments: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  components: {
    AppointmentStatusForm,
    Pagination,
    AppointmentModal,
    EmptyStateTable,
    FilterTables,
  },

  setup(props) {
    const total_records = props?.appointments?.total || 0;
    const per_page = props?.appointments?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }
    const selectAll = computed({
      get() {
        if (
          props?.appointments?.data &&
          props?.appointments?.data?.length > 0
        ) {
          // A users array exists with at least one item
          let allChecked = true;

          for (const appointment of props?.appointments?.data) {
            if (!selected.value.includes(appointment.id)) {
              allChecked = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];

        if (value) {
          props?.appointments?.data?.forEach((appointment) => {
            checked.push(appointment.id);
          });
        }

        selected.value = checked;
      },
    });
    const Pagination = reactive({
      current_page: props?.appointments?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
    const checkinPatient = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The patient will be checked in. Continue?"
        ),

        "Check In Patient",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await checkInAppointment(id);

          if (success) {
            props.refresh("", 1);
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (status) => {
      switch (status) {
        case "awaiting":
          return "bg-gradient-warning";
        case "reviewed":
          return "bg-gradient-success";
        case "cancelled":
          return "bg-gradient-danger";
        default:
          return null;
      }
    };
    const getAppointmentStatusColor = (status) => {
      switch (status) {
        case "no_charge":
          return "bg-gradient-warning";
        case "active":
          return "bg-gradient-success";
        case "cancelled":
          return "bg-gradient-danger";
        default:
          return null;
      }
    };
    const editAppointment = (appointment) => {
      appointmentDetails.value = appointment;
      showAppointmentModal.value = true;
    };
    const addAppointment = () => {
      appointmentDetails.value = {};
      showAppointmentModal.value = true;
    };

    const UpdateStatusSelectedAppointments = () => {
      isMultiple.value = true;
      showAppointmentStatusModal.value = true;
    };

    const ConfirmDeleteAppointments = async () => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The selected appointments will be trashed. This action cannot be undone. Continue?"
        ),

        "Trash Appointments",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;

            const formData = new FormData();
            formData.append("invoice_ids", JSON.stringify(selected.value));
            const { message, success } = await deleteAppointments(formData);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    const ConfirmDeleteAppointment = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The appoitment will be trashed. Continue?"
        ),

        "Trash Appointment",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await deleteAppointment(id);

            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    };
    const checkoutPatient = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The patient will be checked out. Continue?"
        ),

        "Check Out Patient",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await checkOutAppointment(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };
    const printPrescriptionPdf = async (appointment_id) => {
      try {
        ElMessage({
          type: "info",
          message:
            "Your prescription will be downloading shortly. Please wait...",
        });
        await downloadPrescriptionPdf(appointment_id)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "appointments_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const downloadPdf = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadAppointmentPdf(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "appointments_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const downloadExcel = async () => {
      try {
        ElMessage({
          type: "info",
          message: "Your report will be downloading shortly. Please wait...",
        });
        await downloadAppointmentExcel(filter_params)
          .then((Response) => {
            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/vnd.ms-excel",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "appointments_" + Math.round(+new Date() / 1000) + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const role = () => {
      return localStorage.getItem("role");
    };
    return {
      ConfirmDeleteAppointment,
      filter_params,
      _filterCallBack,
      onPageChange,
      Pagination,
      showAppointmentModal,
      formatDate,
      formatTime,
      getInitials,
      getStatusColor,
      checkinPatient,
      isLoading,
      role,
      checkoutPatient,
      appointmentDetails,
      editAppointment,
      addAppointment,
      downloadPdf,
      downloadExcel,
      UpdateStatusSelectedAppointments,
      ConfirmDeleteAppointments,
      showAppointmentStatusModal,
      isMultiple,
      selected,
      selectAll,
      getAppointmentStatusColor,
      printPrescriptionPdf,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

h6 {
  margin: 0;
}

.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
