<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>

        <payments-table :payments="payments" :refresh="fetchData" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsTable from "./components/PaymentsTable.vue";
import Loader from "./components/Loader.vue";
import { fetchPayments } from "../api/services/accounts";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const payments = ref([]);

export default {
  name: "payments",
  components: {
    PaymentsTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      try {
        const response = await fetchPayments(filter, page);
        payments.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      payments,
      isLoading,
    };
  },
  mounted() {
    this.fetchData("",1);
  },
};
</script>
