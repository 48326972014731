import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

api.interceptors.request.use((config) => {
  config.headers.Accept = "application/json";

  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const organization = JSON.parse(localStorage.getItem("organization"));

  if (organization != undefined) {
    config.headers.set("organization-id", organization.id || 0);
  }
  return config;
});

export { api };
