<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>

        <consumables-table
          :consumables="consumables"
          :filter="filter"
          :refresh="fetchData"
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import ConsumablesTable from "./components/ConsumablesTable.vue";
import Loader from "./components/Loader.vue";
import { fetchConsumables } from "../api/services/accounts";
import { ref } from "vue";

const isLoading = ref(false);
const consumables = ref([]);
export default {
  name: "consumables_report",
  components: {
    ConsumablesTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;

      filter.value.has_consumables = true;
      try {
        const response = await fetchConsumables(filter, page);
        consumables.value = response.data;
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      fetchData,
      consumables,
      isLoading,
    };
  },
  mounted() {
    const filter_params = ref({});

    this.fetchData(filter_params, 1);
  },
};
</script>
