import { api } from "../..";

export const fetchHospitals = async (filter, page) => {
  var url = "hospitals?orderBy=name&sortedBy=asc";

  if (filter.value != undefined && filter.value.search_query)
    url = url + "&searchFields=name:like;&search=" + filter.value.search_query;

  if (page) url = url + "&page=" + page;
  url = url.replace("hospitals?&", "hospitals?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchAllHospitals = async () => {
  let response = await api
    .get("list/all/hospitals?orderBy=name&sortedBy=asc")
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createHospital = async (postBody) => {
  let response = await api
    .post("hospitals", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateHospital = async (body) => {
  let response = await api
    .patch("hospitals/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeHospital = async (id) => {
  let response = await api
    .delete(`hospitals/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchServices = async (filter, page) => {
  var url = "services?orderBy=item&sortedBy=asc";

  if (filter.value != undefined && filter.value.search_query)
    url = url + "&searchFields=item:like;&search=" + filter.value.search_query;

  if (filter.value != undefined && filter.value.service_id)
    url = url + "&service_id=" + filter.value.service_id;

  if (page) url = url + "&page=" + page;
  url = url.replace("services?&", "services?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};


export const createService = async (postBody) => {
  let response = await api
    .post("services", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateService = async (body) => {
  let response = await api
    .patch("services/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const removeService = async (id) => {
  let response = await api
    .delete(`services/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const updateServiceItem = async (postBody) => {
  let response = await api
    .post("service_items", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchAllergies = async (search, page) => {
  var url = "allergies?orderBy=name&sortedBy=asc";
  if (search) url = url + "&searchFields=name:like;search=" + search;
  if (page) url = url + "&page=" + page;
  url = url.replace("allergies?&", "allergies?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createAllergy = async (postBody) => {
  let response = await api
    .post("allergies", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateAllergy = async (body) => {
  let response = await api
    .patch("allergies/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const removeAllergy = async (id) => {
  let response = await api
    .delete(`allergies/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchEmployers = async (filter, page) => {
  var url = "employers?orderBy=name&sortedBy=asc";

  if (filter.value != undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=name:like;contact_phone:like;contact_email:like;contact_address:like;&search=" +
      filter.value.search_query;

  if (page) url = url + "&page=" + page;
  url = url.replace("employers?&", "employers?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createEmployer = async (postBody) => {
  let response = await api
    .post("employers", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const updateEmployer = async (body) => {
  let response = await api
    .patch("employers/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeEmployer = async (id) => {
  let response = await api
    .delete(`employers/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchInsurances = async (filter, page) => {
  var url = "insurances?orderBy=name&sortedBy=asc";
  if (filter.value != undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=name:like;contact_phone:like;contact_email:like;contact_address:like;&search=" +
      filter.value.search_query;

  if (page) url = url + "&page=" + page;
  url = url.replace("insurances?&", "insurances?");

  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createInsurance = async (postBody) => {
  let response = await api
    .post("insurances", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const updateInsurance = async (body) => {
  let response = await api
    .patch("insurances/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeInsurance = async (id) => {
  let response = await api
    .delete(`insurances/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createPatientInsurance = async (postBody) => {
  let response = await api
    .post("patient_insurances", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updatePatientInsurance = async (body) => {
  let response = await api
    .patch("patient_insurances/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchMedicines = async (search, page) => {
  var url = "medicines?orderBy=name&sortedBy=asc";
  if (search) url = url + "&search=" + search;
  if (page) url = url + "&page=" + page;
  url = url.replace("medicines?&", "medicines?");
  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createMedicine = async (postBody) => {
  let response = await api
    .post("medicines", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const updateMedicine = async (body) => {
  let response = await api
    .patch("medicines/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeMedicine = async (id) => {
  let response = await api
    .delete(`medicines/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchPaymentModes = async () => {
  let response = await api
    .get("payment_modes")
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const createPayment = async (postBody) => {
  let response = await api
    .post("payments", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const updatePayment = async (body) => {
  let response = await api
    .patch("payments/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchInsuranceSummary = async (filter, page) => {
  var url = "insurances/summary/report?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("report?&", "report?");

  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};
export const downloadInsuranceSummaryPdf = async (filter, page) => {
  var url = "insurances/summary/pdf?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("pdf?&", "pdf?");

  let response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadInsuranceSummaryExcel = async (filter, page) => {
  var url = "insurances/summary/excel?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("excel?&", "excel?");
  let response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchServicesReport = async (filter, page) => {
  var url = "services/summary/report?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("report?&", "report?");

  let response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};
export const downloadServiceReportPdf = async (filter, page) => {
  var url = "services/summary/pdf?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("pdf?&", "pdf?");
  let response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadServiceReportExcel = async (filter, page) => {
  var url = "services/summary/excel?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("excel?&", "excel?");
  let response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};


