<template>
  <div class="card">
    <div v-if="isLoading"><Loader /></div>
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-code text-primary text-md opacity-10"></i>
        <h6>Templates</h6>
      </div>
      <div class="cta">
        <el-button-group>
          <el-button
              color="#626aef"
              @click="$router.push(`/hr/new/template`)"
              v-if="hasPermission('create.invoice')"
          ><i
              class="fa fa-id-badge text-white me-1"
              aria-hidden="true"
          ></i
          >New Template</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="templates.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="templates.data.length > 0">
        <table class="table align-items-left mb-0">
          <thead>
          <tr>
            <th
                class="p-2 text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Action
            </th>
            <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
            >
              Type
            </th>

            <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
            >
              Template
            </th>

            <th
                class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
            >
              Date Created
            </th>

          </tr>
          </thead>

          <tbody>
          <tr v-for="(item, index) in templates.data" :key="item.id + index">
            <td class="align-middle text-center">
              <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                        class="text-default font-weight-bold text-xs"
                        v-if="
                          hasPermission('update.invoice')
                        "
                        @click="
                          $router.push(`/hr/edit/template/${item.id}`)
                        "
                    >
                      Edit
                    </el-dropdown-item>
                    <el-dropdown-item
                        class="text-danger font-weight-bold text-xs"
                        v-if="hasPermission('delete.invoice')"
                        @click="ConfirmDeleteTemplate(item.id)"
                    >
                      Delete
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </td>

            <td>
              <div class="d-flex px-2 py-1">
                <div>
                  <div class="avatar avatar-sm me-3 bg-gradient-primary">
                    {{ getInitials(item?.type) }}
                  </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <p class="text-xs text-secondary mb-0">
                    {{ item?.type }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs text-secondary mb-0">
                {{ truncateString(item.template, 50) }}
              </p>
            </td>

            <td>
              <p class="text-xs text-secondary mb-0">
                {{ dateTime(item.created_at) }}
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
          :totalPages="Pagination.total_pages"
          :perPage="Pagination.per_page"
          :currentPage="Pagination.current_page"
          @pagechanged="onPageChange"
      />
    </div>
  </div>

</template>
<script>
import Pagination from "./Pagination.vue";
import moment from "moment";
import { ElMessage, ElMessageBox } from "element-plus";
import { h, ref, reactive } from "vue";
import EmptyStateTable from "../components/EmptyTableState.vue";
import Loader from "../components/Loader.vue";
import { deleteTemplate } from "../../api/services/templates";
const filter_params = ref({});
const isLoading = ref(false);
export default {
  name: "templates-table",
  props: {
    templates: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },

  components: {
    Pagination,
    EmptyStateTable,
    Loader,
  },

  setup(props) {

    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-warning";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };

    const total_records = props?.templates?.total || 0;
    const per_page = props?.templates?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.templates?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });


    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(filter_params, page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const ConfirmDeleteTemplate = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "You are about to trash an template. This action cannot be undone. Continue?"
          ),

          "Trash Template",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      )
          .then(async () => {
            try {
              isLoading.value = true;
              const { message, success } = await deleteTemplate(id);

              if (success) {
                props.refresh();
                ElMessage({
                  type: "success",
                  message: message,
                });
              } else {
                ElMessage({
                  type: "error",
                  message: message,
                });
              }
            } catch (error) {
              ElMessage({
                type: "error",
                message: error.message,
              });
            } finally {
              isLoading.value = false;
            }
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "Delete canceled",
            });
          });
    };

    function truncateString(inputString, maxLength) {
      if (inputString.length <= maxLength) {
        return inputString;
      } else {
        return inputString.slice(0, maxLength - 3) + '...';
      }
    }
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    return {
      truncateString,
      ConfirmDeleteTemplate,
      isLoading,
      filter_params,
      _filterCallBack,
      onPageChange,
      Pagination,
      dateTime,
      getStatusColor,
      getInitials
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
</style>
