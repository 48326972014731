<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-lock text-primary text-md opacity-10"></i>
        <h6>Users</h6>
      </div>
      <div class="cta">
        <button
          class="btn mb-0 btn-success btn-xs ms-auto"
          @click="newUser"
          v-if="hasPermission('create.user')"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New User
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="users.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-if="users.length > 0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Role
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Email
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in users" :key="item.id + index">
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-if="hasPermission('view.user')">
                        <router-link
                          to="#"
                          class="text-secondary font-weight-bold text-xs"
                          >View Profile</router-link
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        v-if="hasPermission('update.user')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="editUser(item)"
                      >
                        Edit
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="item.status !== 0 && hasPermission('delete.user')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="disableUser(item.id)"
                      >
                        Deactivate
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="item.status === 0 && hasPermission('delete.user')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="activateUser(item.id)"
                      >
                        Activate
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="hasPermission('delete.user')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="removeUserConfirm(item.id)"
                      >
                        Remove from clinic
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="hasPermission('update.user')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="addUserToClinic(item)"
                      >
                        Add to a clinic
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="hasPermission('update.user')"
                        class="text-secondary font-weight-bold text-xs"
                        @click="changeUserRole(item)"
                      >
                        Change Role
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{ getInitials(item.name) }}
                    </div>
                    <!-- <img
                      src="../../assets/img/team-2.jpg"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    /> -->
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.name }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ item.phone }}
                    </p>
                  </div>
                </div>
              </td>
              <td>{{ item.role[0]?.name }}</td>
              <td>
                <h6 class="mb-0 text-sm">{{ item.email }}</h6>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm"
                  :class="getStatusColor(item.status)"
                  >{{ item.status === 1 ? "Active" : "InActive" }}</span
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  formatDate(item.created_at)
                }}</span>
                <p class="text-xs text-secondary mb-0">
                  {{ formatTime(item.created_at) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <UserForm
    v-if="showUserModal"
    @close-user-modal="showUserModal = false"
    :user="userDetails"
    @refresh-users="refresh"
  />
  <AddUserToClinicForm
    v-if="showAddUserToClinicModal"
    @close-add-user-modal="showAddUserToClinicModal = false"
    :user_details="userDetails"
    @refresh-users="refresh"
  />
  <ChangeRoleForm
    v-if="showChangeRoleFormModal"
    @close-add-user-modal="showChangeRoleFormModal = false"
    :user_details="userDetails"
    @refresh-users="refresh"
  />
</template>

<script>
import { ref } from "vue";
import UserForm from "../../components/Modals/UserForm.vue";
import AddUserToClinicForm from "../../components/Modals/AddUserToClinicForm.vue";
import ChangeRoleForm from "../../components/Modals/ChangeRoleForm.vue";
import { formatDate, formatTime } from "../../utils/date";
import { ElMessage, ElMessageBox } from "element-plus";
import { h } from "vue";
import { removeUser, changeUserStatus } from "../../api/services/users";
import EmptyStateTable from "./EmptyTableState.vue";
const showAddUserToClinicModal = ref(false);
const showChangeRoleFormModal = ref(false);
const showUserModal = ref(false);
const userDetails = ref({});
const isLoading = ref(false);

export default {
  name: "user-table",
  props: {
    users: {
      type: Array,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },

  components: {
    UserForm,
    ChangeRoleForm,
    AddUserToClinicForm,
    EmptyStateTable,
  },

  setup(props) {
    const editUser = (user) => {
      userDetails.value = user;
      showUserModal.value = true;
    };

    const addUserToClinic = (user) => {
      userDetails.value = user;
      showAddUserToClinicModal.value = true;
    };

    const changeUserRole = (user) => {
      showChangeRoleFormModal.value = true;
      userDetails.value = user;
    };

    const newUser = () => {
      userDetails.value = {};
      showUserModal.value = true;
    };
    const removeUserConfirm = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The user will be deleted. Continue?"
        ),

        "Delete User",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeUser(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const disableUser = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The user will be deactivated. Continue?"
        ),

        "Deactivate User",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await changeUserStatus(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const activateUser = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The user will be activated. Continue?"
        ),

        "Activate User",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await changeUserStatus(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 1:
          return "bg-gradient-success";
        case 0:
          return "bg-gradient-warning";
        default:
          return null;
      }
    };
    return {
      formatDate,
      formatTime,
      getInitials,
      editUser,
      newUser,
      userDetails,
      showUserModal,
      addUserToClinic,
      changeUserRole,
      showChangeRoleFormModal,
      showAddUserToClinicModal,
      getStatusColor,
      activateUser,
      disableUser,
      isLoading,
      removeUserConfirm,
    };
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissions"));
    return {
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
