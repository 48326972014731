<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <services-table 
          :services="services"
          :refresh="fetchData" 
          :searchQuery="searchTerm"
          v-else />
      </div>
    </div>
  </div>
</template>
 
<script>
import ServicesTable from "./components/ServiceTable.vue";
import Loader from "./components/Loader.vue";
import { fetchServices } from "../api/services/services";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const services = ref([]);
const searchTerm = ref("");

export default {
  name: "services",
  components: {
    ServicesTable,
    Loader,
  },
  setup() {
    const fetchData = async (search, page) => {
      const entity_params = ref();
      entity_params.value = { search_query: search };
     
      isLoading.value = true;
      try {
        const response = await fetchServices(entity_params, page);
        services.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      services,
      isLoading,
      searchTerm
    };
  },
  mounted() {
    this.fetchData("", 1);
  },
};
</script>
