<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <appointments-table
          :appointments="appointments"
          :refresh="fetchData"
          :searchQuery="searchTerm"
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { fetchAppointments } from "../api/services/appointments";
import AppointmentsTable from "./components/AppointmentsTable.vue";
import Loader from "./components/Loader.vue";
const appointments = ref([]);
const isLoading = ref(false);
export default {
  name: "appointments",
  components: {
    AppointmentsTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchAppointments(entity_params, page);
        appointments.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      appointments,
      isLoading,
      fetchData,
    };
  },
  mounted() {
    this.fetchData({}, 1);
  },
};
</script>
