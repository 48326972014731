<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <user-table :users="users" :refresh="fetchData" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { fetchUsers } from "../api/services/users";
import UserTable from "./components/UsersTable.vue";
import Loader from "./components/Loader.vue";

const users = ref([]);
const isLoading = ref(false);

export default {
  name: "users",
  components: {
    UserTable,
    Loader,
  },
  setup() {
    const fetchData = async (query) => {
      isLoading.value = true;
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchUsers(entity_params);

        users.value = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      users,
      isLoading,
      fetchData,
    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>
