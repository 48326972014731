<template>
  <div>

    <button class="btn btn-success mt-2" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
            aria-controls="offcanvasScrolling"
    >
      Patient's History
    </button>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h6 id="offcanvasRightLabel">Patient History</h6>
        <button type="button" class="btn btn-primary mt-2" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
      </div>
      <div class="offcanvas-body">
        <div v-if="loading">
          <content-loader
            viewBox="0 0 500 124"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="80" y="5" rx="3" ry="3" width="100" height="20" />
            <rect x="80" y="30" rx="0" ry="0" width="500" height="100" />
            <circle cx="45" cy="10" r="8" />
          </content-loader>
          <content-loader
            viewBox="0 0 500 124"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="80" y="5" rx="3" ry="3" width="100" height="20" />
            <rect x="80" y="30" rx="0" ry="0" width="500" height="100" />
          </content-loader>
          <content-loader
            viewBox="0 0 500 124"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="80" y="5" rx="3" ry="3" width="100" height="20" />
            <rect x="80" y="30" rx="0" ry="0" width="500" height="100" />
          </content-loader>
        </div>

        <el-timeline>
          <el-timeline-item
            v-for="(record, index) in records"
            :key="index"
            :timestamp="
            record.appointment != null
              ? record.appointment.appointment_date
              : dateFormat(record.created_at)
          "
            placement="top"
          >
            <el-card class="mb-3">
              <h6
                class="text-sm"
                v-if="record.triage != null"
                :set="(triage = JSON.parse(record.triage))"
              >
                Triage
              </h6>
              <div class="d-flex flex-row" v-if="record.triage != null">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-column">
                    <div class="text-sm">Last Monthly Period</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ dateFormat(triage.lmp) }}
                    </div>
                    <div class="text-sm">Blood Pressure</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.pressure }}
                    </div>
                    <div class="text-sm">Temperature (cº)</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.temperature }}
                    </div>
                    <div class="text-sm">SPO²</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.spo }}
                    </div>
                    <div class="text-sm">Pulse (beats/min)</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.pulse }}
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column ps-5">
                  <div class="d-flex flex-column">
                    <div class="text-sm">Height (CM)</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.height }}
                    </div>
                    <div class="text-sm">Weight (Kgs)</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.weight }}
                    </div>
                    <div class="text-sm">Body Mass Index (BMI)</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.bmi }}
                    </div>
                    <div class="text-sm">Respiratory (cpm)</div>
                    <div class="pb-3 text-dark text-bold text-sm">
                      {{ triage.cpm }}
                    </div>
                  </div>
                </div>
              </div>
              <h6 class="text-sm" v-if="record.vision != null">Vision</h6>

              <div
                class="d-flex flex-row"
                v-if="record.vision != null"
                :set="(vision = JSON.parse(record.vision))"
              >
                <table class="table align-items-center table-dark">
                  <thead class="thead-dark">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Vision
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Left Eye
                    </th>

                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Right Eye
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>D.V Unaided</td>
                    <td class="text-center">{{ vision.dv_unaided_left }}</td>
                    <td class="text-center">{{ vision.dv_unaided_right }}</td>
                  </tr>
                  <tr>
                    <td>D.V Pin-Hole</td>
                    <td class="text-center">{{ vision.dv_pin_hole_left }}</td>
                    <td class="text-center">{{ vision.dv_pin_hole_right }}</td>
                  </tr>
                  <tr>
                    <td>D.V With own correction</td>
                    <td class="text-center">
                      {{ vision.dv_own_correction_left }}
                    </td>
                    <td class="text-center">
                      {{ vision.dv_own_correction_right }}
                    </td>
                  </tr>
                  <tr>
                    <td>N.V Unaided</td>
                    <td class="text-center">{{ vision.nv_unaided_left }}</td>
                    <td class="text-center">{{ vision.nv_unaided_right }}</td>
                  </tr>
                  <tr>
                    <td>N.V With own correction</td>
                    <td class="text-center">
                      {{ vision.nv_own_correction_left }}
                    </td>
                    <td class="text-center">
                      {{ vision.nv_own_correction_right }}
                    </td>
                  </tr>
                  <tr>
                    <td>IOP (mmHg)</td>
                    <td class="text-center">{{ vision.iop_left }}</td>
                    <td class="text-center">{{ vision.iop_right }}</td>
                  </tr>
                  <tr>
                    <td>Color Vision</td>
                    <td class="text-center">{{ vision.color_vision_left }}</td>
                    <td class="text-center">{{ vision.color_vision_right }}</td>
                  </tr>
                  <tr>
                    <td>Lang Test</td>
                    <td class="text-center">{{ vision.lang_test_left }}</td>
                    <td class="text-center">{{ vision.lang_test_right }}</td>
                  </tr>
                  <tr>
                    <td>Fly Test</td>
                    <td class="text-center">{{ vision.fly_test_left }}</td>
                    <td class="text-center">{{ vision.fly_test_right }}</td>
                  </tr>
                  <tr>
                    <td>Amasler Grid</td>
                    <td class="text-center">{{ vision.amasler_grid_left }}</td>
                    <td class="text-center">{{ vision.amasler_grid_right }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <h6 class="text-sm" v-if="record.complains != null">
                <u>Presenting Complaint</u>
              </h6>
              <p class="text-sm" v-html="record.complains" />

              <h6 class="text-sm" v-if="record.diagnosis != null">
                <u>Clinical Findings</u>
              </h6>
              <p class="text-sm" v-html="record.findings" />

              <h6 class="text-sm" v-if="record.notes != null">
                <u>Treatment Plan</u>
              </h6>
              <p class="text-sm" v-html="record.notes" />
              <h6 class="text-sm" v-if="record.diagnoses != null">
                <u>Diagnosis</u>
              </h6>
              <ul class="list-group list-group-flush"  v-if="record.diagnoses != null">
                <li
                  class="list-group-item"
                  v-for="(item) in record.diagnoses"
                  :key="item.id"
                >
                  <div class="d-flex flex-row">
                    {{ item.code }} - {{ item.name }}
                  </div>
                </li>
              </ul>


              <h6 class="text-sm" v-if="record.diagnosis != null">
                <u>Diagnosis Details</u>
              </h6>
              <p class="text-sm" v-html="record.diagnosis" />
              <h6 class="text-sm" v-if="record.diagnosis != null">
                <u>Attachments</u>
              </h6>

              <div class="card" style="width: 100%">
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    v-for="(item, index) in record.uploads"
                    :key="item.id"
                  >
                    <div class="d-flex flex-row">
                      <div class="p-2">
                        <div class="demo-image__preview">
                          <el-image
                            v-if="item.ext != 'pdf'"
                            style="width: 30px; height: 30px"
                            :src="item.path"
                            :zoom-rate="1.2"
                            :preview-src-list="getFiles(record.uploads)"
                            :initial-index="index"
                            fit="cover"
                          />
                          <img
                            v-if="item.ext == 'pdf'"
                            style="width: 30px; height: 30px"
                            alt="Image placeholder"
                            @click="viewPdf(item.path)"
                            src="@/assets/img/pdf_holder.png"
                            class="side-glance"
                            fit="cover"
                          />
                        </div>
                      </div>
                      <div class="p-2">{{ item.file_name }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>


    <PdfViewerModal
      v-if="showPdfViewerModal"
      @close-modal="showPdfViewerModal = false"
      :pdfUrl="pdf_url"
    />
  </div>

</template>
<script>

import PdfViewerModal from "../../components/Modals/PdfViewerModal.vue";
import { ContentLoader } from "vue-content-loader";
import { fetchRecords } from "../../api/services/appointments";
import moment from "moment";
import { onMounted, ref } from "vue";

const pdf_url = ref("");
const showPdfViewerModal = ref(false);
const records = ref([]);
const drawer = ref(false);
const loading = ref(true);
const triage = ref({});
export default {
  name: "timeline-drawer",
  components: {
    ContentLoader,
    PdfViewerModal
  },//
  props: {
    patient_id: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const loadRecords = async () =>
    {
      loading.value = true;
      const entity_params = ref();
      entity_params.value = { patient_id: props.patient_id, page: 1 };
      try {
        const response = await fetchRecords(entity_params);
        records.value = response.data?.data;
        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    };
    const dateFormat = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const viewPdf = (path) => {
      pdf_url.value = path;
      showPdfViewerModal.value = true;
    };
    const getFiles = (uploads) => {
      const current_files_preview_list = [];
      uploads?.map((file) => {
        if (file.ext != "pdf") {
          current_files_preview_list.push(file.path);
        }
      });
      return current_files_preview_list;
    };
    onMounted(() => {
      const offcanvasElement = document.getElementById('offcanvasRight');

      offcanvasElement.addEventListener('show.bs.offcanvas', () => {
        drawer.value = true;
        loadRecords();
      });
      offcanvasElement.addEventListener('hide.bs.offcanvas', () => {
        records.value=[];
        drawer.value = false;

      });
    });
    return {
      getFiles,
      dateFormat,
      triage,
      loading,
      records,
      drawer,
      viewPdf,
      pdf_url,
      showPdfViewerModal,
    };

  },
};
</script>
<style scoped>
.demo-image__error .image-slot {
  font-size: 30px;
}

.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}

.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
.offcanvas-end {
  width: 50% !important;
}
</style>
