<template>
  <div class="modal-overlay">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-stock-o text-primary me-2" />Add New Stock: {{ props?.product?.name+" ("+props?.product?.code+")" }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="stockFormRef"
        :model="stockForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <el-form-item label="Quantity" prop="purchase_stock">
                  <el-input  v-model="stockForm.purchase_stock" type="number" />
                </el-form-item>
              </div>
              <div class="mb-3">
                <el-form-item label="Buying Price" prop="buying_price">
                  <el-input v-model="stockForm.buying_price" type="number" />
                </el-form-item>
              </div>
              <div class="mb-3">
                <el-form-item label="Discount Type" prop="discount_type">
                  <el-switch
                    v-model="stockForm.discount_type"
                    class="ml-2"
                    width="200"
                    inline-prompt
                    active-text="percentage"
                    inactive-text="Fixed"
                  />
                </el-form-item>
              </div>
              <div class="mb-3" v-if="stockForm.discount_type == true">
                <el-form-item
                  label="Buying Price Discount (%)"
                  prop="purchase_discount_percentage"
                >
                  <el-input
                    v-model="stockForm.purchase_discount_percentage"
                    type="number"
                  />
                </el-form-item>
              </div>

              <div class="mb-3" v-if="stockForm.discount_type == false">
                <el-form-item
                  label="Buying Price Discount"
                  prop="purchase_discount"
                >
                  <el-input
                    v-model="stockForm.purchase_discount"
                    type="number"
                  />
                </el-form-item>
              </div>
              <div class="mb-3">
                <el-form-item
                  label="Unit Buying Price"
                  prop="unit_buying_price"
                >
                  <el-input
                    v-model="stockForm.unit_buying_price"
                    type="number"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <el-form-item label="Selling Price" prop="buying_price">
                  <el-input v-model="stockForm.selling_price" type="number" />
                </el-form-item>
              </div>
              <div class="mb-3">
                <el-form-item label="Markup Type" prop="markup_type">
                  <el-switch
                    v-model="stockForm.markup_type"
                    class="ml-2"
                    width="200"
                    inline-prompt
                    active-text="percentage"
                    inactive-text="Fixed"
                  />
                </el-form-item>
              </div>

              <div class="mb-3" v-if="stockForm.markup_type == true">
                <el-form-item
                  label="Selling Price Markup (%)"
                  prop="sale_markup_percentage"
                >
                  <el-input
                    v-model="stockForm.sale_markup_percentage"
                    type="number"
                  />
                </el-form-item>
              </div>

              <div class="mb-3" v-if="stockForm.markup_type == false">
                <el-form-item label="Selling Price Markup" prop="sale_markup">
                  <el-input v-model="stockForm.sale_markup" type="number" />
                </el-form-item>
              </div>
              <div class="mb-3">
                <el-form-item
                  label="Unit Selling Price"
                  prop="unit_selling_price"
                >
                  <el-input
                    v-model="stockForm.unit_selling_price"
                    type="number"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs"
            @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(stockFormRef)"
            >Submit</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { fetchProduct, createStock } from "../../api/services/inventory";
import { ElNotification } from "element-plus";
const stockFormRef = ref();
const isLoading = ref(false);

export default {
  name: "add-stock",

  watch: {
    stockForm: {
      handler: function (newstockForm) {
        if (
          newstockForm.discount_type &&
          newstockForm.purchase_discount_percentage > 0
        ) {
          //if percentage
          newstockForm.purchase_discount =
            newstockForm.buying_price *
            (newstockForm.purchase_discount_percentage / 100);
          newstockForm.unit_buying_price =
            newstockForm.buying_price - newstockForm.purchase_discount;
        } else {
          newstockForm.purchase_discount_percentage = "";
          newstockForm.unit_buying_price =
            newstockForm.buying_price - newstockForm.purchase_discount;
        }

        if (
          newstockForm.markup_type &&
          newstockForm.sale_markup_percentage > 0
        ) {
          //if percentage
          newstockForm.sale_markup =
            newstockForm.selling_price *
            (newstockForm.sale_markup_percentage / 100);
          newstockForm.unit_selling_price =
            parseFloat(newstockForm.selling_price) +
            parseFloat(newstockForm.sale_markup);
        } else {
          newstockForm.sale_markup_percentage = "";
          newstockForm.unit_selling_price =
            parseFloat(newstockForm.selling_price) +
            parseFloat(newstockForm.sale_markup);
        }
      },
      deep: true,
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props,{emit}) {
    const pid = props?.product?.id || 0;


    const stockForm = reactive({
      id: 0,
      name: "",
      description: "",

      discount_type: false,
      purchase_discount_percentage: 0,
      markup_type: false,
      sale_markup_percentage: 0,
      purchase_stock: 0,
      purchase_discount: 0,
      sale_markup: 0,
      buying_price: 0,
      selling_price: 0,
      unit_buying_price: 0,
      unit_selling_price: 0,
    });

    const rules = reactive({
      buying_price: [
        {
          required: true,
          message: "Please add the buying price",
          trigger: ["blur", "change"],
         
        },
      ],
      selling_price: [
        {
          required: true,
          message: "Please add the selling price",
          trigger: ["blur", "change"],
        },
      ],
      purchase_stock: [
        {
          required: true,
          message: "Please add the quantity purchased",
          trigger: ["blur", "change"],
          min: 1,
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          stockForm.discount_type = stockForm.discount_type
            ? "fixed"
            : "percent";
          stockForm.markup_type = stockForm.markup_type ? "fixed" : "percent";

          stockForm.purchase_discount_percentage = stockForm.purchase_discount_percentage || 0;
          stockForm.sale_markup_percentage = stockForm.sale_markup_percentage || 0;

          formData.append("product_id", pid);
          formData.append("name", stockForm.name);
          formData.append("description", stockForm.description);

          formData.append("purchase_stock", stockForm.purchase_stock);

          formData.append("buying_price", stockForm.buying_price);
          formData.append("discount_type", stockForm.discount_type);
          formData.append(
            "purchase_discount",
            stockForm.purchase_discount ?? 0
          );
          formData.append(
            "purchase_discount_percentage",
            stockForm.purchase_discount_percentage
          );
          formData.append("unit_buying_price", stockForm.unit_buying_price);

          formData.append("selling_price", stockForm.selling_price);
          formData.append("markup_type", stockForm.markup_type);
          formData.append("sale_markup", stockForm.sale_markup ?? 0);
          formData.append(
            "sale_markup_percentage",
            stockForm.sale_markup_percentage 
          );
          formData.append("unit_selling_price", stockForm.unit_selling_price);

          createStock(formData)
            .then((data) => {
              if (data.success) {
                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
              } else {
                Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
                );
              }
              emit("refresh-units");
              emit("close-modal");
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const fetchStockData = async () => {
      if (pid > 0) {
        isLoading.value = true;
        try {
          const response = await fetchProduct(pid);

          stockForm.id = response.data?.id;
          stockForm.name = response.data?.name;
          stockForm.description = response.data?.description;

          stockForm.discount_type =
            response.data?.lateststock?.discount_type == "fixed";
          stockForm.purchase_discount_percentage =
            response.data?.lateststock?.purchase_discount_percentage;
          stockForm.markup_type =
            response.data?.lateststock?.markup_type == "fixed";
          stockForm.sale_markup_percentage =
            response.data?.lateststock?.sale_markup_percentage;
          stockForm.purchase_stock = '';
          stockForm.purchase_discount =
            response.data?.lateststock?.purchase_discount;
          stockForm.sale_markup = response.data?.lateststock?.sale_markup;
          stockForm.buying_price = response.data?.lateststock?.buying_price;
          stockForm.selling_price = response.data?.lateststock?.selling_price;
          stockForm.unit_buying_price =
            response.data?.lateststock?.unit_buying_price;
          stockForm.unit_selling_price =
            response.data?.lateststock?.unit_selling_price;
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      }
    };
    return {
      submitForm,
      rules,
      stockFormRef,
      stockForm,
      isLoading,
      props,
      fetchStockData,
    };
  },
  mounted() {
    this.fetchStockData();
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  min-width: 800px;
  max-width: 900px;
  max-height: 700px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
