<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="mb-0">Top Debtors</h6>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
          v-for="(debtor, index) in debtors"
          :key="index"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex align-items-center">
            <router-link :to="{ path: `/patient/statement/${debtor.id}` }">
              <argon-button
                color="danger"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
              >
                <i class="fa fa-file" aria-hidden="true"></i>
              </argon-button>
            </router-link>

            <div class="d-flex flex-column">
              <h6 class="mb-1 text-dark text-sm">{{ debtor.name }}</h6>
              <span class="text-xs">{{ debtor.mobile_number }}</span>
            </div>
          </div>
          <div
            class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold"
          >
            {{ debtor.balance }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  props: {
    debtors: {
      type: Object,
      required: true,
    },
  },
  name: "debtors-card",
  components: {
    ArgonButton,
  },
};
</script>
