<template>
  <div class="pdf">
    <div class="row letter_head">
      <div class="col-md-4">
        <img
          alt="logo"
          :src="urlBase64"
          class="side-glance"
          style="max-width: 150px; height: auto"
        />
      </div>
      <div class="col-md-4">
        <div class="d-flex d-row justify-content-center">
          <div style="width: 38%">Invoice No.:</div>
          <div class="ps-1 inv_number">
            <b>{{ props.invoice.invoice_number }}</b>
          </div>
        </div>
        <div class="d-flex d-row justify-content-center">
          <div style="width: 38%">Invoice Date:</div>
          <div class="ps-1">{{ dateTime(props.invoice.invoice_date) }}</div>
        </div>
        <div class="d-flex d-row justify-content-center">
          <vue-barcode
            :value="props.invoice.invoice_number"
            :options="{ width: '2', height: '30' }"
          ></vue-barcode>
        </div>
      </div>

      <div class="col-md-4" v-html="organization.address"></div>
    </div>
    <hr class="horizontal dark m-1" />
    <div class="row invoice_body">
      <div class="col-md-4">
        <b class="mb-1 text-dark">Patient details</b>

        <div class="d-flex d-row">
          <div style="width: 30%">Patient Name:</div>
          <div class="ps-1 font-weight-bold">
            {{
              props.invoice.patient.first_name +
              " " +
              props.invoice.patient.middle_name +
              " " +
              props.invoice.patient.last_name
            }}
          </div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 30%">Patient No.:</div>
          <div class="ps-1 font-weight-bold">
            {{ props.invoice.patient.patient_number }}
          </div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 30%">Case Type:</div>
          <div class="ps-1 font-weight-bold">
            {{
              modifyPatientType(
                props.invoice?.appointment_details?.appointment_type
              )
            }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <b class="mb-1 text-dark">Medical Cover Details</b>

        <div
          v-if="props.invoice.patient_insurance?.insurance != null"
          class="d-flex d-row"
        >
          <div style="width: 40%">Insurance Name:</div>
          <div class="font-weight-bold">
            {{ props.invoice.patient_insurance?.insurance?.name }}
          </div>
        </div>

        <div
          v-if="props.invoice.patient_insurance?.employer != null"
          class="d-flex d-row"
        >
          <div style="width: 40%">Scheme:</div>
          <div class="font-weight-bold">
            {{ props.invoice.patient_insurance?.employer?.name }}
          </div>
        </div>
        <div
          v-if="props.invoice.patient_insurance?.membership_name != ''"
          class="d-flex d-row"
        >
          <div style="width: 40%">Member Name:</div>
          <div class="font-weight-bold">
            {{ props.invoice.patient_insurance?.membership_name }}
          </div>
        </div>
        <div
          v-if="props.invoice.patient_insurance?.membership_number != ''"
          class="d-flex d-row"
        >
          <div style="width: 40%">Member No.:</div>
          <div class="font-weight-bold">
            {{ props.invoice.patient_insurance?.membership_number }}
          </div>
        </div>
        <div
          v-if="props.invoice.patient_insurance?.employee_name != ''"
          class="d-flex d-row"
        >
          <div style="width: 40%">Employee Name:</div>
          <div class="font-weight-bold">
            {{ props.invoice.patient_insurance?.employee_name }}
          </div>
        </div>
        <div
          v-if="props.invoice.patient_insurance?.employee_number != ''"
          class="d-flex d-row"
        >
          <div style="width: 40%">Employee No.:</div>
          <div class="font-weight-bold">
            {{ props.invoice.patient_insurance?.employee_number }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <b class="mb-1 text-dark">Invoice Details</b>
        <div class="d-flex d-row">
          <div style="width: 38%">Dispatch No.:</div>
          <div class="ps-1 font-weight-bold">
            {{ props.invoice.dispatch_number }}
          </div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 38%">Dispatch Date:</div>
          <div class="ps-1 font-weight-bold">
            {{ dateTime(props.invoice.dispatch_date) }}
          </div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 38%">Dispatch No.:</div>
          <div class="ps-1 font-weight-bold">
            {{ props.invoice.dispatch_number }}
          </div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 38%">Pre-Auth Code</div>
          <div class="ps-1 font-weight-bold">
            {{ props.invoice.pre_auth_code }}
          </div>
        </div>
        <div class="d-flex d-row">
          <div style="width: 38%">Facility Inv No.:</div>
          <div class="ps-1 font-weight-bold">
            {{ props.invoice.facility_invoice_number }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table align-items-left">
          <thead>
            <tr>
              <th
                width="2%"
                clas="text-uppercase text-secondary text-xxs font-weight-bolder "
              >
                #
              </th>
              <th width="23%" clas="">Service</th>
              <th width="23%" clas="">Desc</th>
              <th width="16%" clas="">Quantity</th>
              <th width="16%" class="text-end">Price</th>

              <th width="16%" class="text-end">Total (KES)</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(service, index) in props.service_price_list"
              :key="index"
            >
              <td class="text-center text-sm">{{ index + 1 }}</td>
              <td
                class="text-start text-sm"
                style="white-space: pre-wrap; width: 50px"
              >
                {{ service.item }}
              </td>
              <td
                class="text-start text-sm ps-4"
                style="white-space: pre-wrap; width: 50px"
              >
                {{ service.description }}
              </td>
              <td class="text-start text-sm ps-4">1</td>
              <td class="text-end text-sm pe-4">
                {{ service.price }}
              </td>
              <td class="text-end text-sm pe-4">
                {{ service.price }}
              </td>
            </tr>
            <tr
              v-for="(product, index) in props.product_price_list"
              :key="index"
            >
              <td class="text-center text-sm">
                {{ index + service_price_list.length + 1 }}
              </td>
              <td
                class="text-start text-sm"
                style="white-space: pre-wrap; width: 50px"
              >
                {{ product.name }}
              </td>
              <td
                class="text-start text-sm ps-4"
                style="white-space: pre-wrap; width: 50px"
              >
                {{ product.description }}
              </td>
              <td class="text-start text-sm ps-4">{{ product.quantity }}</td>
              <td class="text-end text-sm pe-4">
                {{ product.price }}
              </td>
              <td class="text-end text-sm pe-4">
                {{ product.totals }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="invoice?.discount > 0">
              <td colspan="4"></td>
              <td class="text-end text-sm font-weight-bolder">
                Sub Total (KES)
              </td>
              <td class="text-end text-sm font-weight-bolder pe-4">
                {{ invoice?.sub_total }}
              </td>
            </tr>
            <tr v-if="invoice?.discount > 0">
              <td colspan="4"></td>
              <td class="text-end text-sm font-weight-bolder">
                Discount (KES)
              </td>
              <td class="text-end text-sm font-weight-bolder pe-4">
                {{ invoice?.discount }}
              </td>
            </tr>

            <!--  <tr>
          <td colspan="4"></td>
          <td class="text-end text-sm font-weight-bolder">TAX</td>
          <td class="text-end text-sm font-weight-bolder pe-4">
            15.00
          </td>
        </tr> -->
            <tr>
              <td colspan="4"></td>
              <td class="text-end text-sm font-weight-bolder">Total (KES)</td>
              <td class="text-end text-sm font-weight-bolder pe-4">
                {{ invoice.total }}
              </td>
            </tr>
            <tr v-if="invoice.invoice_payments.length > 0">
              <td colspan="4"></td>
              <td class="text-end text-sm font-weight-bolder">Paid (KES)</td>
              <td class="text-end text-sm font-weight-bolder pe-4">
                {{ invoice.invoice_payments_sum_amount }}
              </td>
            </tr>
            <tr v-if="invoice.invoice_payments.length > 0">
              <td colspan="4"></td>
              <td class="text-end text-sm font-weight-bolder">Balance (KES)</td>
              <td class="text-end text-sm font-weight-bolder pe-4">
                {{ invoice.total - invoice.invoice_payments_sum_amount }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import moment from "moment";
const urlBase64 = ref("");
const organization = ref({});
const barcode = ref("");
export default {
  name: "invoice details",

  props: {
    invoice: {
      type: Object,
      required: true,
    },
    service_price_list: {
      type: Object,
      required: true,
    },
    product_price_list: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    organization.value = JSON.parse(localStorage.getItem("organization"));
    const modifyPatientType = (type) => {
      if (type == "in-patient") return "In Patient";
      if (type == "out-patient") return "Out Patient";
      if (type == "day-case") return "Day Case";
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const toDataUrl = function (url) {
      url += "?" + new Date().getTime();
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
          });
        };
        xhr.open("GET", url);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.responseType = "blob";
        xhr.send();
      });
    };

    onMounted(async () => {
      barcode.value = props.invoice.invoice_number;
      urlBase64.value = await toDataUrl(organization.value.logo.path);
    });
    return {
      barcode,
      urlBase64,
      toDataUrl,
      organization,
      props,
      modifyPatientType,
      dateTime,
    };
  },
};
</script>
<style scoped>
.pdf {
  color: #000;
  font-size: 10px;
  /* height: 148.5mm; */
  width: 210mm;
}
.letter_head {
  font-size: 12px;
  padding: 5px;
  width: 200mm;
}
.invoice_body {
  color: #000;
  margin-left: 20px;
  width: 200mm;
}
.inv_number {
  font-size: 12px;
}
.table {
  color: #000;
  width: 200mm;
}
.table td,
.table th {
  font-size: 10px !important;
}
</style>
