<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="row">
          <div v-if="isLoading">
            <Loader />
          </div>
          <div
            class="top-0 my-auto text-center col-8 d-lg-flex d-none pe-0 justify-content-center flex-column"
            style="height: 100vh"
          >
            <div
              class="position-relative bg-gradient-primary h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/sign-in-side-image.jpg') + ')',
                backgroundSize: 'cover',
              }"
            >
              <span class="mask bg-gradient-secondary opacity-4"></span>
              <h4 class="mt-5 text-white font-weight-bolder position-relative">
                "Here to Simplify Your Hospital’s Management, One Click at a
                Time"
              </h4>
              <p class="text-white position-relative">
                A hospital and clinic management software which enables you to
                run your core financial, clinical, and operational processes
              </p>
            </div>
          </div>
          <div
            class="col-xl-4 col-lg-4 col-md-4 d-flex flex-column mx-lg-0 my-auto align-items-end"
          >
            <div class="card card-plain">
              <div class="pb-0 card-header text-start">
                <h4 class="font-weight-bolder">Account Verification</h4>
              </div>
              <div class="card-body">
                <p class="mb-1">
                  Please wait as we verify your account and redirect you to your
                  dashboard!. Thank you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ref, h } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { verifyEmail } from "../api/services/authentication";
import { useRouter, useRoute } from "vue-router";
import Loader from "./components/Loader.vue";
const body = document.getElementsByTagName("body")[0];
const isLoading = ref(false);

export default {
  components: {
    Loader,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const Authenticate = async () => {
      const token = route.params.id;

      isLoading.value = true;

      let body = {
        grant_type: "password",
        client_id: process.env.VUE_APP_PASSWORD_API_KEY,
        client_secret: process.env.VUE_APP_PASSWORD_API_SECRET,
        token: token,
      };

      await verifyEmail(body, token)
        .then((response) => {
          router.push({ path: "/dashboard" });
          if (response.status == 200) {
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("token", response.data.token.access_token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem(
              "profileName",
              response.data.user.first_name + " " + response.data.user.last_name
            );

            ElMessage({
              message: h(
                "i",
                { style: "color: teal" },
                `Welcome ${
                  response.data.user.first_name +
                  " " +
                  response.data.user.last_name
                } to your dashboard`
              ),
              type: "success",
            });
          } else if (response.status == 422) {
            ElNotification({
              title: "Error",
              message: "Invalid access token.",
              type: "error",
            });
          } else {
            ElNotification({
              title: "Error",
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          isLoading.value = false;
        });
    };
    return {
      Authenticate,
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");

    this.Authenticate();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
.click-here {
  margin-left: 1.25rem;
}
</style>
